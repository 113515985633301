import { Divider, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { setModal } from 'src/redux/modal'

export const ModalBase = styled('div')`
  margin: 5em 20em 0em 20em;
  padding: 2em;
  background-color: white;
  display: block;
  overflow: auto;
`

export function ModalTitle({ title }: { title: string }): JSX.Element {
  return (
    <div style={{ marginBottom: '2em' }}>
      <Typography variant="h2">{title}</Typography>
      <div style={{ marginTop: '0.4em' }}>
        <Divider />
      </div>
    </div>
  )
}

export function CloseModalButton(): JSX.Element {
  const dispatch = useDispatch()

  const handleCloseModal = () => {
    dispatch(setModal({ modalOpen: false }))
  }

  return (
    <div style={{ marginTop: '1.5em' }}>
      <Divider />
      <Button
        sx={{ marginTop: '1em' }}
        variant="contained"
        onClick={handleCloseModal}
      >
        Sulje
      </Button>
    </div>
  )
}
