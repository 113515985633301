import { ExportChecked } from 'src/redux/export'

import {
  householdCategories,
  employmentCategories,
  generalCategories
} from 'src/utils/categories'

export type Panel = {
  [key: string]: ExportChecked
}

type Hierarchy = {
  [key: string]: string[]
}

export type StudyFieldState = {
  indexes: string[]
  selectedIndexes: string[]
  allowExport?: boolean
}

export type StudyDefaultState = {
  [key: string]: StudyFieldState
}

export type ExportFilter = {
  panels: Panel
  categories: Panel
  populationFields: string[]
  study: StudyDefaultState
  ownData: StudyDefaultState
  ownDataCategories: Panel
  studyCategories: Panel
}

export function createDefaultExportState() {
  const allCategories = Object.keys(populationHierarchy).concat(
    Object.keys(householdHierarchy),
    Object.keys(employmentHierarchy)
  )
  const exportDefaultState: ExportFilter = {
    panels: {
      population: ExportChecked.Not,
      housing: ExportChecked.Not,
      employment: ExportChecked.Not,
      study: ExportChecked.Not,
      ownData: ExportChecked.Not
    },
    categories: {},
    populationFields: [],
    study: {},
    ownData: {},
    ownDataCategories: {},
    studyCategories: {}
  }
  allCategories.forEach((category) => {
    exportDefaultState.categories[category] = ExportChecked.Not
  })
  return exportDefaultState
}

export const populationHierarchy: Hierarchy = {
  gender: ['gender_f', 'gender_m'],
  age: [
    'age_0_6',
    'age_7_17',
    'age_18_24',
    'age_25_34',
    'age_35_44',
    'age_45_54',
    'age_55_64',
    'age_65_plus',
    'age_65_74',
    'age_75_84',
    'age_85_plus'
  ],
  majority: ['age_0_17', 'age_18_plus'],
  education: [
    'education_basic',
    'education_matriculation',
    'education_vocational',
    'education_bachelor',
    'education_master'
  ]
}

export const populationFields = Object.keys(populationHierarchy).flatMap(
  (category) => {
    return populationHierarchy[category]
  }
)

export const householdHierarchy: Hierarchy = {
  housing: [
    'household_owned',
    'household_rented',
    'summer_cottages',
    'home_small_house',
    'home_apartment'
  ],
  household: [
    'household_total_te',
    'household_young_single',
    'household_young_childfree',
    'household_with_children',
    'household_with_children_young',
    'household_with_children_under_school_age',
    'household_with_children_school_age',
    'household_with_children_teenager',
    'household_with_adults',
    'household_with_pensioner'
  ]
}

export const householdFields = Object.keys(householdHierarchy).flatMap(
  (category) => {
    return householdHierarchy[category]
  }
)

export const employmentHierarchy: Hierarchy = {
  incomeClass: ['income_class_low', 'income_class_middle', 'income_class_high'],
  employment: ['workforce', 'unemployed', 'student'],
  jobs: [
    'job_total',
    'job_primary_sector',
    'job_secondary_sector',
    'job_services',
    'job_industry_class_A',
    'job_industry_class_B',
    'job_industry_class_C',
    'job_industry_class_D',
    'job_industry_class_E',
    'job_industry_class_F',
    'job_industry_class_G',
    'job_industry_class_H',
    'job_industry_class_I',
    'job_industry_class_J',
    'job_industry_class_K',
    'job_industry_class_L',
    'job_industry_class_M',
    'job_industry_class_N',
    'job_industry_class_O',
    'job_industry_class_P',
    'job_industry_class_Q',
    'job_industry_class_R',
    'job_industry_class_S'
  ]
}

export const employmentFields = Object.keys(employmentHierarchy).flatMap(
  (category) => {
    return employmentHierarchy[category]
  }
)

export function getFieldsForTopCategory(topCategory: string): string[] {
  switch (topCategory) {
    case 'population':
      return populationFields
    case 'employment':
      return employmentFields
    case 'household':
      return householdFields
    default:
      throw new Error(
        `Category ${topCategory} is not a valid category for fields!`
      )
  }
}

export function getFieldsForCategory(
  topCategory: string,
  category: string
): string[] {
  switch (topCategory) {
    case 'population':
      return populationHierarchy[category]
    case 'employment':
      return employmentHierarchy[category]
    case 'household':
      return householdHierarchy[category]
    default:
      throw new Error(
        `Fields could not be resolved in getFieldsForCategory for ${topCategory}/${category}`
      )
  }
}

export function getCategoriesForTopCategory(
  topCategory: string,
  newState: ExportFilter | null = null
): string[] {
  switch (topCategory) {
    case 'population':
      return generalCategories
    case 'employment':
      return employmentCategories
    case 'household':
      return householdCategories
    case 'study':
      if (!newState) {
        throw new Error(
          'NewState was not passed when fetching categories for TopCategory!'
        )
      }
      return Object.keys(newState.studyCategories)
    case 'ownData':
      if (!newState) {
        throw new Error(
          'NewState was not passed when fetching categories for TopCategory!'
        )
      }
      return Object.keys(newState.ownDataCategories)
    default:
      throw new Error(
        `Category ${topCategory} is not a valid category for category!`
      )
  }
}

export function getPanelCheckboxStatus(
  newState: ExportFilter,
  topCategory: string
): ExportChecked {
  let categoryStatuses: ExportChecked[]
  if (topCategory === 'study') {
    categoryStatuses = getCategoriesForTopCategory(topCategory, newState).map(
      (category) => {
        return newState.studyCategories[category]
      }
    )
  } else if (topCategory === 'ownData') {
    categoryStatuses = getCategoriesForTopCategory(topCategory, newState).map(
      (category) => {
        return newState.ownDataCategories[category]
      }
    )
  } else {
    categoryStatuses = getCategoriesForTopCategory(topCategory).map(
      (category) => {
        return newState.categories[category]
      }
    )
  }

  if (
    categoryStatuses.every(
      (checkboxStatus) => checkboxStatus === ExportChecked.Yes
    )
  ) {
    return ExportChecked.Yes
  } else if (
    categoryStatuses.every(
      (checkboxStatus) => checkboxStatus === ExportChecked.Not
    )
  ) {
    return ExportChecked.Not
  } else {
    return ExportChecked.Partial
  }
}

export function getSingleStudyCategoryStatus(
  studyCategory: StudyFieldState
): ExportChecked {
  const categoryIndexesTotalCount = studyCategory.indexes.length
  const selectedIndexesCount = studyCategory.selectedIndexes.length

  if (selectedIndexesCount === categoryIndexesTotalCount) {
    return ExportChecked.Yes
  } else if (selectedIndexesCount === 0) {
    return ExportChecked.Not
  } else {
    return ExportChecked.Partial
  }
}
