import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import styled from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

import { appTheme } from '../../layout/appTheme'

type Props = {
  FormArea: JSX.Element
  title: string
}

const ContentArea = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '12%'
})

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '1em',
  width: '20em',
  alignItems: 'center',
  backgroundColor: 'white'
})

const PaperArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'white',
  maxWidth: '20rem'
})

export const useStyles = makeStyles()(() => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    display: 'flex',
    flexDirection: 'column'
  },
  error: {
    color: 'red',
    padding: '0.5em'
  }
}))

export function LoginHeader({ FormArea, title }: Props): JSX.Element {
  return (
    <ContentArea>
      <Content>
        <PaperArea>
          <Avatar sx={{ backgroundColor: appTheme.palette.primary.main }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography color="primary" component="h1" variant="h2">
            {title}
          </Typography>
        </PaperArea>
        {FormArea}
      </Content>
    </ContentArea>
  )
}
