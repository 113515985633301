import { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  CssBaseline,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  css
} from '@mui/material'
import styled from '@emotion/styled'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { appTheme } from './layout/appTheme'
import { useDispatch } from 'react-redux'
import { storeUserState } from '../redux/auth'
import { ProjectPage } from './pages/ProjectPage'
import { LandingPage } from './pages/landing-page'
import { PageNotFound } from './pages/PageNotFound'
import { Notification } from 'src/components/layout/Notification'
import { useNotification, useUser } from 'src/redux'
import { z } from 'zod'
import gql from 'graphql-tag'
import { Query, Role } from 'generated-types'
import GlobalStyles from '@mui/material/GlobalStyles'
import {
  RequestNewPasswordPage,
  LoginFormPage,
  NewUserPage,
  ResetPasswordPage
} from './login'
import { PrivateRoute } from './PrivateRoute'
import { getConfigFromEnv } from '../config'
const {
  app: { VERSION, ENV_NAME }
} = getConfigFromEnv()

const VersionBox = styled('div')({
  fontSize: '0.9em',
  position: 'fixed',
  display: 'flex',
  paddingLeft: '0.5em',
  paddingRight: '0.5em',
  bottom: 0,
  right: 0,
  backgroundColor: 'white',
  border: '2px solid black'
})

function App(): JSX.Element {
  const dispatch = useDispatch()
  const { message, messageId } = useNotification()
  const developerMode = Boolean(localStorage.getItem('insightDebug')) ?? false

  const { data, loading, error } = useQuery<Query>(
    gql`
      query {
        currentUser {
          role
        }
      }
    `
  )
  const { role } = useUser()

  let networkError = false

  if (error && error?.networkError) {
    networkError = true
  }
  useEffect(() => {
    const role = z
      .union([z.literal('user'), z.literal('admin'), z.literal('superadmin')])
      .nullable()
      .parse(data?.currentUser?.role ?? null) as Role | null | undefined

    if (loading) {
      return
    }

    if (role) {
      dispatch(storeUserState({ role, serviceNetworkError: false }))
    } else {
      dispatch(
        storeUserState({ role: null, serviceNetworkError: networkError })
      )
    }
  }, [dispatch, data, loading])

  return (
    <StyledEngineProvider injectFirst>
      <GlobalStyles
        styles={css`
          .loading {
            display: none;
          }
        `}
      />
      <ThemeProvider theme={appTheme}>
        <CssBaseline />

        {loading && role === undefined ? null : (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<LandingPage />}></Route>
              </Route>

              <Route path="/project/:projectId" element={<PrivateRoute />}>
                <Route
                  path="/project/:projectId"
                  element={<ProjectPage />}
                ></Route>
              </Route>

              <Route path="/public/:projectId" element={<ProjectPage />} />
              <Route path="/login">
                <Route index element={<LoginFormPage />} />
                <Route
                  path="/login/request-new-password"
                  element={<RequestNewPasswordPage />}
                />
                <Route
                  path="/login/reset-password/:token"
                  element={<ResetPasswordPage />}
                />
                <Route path="/login/newuser/:token" element={<NewUserPage />} />
              </Route>
              <Route element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        )}
        <Notification key={messageId} message={message} />
        {developerMode ? (
          <VersionBox>
            {VERSION} {ENV_NAME}
          </VersionBox>
        ) : null}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
