import { combineReducers } from 'redux'
import { useSelector } from 'react-redux'

import AuthReducer, { UserStore } from './auth'
import FilterReducer, {
  FilterState,
  FilterStateMode,
  AdministrativeDivisions,
  FilterStateFilters,
  FilterStateLabels
} from './filter'
import UIReducer, { UIState } from './ui'
import UserPrefencesReducer, { UserPreferences } from './userPreferences'
import ExportReducer from './export'
import { ExportFilter } from './export/exportModelFilter'
import ModalReducer, { ModalState } from './modal'
import SearchResultsReducer, { SearchResults } from './searchresults'
import ExportSettingsReducer, { ExportSettingsStore } from './exportSettings'
import NotificationReducer, { NotificationStore } from './notification'
import NewMapReducer, { NewMapState } from './newmap'

export type RootState = {
  filter: FilterState
  auth: UserStore
  ui: UIState
  exportFilter: ExportFilter
  modal: ModalState
  userPreferences: UserPreferences
  searchResults: SearchResults
  exportSettings: ExportSettingsStore
  notification: NotificationStore
  newMap: NewMapState
}

// CustomFilterState is here, because RootState's - FilterState is using immutability-js
// and it doesn't easily work with these redux-hooks
type CustomFilterState = {
  filter: FilterType
}

type FilterType = {
  mode: FilterStateMode
  administrativeDivisions: AdministrativeDivisions
  filters: FilterStateFilters
  labels: FilterStateLabels
  userPrefences: UserPreferences
  searchResults: SearchResults
}

export function useUI(): UIState {
  return useSelector<RootState, UIState>((state) => state.ui)
}

export function useUserPreferences(): UserPreferences {
  return useSelector<RootState, UserPreferences>(
    (state) => state.userPreferences
  )
}

export function useExportFilter(): ExportFilter {
  return useSelector<RootState, ExportFilter>((state) => state.exportFilter)
}

export function useUser(): UserStore {
  const auth = useSelector<RootState, UserStore>((state) => state.auth)
  return auth
}

export function useModal(): ModalState {
  const modal = useSelector<RootState, ModalState>((state) => state.modal)
  return modal
}

export function useFilter(): FilterType {
  return useSelector<CustomFilterState, FilterType>((state) => state.filter)
}

export function useSearchResults(): SearchResults {
  return useSelector<RootState, SearchResults>((state) => state.searchResults)
}

export function useExportSettings(): ExportSettingsStore {
  return useSelector<RootState, ExportSettingsStore>(
    (state) => state.exportSettings
  )
}

export function useNotification(): NotificationStore {
  return useSelector<RootState, NotificationStore>(
    (state) => state.notification
  )
}

export function useNewMap(): NewMapState {
  return useSelector<RootState, NewMapState>((state) => state.newMap)
}

export default combineReducers<RootState>({
  filter: FilterReducer,
  auth: AuthReducer,
  ui: UIReducer,
  exportFilter: ExportReducer,
  modal: ModalReducer,
  userPreferences: UserPrefencesReducer,
  searchResults: SearchResultsReducer,
  exportSettings: ExportSettingsReducer,
  notification: NotificationReducer,
  newMap: NewMapReducer
})
