import React from 'react'
import {
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@mui/material'
import { useQuery } from '@apollo/react-hooks'

import { setAreaSelection } from 'src/redux/newmap'
import { setModal } from 'src/redux/modal'
import gql from 'graphql-tag'
import { Query } from 'generated-types'
import { useDispatch } from 'react-redux'
import { CloseModalButton, ModalBase, ModalTitle } from './common'
import { useParams } from 'react-router-dom'

const styles = {
  row: {},
  rows: {
    height: '30em',
    overflow: 'scroll'
  }
}

export const AreaSelection: any = ({ projectData }: any) => {
  const areaSelectionIds = projectData?.project?.areaSelectionIds
  const { projectId } = useParams()
  const dispatch = useDispatch()
  const { data, loading } = useQuery<Query>(
    gql`
      query areaSelectionFeed {
        areaSelectionFeed(filter: { perPage: 500, projectId: "${projectId}" }) {
          data {
            id
            name
            description
            common
            regions
            municipalities
            postals
          }
        }
      }
    `
  )

  console.log('data', data)

  const handleAreaSelection = (id: string, name: string) => {
    dispatch(setModal({ modalOpen: false }))
    dispatch(setAreaSelection({ id, name }))
  }

  if (loading) {
    return (
      <ModalBase>
        <ModalTitle title="Aluevalinnat" />
        <LinearProgress />
        <CloseModalButton />
      </ModalBase>
    )
  }

  if (!data?.areaSelectionFeed) {
    return (
      <ModalBase>
        <ModalTitle title="Aluevalinnat" />
        Aluevalintoja ei löytynyt.
        <CloseModalButton />
      </ModalBase>
    )
  }
  const { data: areaSelections } = data.areaSelectionFeed
  console.log('areaSelections', areaSelections)
  const visibleAreaSelections = Object.entries(
    areaSelections?.map((item) => item)
  ).flatMap(([_, value]) => {
    if (value.common === true) {
      return [value]
    }

    return []
  })
  console.log('visibleAreaSelections', visibleAreaSelections)

  const findAreaSelectionsWithId = areaSelections?.filter((area: any) =>
    areaSelectionIds?.includes(area.id)
  )
  console.log('findAreaSelectionsWithId', findAreaSelectionsWithId)

  const allAreaSelections = visibleAreaSelections.concat(
    findAreaSelectionsWithId
  )
  console.log('allAreaSelections', allAreaSelections)

  return (
    <ModalBase>
      <ModalTitle title="Aluevalinnat" />
      <div style={styles.rows}>
        {allAreaSelections?.map((row) => (
          <List key={row.id}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleAreaSelection(row.id, row.name)}
              >
                <ListItemText primary={row.name} />
              </ListItemButton>
            </ListItem>
          </List>
        ))}
      </div>
      <CloseModalButton />
    </ModalBase>
  )
}
