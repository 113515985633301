import React, { FunctionComponent, useLayoutEffect, useState } from 'react'

import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Checkbox from '@mui/material/Checkbox'
import first from 'lodash/first'

import { FilterStateMode } from 'src/redux/filter'
import { projectQueryWithQuestionStudies } from 'src/graphql/queries'
import { useQueryWithSmoothDataChange } from 'src/graphql/hooks'
import { createQueryFilters } from 'src/utils/filter'
import Questions from './Questions'
import { PanelNames } from '../../SidebarContent'
import { sidebarPanelStyles } from 'src/components/styles/common'
import { useFilter } from 'src/redux'
import { useParams } from 'react-router-dom'

type Props = {
  selectTab: (event: any, tabName: PanelNames) => void
  currentTab: string
}

type StudyLabel = {
  identifier: string
  label: string
}

const QuestionPanel: FunctionComponent<Props> = (props: Props) => {
  const { currentTab, selectTab } = props
  const { projectId } = useParams()
  const { classes } = sidebarPanelStyles()
  const { filters, mode } = useFilter()

  const filterData = createQueryFilters(filters)
  const { study: filteredStudy } = filterData
  const { id: filteredStudyId } = filteredStudy || {}

  const [anchorEl, setAnchorEl] = useState(null)
  const [chosenStudy, setChosenStudy] = useState<StudyLabel | null>(null)

  const handleClick = (event: any) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleSelectStudy = (event: any, study: StudyLabel) => {
    event.stopPropagation()
    setChosenStudy(study)
    handleClose(event)
  }

  const handleClose = (event: any) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const { data } = useQueryWithSmoothDataChange(
    projectQueryWithQuestionStudies,
    {
      variables: {
        id: projectId
      }
    }
  )

  const { project } = data || {}
  const { questionStudies = [] } = project || {}

  useLayoutEffect(() => {
    if (data) {
      const study =
        (filteredStudyId &&
          questionStudies.find(
            (s: StudyLabel) => s.identifier === filteredStudyId
          )) ||
        first<StudyLabel>(questionStudies)

      if (study) {
        setChosenStudy(study)
      }
    }
  }, [data, filteredStudyId, questionStudies])

  if (questionStudies.length === 0) {
    return null
  }

  const expanded = currentTab === PanelNames.Questions

  return (
    <Accordion expanded={expanded} square>
      <AccordionSummary
        id="study-header"
        expandIcon={<ExpandMoreIcon />}
        className={expanded ? classes.panelHeader : classes.nonExpandedHeader}
        onClick={(event) => selectTab(event, PanelNames.Questions)}
      >
        <Typography className={classes.panelHeader} variant="h5">
          {mode === FilterStateMode.Export ? <Checkbox size="small" /> : null}
          Kysymykset
        </Typography>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {chosenStudy ? chosenStudy.label : 'Loading'}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {questionStudies.map((study: StudyLabel) => (
            <MenuItem
              key={study.identifier}
              onClick={(event) => handleSelectStudy(event, study)}
            >
              {study.label}
            </MenuItem>
          ))}
        </Menu>
      </AccordionSummary>

      {chosenStudy ? (
        <AccordionDetails>
          <Questions studyId={chosenStudy.identifier} filterData={filterData} />
        </AccordionDetails>
      ) : null}
    </Accordion>
  )
}

export default React.memo(QuestionPanel)
