import { Feature, FeatureCollection, Geometry } from 'geojson'
import { ZoneData } from 'generated-types'

// Input
export type BoundingBox = {
  minLat: number
  minLng: number
  maxLat: number
  maxLng: number
}

export type Filter = {
  area?: {
    areaType: number
    areaIds: string[]
  }
} & FilterBase

export type MapFilter = {
  area: {
    areaType: number
    areaIds?: string[]
  }
} & FilterBase

export type SegmentId = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'

type FilterBase = {
  gender?: 'f' | 'm'
  age?:
    | 'age_0_6'
    | 'age_7_17'
    | 'age_18_24'
    | 'age_25_34'
    | 'age_35_44'
    | 'age_45_54'
    | 'age_55_64'
    | 'age_65_plus'
    | 'age_65_74'
    | 'age_75_84'
    | 'age_85_plus'
  majority?: 'age_0_17' | 'age_18_plus'
  education?: 'basic' | 'matriculation' | 'vocational' | 'bechelor' | 'master'
  incomeClass?: 'income_class_low' | 'income_class_middle' | 'income_class_high'
  housing?:
    | 'household_owned'
    | 'household_rented'
    | 'summer_cottages'
    | 'home_small_house'
    | 'home_apartment'
  employment?: 'workforce' | 'unemployed' | 'student'
  jobs?:
    | 'job_total'
    | 'job_primary_sector'
    | 'job_secondary_sector'
    | 'job_services'
    | 'job_industry_class_A'
    | 'job_industry_class_B'
    | 'job_industry_class_C'
    | 'job_industry_class_D'
    | 'job_industry_class_E'
    | 'job_industry_class_F'
    | 'job_industry_class_G'
    | 'job_industry_class_H'
    | 'job_industry_class_I'
    | 'job_industry_class_J'
    | 'job_industry_class_K'
    | 'job_industry_class_L'
    | 'job_industry_class_M'
    | 'job_industry_class_N'
    | 'job_industry_class_O'
    | 'job_industry_class_P'
    | 'job_industry_class_Q'
    | 'job_industry_class_R'
    | 'job_industry_class_S'
  household?:
    | 'household_total_te'
    | 'household_young_single'
    | 'household_young_childfree'
    | 'household_with_children'
    | 'household_with_children_young'
    | 'household_with_children_under_school_age'
    | 'household_with_children_school_age'
    | 'household_with_children_teenager'
    | 'household_with_adults'
    | 'household_with_pensioner'
  study?: {
    id: string
    segmentId: SegmentId
  }
  ownData?: {
    id: string
    segmentId: SegmentId
  }
  respondentIdStudy?: {
    id: string
    answerRange: string
    questionId: string
  }
}

// Query
export type PostalCodeDataOutput = {
  zoneData: ZoneData[]
  GeoJSONFeatureCollection: PostalCodeFeatureCollection
}

export type ZoneDataOutput = ZoneData[]

export type PopulationInfoOutput = PopulationInfoCategory[]

export type StudyOutput = Study[]

export type Coordinates = {
  lat: number
  lng: number
}

type NumericResult = {
  population: number
  populationPercentage: number
}

export type SearchPayload = {
  population?: {
    [key: string]: NumericResult
  }
  studies?: {
    [key: string]: {
      [key: string]: NumericResult
    }
  }
  ownData?: {
    [key: string]: {
      [key: string]: NumericResult
    }
  }
}

export type AnswersOutput = {
  total?: number
  values: AnswerOutput[]
}

export type AnswerValueOutput = {
  name?: string
  value: string
  x: number
  y: number
}

export type AnswerOutput = {
  segmentId: string
  name: string
  values: AnswerValueOutput[]
}

export type ScaleOutput = {
  start: string
  end: string
  startLabel: string
  endLabel: string
}

export type QuestionOutput = {
  id: string
  label: string
  type: string
  studyId: string
  scale: ScaleOutput
  answers: AnswersOutput
}

// PostalCode
export type PostalCodeFeatureCollection = FeatureCollection<
  Geometry,
  PostalCodeProperties
>
export type PostalCodeFeature = Feature<Geometry, PostalCodeProperties>

export type PostalCodeProperties = {
  municipalityIdentifier: string
} & ZoneProperties

// Municipality
export type ZoneFeatureCollection = FeatureCollection<Geometry, ZoneProperties>
export type ZoneFeature = Feature<Geometry, ZoneProperties>
export type ZoneProperties = {
  areaType: AreaType
  identifier: string
  name: LocalizedName
}

// PopulationInfo
export type PopulationInfoCategory = TabularDataGroup<PopulationInfoItem>
export type PopulationInfoItem = TabularData

// Study
export type Study = TabularDataGroup<StudySegment>
export type StudySegment = TabularData

// Own Data
export type OwnData = TabularDataGroup<OwnDataSegment>
export type OwnDataSegment = TabularData

// General
export type LocalizedName = {
  fi: string
  sv: string
}

export enum AreaType {
  PostalCode = 0,
  Municipality,
  Region
}

export type TabularDataGroup<T> = {
  identifier: string
  label: string
  data: T[]
  allowExport?: boolean | undefined
}

export type TabularData = {
  identifier: string
  label: string
  population: number
  populationPercentage: number
  allowExport?: boolean | undefined
}
