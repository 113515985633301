import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import React from 'react'

type Props = {
  error: string
  buttonTitle: string
  loading?: boolean
  loadingTitle?: string
}

const styles = {
  error: {
    color: 'red',
    padding: '0.5em'
  }
}

export function SendButton({
  error,
  buttonTitle,
  loading,
  loadingTitle
}: Props): JSX.Element {
  let buttonText = buttonTitle
  if (loading && loadingTitle) {
    buttonText = loadingTitle
  }

  return (
    <React.Fragment>
      <Typography variant="subtitle1" sx={styles.error}>
        {error}
      </Typography>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        style={{ marginTop: '0.5em' }}
        disabled={loading}
      >
        {buttonText}
      </Button>
    </React.Fragment>
  )
}
