import filterSagas from './filterSagas'

import { all, setContext } from 'redux-saga/effects'

export default function getClientSagas() {
  return function* getSagas(context: any) {
    yield setContext(context)
    yield all([filterSagas()])
  }
}
