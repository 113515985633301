import React, { FunctionComponent, useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { first, keys, isEmpty } from 'lodash'

import { updateSearchResults } from 'src/redux/searchresults'
import { TableType } from 'src/redux/TableData'
import { useQueryWithSmoothDataChange } from 'src/graphql/hooks'
import {
  SearchPayload,
  Study,
  StudyOutput
} from 'src/services/graphqlServiceTypes'
import { createQueryFilters } from 'src/utils/filter'
import InsightData from '../panel-data/InsightData'
import { useFilter } from 'src/redux'
import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { Query } from 'generated-types'

type Props = {
  panelName: string
}

const query = gql`
  query Project($id: ID!, $filter: Filter!) {
    project(id: $id) {
      id
      name
      study(filter: $filter) {
        identifier
        label
        allowExport
        data {
          identifier
          label
          population
          populationPercentage
        }
      }
    }
  }
`

const getStudyData = (object: Query['project']): StudyOutput | undefined => {
  const key = first<string>(keys(object))
  // @ts-ignore
  return key ? object[key].study : undefined
}

const Studies: FunctionComponent<Props> = ({ panelName }) => {
  const { projectId } = useParams()
  const dispatch = useDispatch()
  const { filters } = useFilter()

  const filterData = createQueryFilters(filters)

  const { data: projectNationWideData } = useQueryWithSmoothDataChange(query, {
    variables: { id: projectId, filter: {} }
  })
  const { data: projectFilteredData } = useQueryWithSmoothDataChange(query, {
    variables: { id: projectId, filter: filterData }
  })

  useEffect(() => {
    const studies: Study[] = projectNationWideData?.project?.study

    if (isEmpty(filterData) && studies) {
      const organizedData: SearchPayload = {}

      studies.forEach((study) => {
        if (!organizedData?.studies) {
          organizedData.studies = {}
        }

        const { data } = study

        organizedData.studies[study.identifier] = {}

        data.forEach((studySegment) => {
          if (organizedData?.studies) {
            organizedData.studies[study.identifier][studySegment.identifier] = {
              population: studySegment.population,
              populationPercentage: studySegment.populationPercentage
            }
          }
        })
      })
      dispatch(
        updateSearchResults({
          searchCategory: 'total',
          searchPayload: organizedData
        })
      )
    }
  }, [projectNationWideData, dispatch, filterData])

  const studyNationWideData = getStudyData(projectNationWideData)
  const studyFilteredData = getStudyData(projectFilteredData)

  const resettableKeys = studyNationWideData
    ? studyNationWideData.map((study: any) => study.identifier)
    : []

  return (
    <InsightData
      data={studyFilteredData}
      nationWideData={studyNationWideData}
      panelName={panelName}
      tableType={TableType.Study}
      resettableKeys={resettableKeys}
    />
  )
}

export default React.memo(Studies)
