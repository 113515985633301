import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { updateSearchResults } from '../../redux/searchresults'
import {
  PopulationInfoCategory,
  SearchPayload
} from '../../services/graphqlServiceTypes'
import { useQuery } from '@apollo/react-hooks'
import { populationInfoQuery } from '../../graphql/queries'
import { createQueryFilters } from '../../utils/filter'
import SidebarTop from './top-area/SidebarTop'
import SidebarContent from './SidebarContent'
import { useFilter } from 'src/redux'

type Props = {
  projectId: string
}

type PopulationInfo = {
  populationInfo?: PopulationInfoCategory[]
}

const Sidebar: React.FC<Props> = (props) => {
  const { projectId } = props
  const dispatch = useDispatch()
  const { filters } = useFilter()

  const filterData = createQueryFilters(filters)

  const { data } = useQuery<PopulationInfo>(populationInfoQuery, {
    variables: { filter: filterData }
  })

  useEffect(() => {
    if (isEmpty(filterData) && data?.populationInfo) {
      const organizedData: SearchPayload = {}
      const { populationInfo } = data
      populationInfo.forEach((group) => {
        group.data.forEach((item) => {
          if (!organizedData?.population) {
            organizedData.population = {}
          }
          organizedData.population[item.identifier] = {
            population: item.population,
            populationPercentage: item.populationPercentage
          }
        })
      })
      dispatch(
        updateSearchResults({
          searchCategory: 'total',
          searchPayload: organizedData
        })
      )
    }
  }, [data, dispatch, filterData])

  return (
    <div>
      <SidebarTop />
      <SidebarContent projectId={projectId} />
    </div>
  )
}

export default Sidebar
