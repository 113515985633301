import React, { FunctionComponent } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid
} from '@mui/material'
import Slider from '@mui/material/Slider'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import cx from 'classnames'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label
} from 'recharts'

import { addFilter, FilterType, removeFilter } from 'src/redux/filter'
import { closePopover, openPopover } from 'src/redux/ui'
import {
  AnswerOutput,
  AnswerValueOutput,
  QuestionOutput
} from '../../../../services/graphqlServiceTypes'
import { createQueryFilters } from '../../../../utils/filter'
import PopoverContent from '../../../PopoverContent'
import useStyles from './graphStyles'
import { useDispatch } from 'react-redux'
import { useFilter } from 'src/redux'
import { CustomDataSegment } from './Question'

type Props = {
  question: QuestionOutput
  segmentNames: CustomDataSegment[]
}

const NumberIntervalWithRespondentIdQuestion: FunctionComponent<Props> = ({
  question,
  segmentNames
}) => {
  const { classes } = useStyles()
  const { filters } = useFilter()
  const dispatch = useDispatch()

  const { label, scale, answers, studyId, id: questionId } = question
  const filterData = createQueryFilters(filters)

  const { study: filteredStudy } = filterData
  const { id: filteredStudyId, segmentId: filteredSegmentId } =
    filteredStudy || {}

  const { respondentIdStudy: filteredRespondentIdStudy } = filterData
  const { answerRange: filterAnswerRange } = filteredRespondentIdStudy || {}

  const [start, end] = [Number(scale.start), Number(scale.end)]
  const displayMarks = 15 > end - start
  const [answerRangeValues, setAnswerRangeValues] = React.useState([start, end])
  const stringifiedFilterAnswerRange = JSON.stringify(filterAnswerRange)
  const filteredQuestionId = filteredRespondentIdStudy?.questionId

  React.useEffect(() => {
    if (!stringifiedFilterAnswerRange || questionId !== filteredQuestionId) {
      setAnswerRangeValues([start, end])
    }
  }, [stringifiedFilterAnswerRange, start, end, questionId, filteredQuestionId])

  const handleRangeSliderChange = (_: any, newValues: number | number[]) => {
    setAnswerRangeValues(newValues as number[])
  }

  const handleCommitRangeSliderChange = () => {
    const payload = {
      filterName: `${questionId}-range`,
      filterValue: '',
      filterLabel: `${label}: ${answerRangeValues[0]}-${answerRangeValues[1]}`,
      filterType: FilterType.RespondentIdStudy,
      questionId: questionId,
      answerRange: answerRangeValues
    }
    dispatch(addFilter(payload))
  }

  return (
    <Accordion square>
      <AccordionSummary
        className={classes.panelSummary}
        id="question-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.name}>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} className={cx(classes.sliderCell)}>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item className={classes.sliderLabel}>
                    <Typography style={{ textAlign: 'right' }}>
                      {scale.startLabel}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Slider
                      onChange={handleRangeSliderChange}
                      onChangeCommitted={handleCommitRangeSliderChange}
                      min={start}
                      max={end}
                      value={answerRangeValues}
                      valueLabelDisplay="auto"
                      marks={displayMarks}
                    />
                  </Grid>
                  <Grid item className={classes.sliderLabel}>
                    <Typography>{scale.endLabel}</Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {answers.values.map((answer: AnswerOutput) => {
              const selected =
                answer.segmentId === filteredSegmentId &&
                studyId === filteredStudyId
              const { segmentId } = answer
              const segment = segmentNames.find((elem) => elem.id === segmentId)
              const rowName = segment?.name ? segment.name : answer.name

              const statistics = calculateStatistics(answer)

              const handleCellPopoverOpen = (event: any) => {
                dispatch(
                  openPopover({
                    popoverTargetRef: event.currentTarget,
                    popoverContent: (
                      // @ts-ignore
                      <PopoverContent>
                        <div>
                          <span>{rowName}</span>
                          <p>{`Keskiarvo: ${statistics.average}`}</p>
                          <p>{`Mediaani: ${statistics.median}`}</p>
                        </div>
                      </PopoverContent>
                    )
                  })
                )
              }

              const onRowClick = (segmentId: string, segmentLabel: string) => {
                const payload = {
                  filterName: studyId,
                  filterValue: segmentId,
                  filterLabel: segmentLabel,
                  filterType: FilterType.StudySegment
                }
                if (selected) {
                  dispatch(removeFilter(payload))
                } else {
                  dispatch(addFilter(payload))
                }
              }

              return (
                <TableRow
                  key={answer.segmentId}
                  className={cx(
                    classes.tr,
                    selected ? classes.selectedGraphTr : ''
                  )}
                  onClick={() => onRowClick(answer.segmentId, rowName)}
                >
                  <TableCell
                    className={cx(classes.chartCell, classes.segmentBar)}
                    colSpan={3}
                    onClick={handleCellPopoverOpen}
                    onMouseEnter={handleCellPopoverOpen}
                    onMouseLeave={() => dispatch(closePopover())}
                  >
                    <BarChart
                      width={500}
                      height={140}
                      data={answer.values}
                      margin={{
                        top: 0,
                        right: 0,
                        left: -10,
                        bottom: 20
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis name="Vastaus" dataKey="x">
                        <Label value={rowName} offset={0} position="bottom" />
                      </XAxis>
                      <YAxis />
                      <Tooltip />
                      <Bar name="Lukumäärä" dataKey="y" fill="#8884d8" />
                    </BarChart>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}

const calculateStatistics = (answer: AnswerOutput) => {
  let sequence: any = []
  answer.values.forEach((val: AnswerValueOutput) => {
    if (val.y > 0) {
      sequence.push(...Array(val.y).fill(val.x))
    }
  })
  const middle = Math.floor(sequence.length / 2)
  const median =
    sequence.length % 2 === 0
      ? (sequence[middle] + sequence[middle - 1]) / 2
      : sequence[middle]

  const average = Number(
    (
      sequence.reduce((acc: number, curr: number) => {
        return acc + curr
      }, 0) / sequence.length
    ).toFixed(2)
  )
  return { median, average }
}

export default React.memo(NumberIntervalWithRespondentIdQuestion)
