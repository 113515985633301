import { all, put, takeEvery, takeLatest, delay } from 'redux-saga/effects'

import { createApolloClient } from '../utils/apolloClient'
import { logoutMutation } from '../graphql/mutations'

import {
  addFilter,
  addLabel,
  doRemoveFilter,
  doRemoveMapFilter,
  doResetMapFilters,
  doResetMapLabels,
  FilterSpec,
  FilterType,
  removeFilter,
  resetMapFilters,
  setCustomAreaSelection,
  updateFilter,
  updateMapFilter
} from './filter'

import { Action } from 'typescript-fsa'
import { storeUserState } from './auth'
import { getAllPostalsFromAreaSelection } from './filter/area-selection'
import { getPostalAreaName } from 'src/utils/area-functions'
import { setAreaSelection } from './newmap'
import { getConfigFromEnv } from 'src/config'
import { AreaSelection } from 'generated-types'
const {
  app: { SESSION_TIMEOUT }
} = getConfigFromEnv()

function* update(action: Action<FilterSpec>) {
  const {
    filterName,
    filterValue,
    filterLabel,
    filterType,
    questionId,
    answerRange
  } = action.payload

  if (filterName === 'map') {
    yield put(
      setAreaSelection({
        id: '',
        name: ''
      })
    )

    yield put(
      updateMapFilter({
        filterName,
        filterValue,
        filterLabel,
        filterType
      })
    )
  } else {
    yield put(
      updateFilter({
        filterName,
        filterValue,
        filterLabel,
        filterType,
        questionId,
        answerRange
      })
    )
  }

  yield put(
    addLabel({ filterName, labelKey: filterValue, labelName: filterLabel })
  )
}

/** TODO: Refactor, this whole filter-area pattern is not sustainable */
function* setCustomArea(action: Action<AreaSelection>) {
  const allPostals = getAllPostalsFromAreaSelection(action.payload)

  allPostals.forEach((postal) => {
    put(
      updateMapFilter({
        filterName: 'map',
        filterValue: postal,
        filterLabel: getPostalAreaName(postal),
        filterType: FilterType.MapAreaPostalCode
      })
    )
  })

  yield put(
    addLabel({ filterName: 'map', labelKey: '', labelName: 'Useita alueita' })
  )
}

function* remove(action: Action<FilterSpec>) {
  const { filterName, filterValue, filterLabel, filterType } = action.payload

  if (filterName === 'map') {
    yield put(
      setAreaSelection({
        id: '',
        name: ''
      })
    )
    yield put(
      doRemoveMapFilter({
        filterName,
        filterValue,
        filterLabel,
        filterType
      })
    )
  } else {
    yield put(
      doRemoveFilter({
        filterName,
        filterValue,
        filterLabel,
        filterType
      })
    )

    yield put(
      addLabel({ filterName, labelKey: filterValue, labelName: filterLabel })
    )
  }
}

function* resetSelectedMapFilters() {
  yield put(doResetMapLabels())
  yield put(doResetMapFilters())
}

function* sessionExpirationLogOut() {
  yield delay(SESSION_TIMEOUT)
  const client = createApolloClient()
  client.mutate({ mutation: logoutMutation })
  yield put(storeUserState({}))
  client.cache.reset().then(() => {
    window.location.replace('/login')
  })
}

export default function* filterSagas() {
  yield all([
    takeEvery(addFilter, update),
    takeEvery(setCustomAreaSelection, setCustomArea),
    takeEvery(removeFilter, remove),
    takeEvery(resetMapFilters, resetSelectedMapFilters),
    takeLatest('*', sessionExpirationLogOut)
  ])
}
