import React, { FunctionComponent, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import Checkbox from '@mui/material/Checkbox'
import styled from '@emotion/styled'

import { ExportChecked, updateTopLevelExport } from '../../redux/export'
import { FilterStateMode } from 'src/redux/filter'
import { useFilter } from '../../redux/'
import { userStudiesAndOwnData } from '../../graphql/queries'
import Population from './panels/Population'
import QuestionPanel from './panel-data/questions/QuestionPanel'
import Studies from './panels/Studies'
import OwnData from './panels/OwnData'
import { useExportFilter } from '../../redux'
import { isEmpty } from 'lodash'
import { sidebarPanelStyles } from '../styles/common'

const HorizontalRule = styled.div`
  border-bottom: 1px solid #ccc;
  height: 1px;
  width: 100%;
`

const Panels = styled.div`
  overflow-y: auto;
  height: 85vh;
`

export enum PanelNames {
  Population = 'population',
  Household = 'household',
  Employment = 'employment',
  Studies = 'study',
  OwnData = 'ownData',
  Questions = 'questions'
}

type Props = {
  projectId: string
}

type Translations = {
  [key: string]: string
}

const translations: Translations = {
  population: 'Väestötiedot',
  household: 'Kotitaloudet',
  employment: 'Työllisyys',
  study: 'Tutkimukset',
  ownData: 'Oma data'
}

const SidebarContent: FunctionComponent<Props> = (props) => {
  const { projectId } = props
  const { classes } = sidebarPanelStyles()
  const dispatch = useDispatch()
  const { panels } = useExportFilter()
  const { mode } = useFilter()
  const [currentTab, setExpandedTab] = useState<PanelNames | ''>(
    PanelNames.Population
  )

  function selectTab(event: any, tabName: PanelNames): void {
    if (event?.target.type === 'checkbox') {
      return
    }
    tabName === currentTab ? setExpandedTab('') : setExpandedTab(tabName)
  }

  const { data } = useQuery(userStudiesAndOwnData, {
    variables: {
      id: projectId
    }
  })

  const panelTabs: PanelNames[] = [
    PanelNames.Population,
    PanelNames.Household,
    PanelNames.Employment
  ]

  if (!isEmpty(data?.project?.studyIds)) {
    panelTabs.push(PanelNames.Studies)
  }

  if (!isEmpty(data?.project?.ownDataIds)) {
    panelTabs.push(PanelNames.OwnData)
  }

  const showQuestionStudies =
    data?.project?.questionStudyIds && mode !== FilterStateMode.Export

  const toggleCheckbox = (category: string) => {
    const newCheckedState =
      panels[category] !== ExportChecked.Not
        ? ExportChecked.Not
        : ExportChecked.Yes
    dispatch(
      updateTopLevelExport({ topCategory: category, checked: newCheckedState })
    )
  }

  function createPanel(tabId: PanelNames) {
    switch (tabId) {
      case 'study':
        return <Studies panelName={tabId} />
      case 'ownData':
        return <OwnData panelName={tabId} />
      case 'population':
        return <Population panelName={tabId} />
      case 'household':
        return <Population panelName={tabId} />
      case 'employment':
        return <Population panelName={tabId} />
    }
  }

  return (
    <Panels>
      {panelTabs.map((tabId) => {
        const expanded = currentTab === tabId
        return (
          <div key={`${tabId}-panelName`}>
            <Accordion expanded={expanded} square>
              <AccordionSummary
                id={`${tabId}-header`}
                className={
                  expanded ? classes.panelHeader : classes.nonExpandedHeader
                }
                expandIcon={<ExpandMoreIcon />}
                onClick={(event) => selectTab(event, tabId)}
              >
                <Typography variant="h2" className={classes.panelHeader}>
                  {mode === FilterStateMode.Export ? (
                    <Checkbox
                      size="small"
                      key={`${tabId}-checkbox`}
                      onChange={() => toggleCheckbox(tabId)}
                      checked={panels[tabId] === ExportChecked.Yes}
                      indeterminate={panels[tabId] === ExportChecked.Partial}
                    />
                  ) : null}
                  {translations[tabId]}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>{createPanel(tabId)}</AccordionDetails>
            </Accordion>
            <HorizontalRule />
          </div>
        )
      })}

      {showQuestionStudies ? (
        <QuestionPanel selectTab={selectTab} currentTab={currentTab} />
      ) : null}
    </Panels>
  )
}

export default React.memo(SidebarContent)
