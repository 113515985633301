export const postalCodesByMunicipality: Array<{
  postalCodes: Array<string>
  code: string
}> = [
  {
    postalCodes: [
      '32610',
      '32620',
      '32700',
      '32701',
      '32704',
      '32705',
      '32710',
      '32760',
      '32770'
    ],
    code: '102'
  },
  {
    postalCodes: ['31640', '31641', '31644', '31650'],
    code: '103'
  },
  {
    postalCodes: ['89400', '89401', '89430', '89540', '89560'],
    code: '105'
  },
  {
    postalCodes: [
      '05440',
      '05460',
      '05464',
      '05465',
      '05470',
      '05510',
      '05620',
      '05720',
      '05800',
      '05801',
      '05804',
      '05805',
      '05810',
      '05814',
      '05820',
      '05830',
      '05831',
      '05834',
      '05840',
      '05844',
      '05850',
      '05860',
      '05880',
      '05900',
      '05901',
      '05904',
      '05950'
    ],
    code: '106'
  },
  {
    postalCodes: [
      '38490',
      '39100',
      '39101',
      '39104',
      '39110',
      '39130',
      '39170',
      '39180',
      '39195',
      '39200',
      '39204',
      '39230',
      '39290',
      '39360',
      '39380'
    ],
    code: '108'
  },
  {
    postalCodes: [
      '12170',
      '13100',
      '13101',
      '13102',
      '13105',
      '13110',
      '13111',
      '13130',
      '13131',
      '13134',
      '13135',
      '13200',
      '13204',
      '13210',
      '13211',
      '13215',
      '13220',
      '13250',
      '13270',
      '13300',
      '13430',
      '13500',
      '13501',
      '13505',
      '13530',
      '13600',
      '13725',
      '14300',
      '14304',
      '14330',
      '14370',
      '14450',
      '14500',
      '14530',
      '14680',
      '14690',
      '14700',
      '14770',
      '14810',
      '14814',
      '14820',
      '14840',
      '14870',
      '14930',
      '16900',
      '16901',
      '16905',
      '16960',
      '16970',
      '16980'
    ],
    code: '109'
  },
  {
    postalCodes: [
      '18100',
      '18101',
      '18105',
      '18120',
      '18130',
      '18150',
      '18200',
      '18300',
      '18304',
      '18600',
      '19110',
      '19120',
      '19210',
      '19250',
      '19260'
    ],
    code: '111'
  },
  {
    postalCodes: [
      '91100',
      '91101',
      '91110',
      '91140',
      '91150',
      '95100',
      '95110',
      '95130',
      '95160',
      '95164'
    ],
    code: '139'
  },
  {
    postalCodes: [
      '74100',
      '74101',
      '74102',
      '74105',
      '74120',
      '74121',
      '74124',
      '74125',
      '74130',
      '74135',
      '74140',
      '74150',
      '74160',
      '74170',
      '74420',
      '74510',
      '74520',
      '74540',
      '74550',
      '74590',
      '74595'
    ],
    code: '140'
  },
  {
    postalCodes: [
      '19160',
      '47310',
      '47400',
      '47401',
      '47405',
      '47440',
      '47450',
      '47460',
      '47490',
      '47520',
      '47540',
      '47610'
    ],
    code: '142'
  },
  {
    postalCodes: [
      '39410',
      '39430',
      '39450',
      '39500',
      '39501',
      '39504',
      '39505',
      '39530',
      '39580',
      '39590',
      '39610'
    ],
    code: '143'
  },
  {
    postalCodes: [
      '60450',
      '60720',
      '60760',
      '60800',
      '60801',
      '60804',
      '61330',
      '61340',
      '61350'
    ],
    code: '145'
  },
  {
    postalCodes: [
      '81350',
      '81420',
      '81430',
      '81450',
      '81460',
      '81470',
      '82815',
      '82820',
      '82830',
      '82900',
      '82901',
      '82915',
      '82960',
      '82967',
      '82980'
    ],
    code: '146'
  },
  {
    postalCodes: [
      '99800',
      '99801',
      '99830',
      '99831',
      '99860',
      '99870',
      '99871',
      '99885',
      '99910',
      '99930',
      '99940',
      '99944'
    ],
    code: '148'
  },
  {
    postalCodes: [
      '10120',
      '10140',
      '10160',
      '10210',
      '10211',
      '10230',
      '10250',
      '10270'
    ],
    code: '149'
  },
  {
    postalCodes: [
      '64810',
      '64820',
      '64830',
      '64840',
      '64850',
      '64900',
      '64901',
      '64930'
    ],
    code: '151'
  },
  {
    postalCodes: [
      '61500',
      '61501',
      '61520',
      '61550',
      '61560',
      '66440',
      '66441'
    ],
    code: '152'
  },
  {
    postalCodes: [
      '55100',
      '55101',
      '55102',
      '55105',
      '55120',
      '55121',
      '55124',
      '55125',
      '55400',
      '55420',
      '55510',
      '55610',
      '55615',
      '55700',
      '55800',
      '55801',
      '55804',
      '55910'
    ],
    code: '153'
  },
  {
    postalCodes: [
      '12380',
      '12400',
      '12401',
      '12450',
      '13330',
      '14140',
      '14200',
      '14201',
      '14204',
      '14205',
      '14240'
    ],
    code: '165'
  },
  {
    postalCodes: [
      '80020',
      '80100',
      '80101',
      '80102',
      '80104',
      '80105',
      '80110',
      '80130',
      '80140',
      '80141',
      '80145',
      '80160',
      '80161',
      '80165',
      '80170',
      '80175',
      '80200',
      '80210',
      '80220',
      '80225',
      '80230',
      '80260',
      '80264',
      '80330',
      '80334',
      '81200',
      '81201',
      '81210',
      '81260',
      '81270',
      '81280',
      '81281',
      '81290',
      '81295',
      '81320',
      '81330',
      '81360',
      '81390',
      '82110',
      '82120',
      '82140',
      '82160',
      '82170',
      '82180',
      '82200',
      '82201',
      '82210',
      '82220',
      '82710',
      '82730',
      '82750',
      '82760',
      '82840',
      '82850',
      '82865',
      '82870',
      '82880'
    ],
    code: '167'
  },
  {
    postalCodes: ['31600', '31601', '31610', '31620', '31630'],
    code: '169'
  },
  {
    postalCodes: ['22130', '22140', '22150', '22151'],
    code: '170'
  },
  {
    postalCodes: [
      '77460',
      '77480',
      '78880',
      '79600',
      '79601',
      '79620',
      '79630',
      '79660',
      '79680',
      '79690'
    ],
    code: '171'
  },
  {
    postalCodes: [
      '19650',
      '19651',
      '19654',
      '19670',
      '19920',
      '41710',
      '41730',
      '41750',
      '41770'
    ],
    code: '172'
  },
  {
    postalCodes: [
      '83870',
      '83880',
      '83900',
      '83901',
      '83904',
      '83910',
      '83915',
      '83940',
      '83950',
      '83985'
    ],
    code: '176'
  },
  {
    postalCodes: ['35500', '35520', '35530', '35540', '35550'],
    code: '177'
  },
  {
    postalCodes: [
      '51760',
      '51780',
      '51810',
      '51820',
      '51850',
      '51860',
      '51880',
      '51890',
      '51900',
      '51901',
      '51904',
      '51905',
      '51930',
      '51940',
      '51980'
    ],
    code: '178'
  },
  {
    postalCodes: [
      '40011',
      '40014',
      '40049',
      '40056',
      '40063',
      '40100',
      '40101',
      '40104',
      '40105',
      '40200',
      '40250',
      '40254',
      '40270',
      '40271',
      '40274',
      '40275',
      '40320',
      '40321',
      '40324',
      '40325',
      '40340',
      '40341',
      '40345',
      '40350',
      '40351',
      '40400',
      '40420',
      '40500',
      '40504',
      '40505',
      '40520',
      '40521',
      '40524',
      '40525',
      '40530',
      '40531',
      '40600',
      '40620',
      '40630',
      '40640',
      '40660',
      '40700',
      '40701',
      '40704',
      '40705',
      '40720',
      '40740',
      '40800',
      '40801',
      '40805',
      '40820',
      '40900',
      '40905',
      '41006',
      '41120',
      '41140',
      '41160',
      '41161',
      '41450',
      '41630',
      '41800',
      '41801',
      '41820',
      '41840',
      '41860',
      '41870',
      '41880',
      '41930',
      '41940'
    ],
    code: '179'
  },
  {
    postalCodes: ['38800', '38860'],
    code: '181'
  },
  {
    postalCodes: [
      '35400',
      '35600',
      '35601',
      '35610',
      '35630',
      '42100',
      '42101',
      '42104',
      '42105',
      '42140',
      '42220',
      '42300',
      '42301',
      '42330',
      '42440'
    ],
    code: '182'
  },
  {
    postalCodes: [
      '04400',
      '04401',
      '04404',
      '04405',
      '04410',
      '04414',
      '04415',
      '04420',
      '04430',
      '04431',
      '04434',
      '04435',
      '04440',
      '04444',
      '04460',
      '04480'
    ],
    code: '186'
  },
  {
    postalCodes: [
      '20660',
      '20664',
      '20760',
      '20761',
      '20764',
      '20780',
      '20781',
      '20784',
      '20785',
      '21500',
      '21501',
      '21504',
      '21620'
    ],
    code: '202'
  },
  {
    postalCodes: [
      '73600',
      '73601',
      '73604',
      '73620',
      '73640',
      '73645',
      '73670'
    ],
    code: '204'
  },
  {
    postalCodes: [
      '87070',
      '87100',
      '87101',
      '87102',
      '87105',
      '87150',
      '87200',
      '87250',
      '87254',
      '87300',
      '87400',
      '87401',
      '87404',
      '87500',
      '87601',
      '87700',
      '87705',
      '87788',
      '87800',
      '87830',
      '87850',
      '87900',
      '87910',
      '87930',
      '87950',
      '87970',
      '88200',
      '88201',
      '88270'
    ],
    code: '205'
  },
  {
    postalCodes: [
      '68100',
      '68150',
      '85100',
      '85101',
      '85104',
      '85105',
      '85120',
      '85140',
      '85150',
      '85160',
      '85180'
    ],
    code: '208'
  },
  {
    postalCodes: [
      '36100',
      '36110',
      '36120',
      '36200',
      '36201',
      '36205',
      '36220',
      '36224',
      '36225',
      '36240',
      '36270',
      '36280',
      '36284',
      '36340',
      '36420',
      '36430',
      '36520',
      '36570',
      '36810',
      '36840',
      '36880'
    ],
    code: '211'
  },
  {
    postalCodes: [
      '51200',
      '51201',
      '51205',
      '51260',
      '51270',
      '51310',
      '51335',
      '51340',
      '51360',
      '51380',
      '51430',
      '51440',
      '51460'
    ],
    code: '213'
  },
  {
    postalCodes: [
      '38700',
      '38701',
      '38704',
      '38705',
      '38710',
      '38720',
      '38740',
      '38750',
      '38760',
      '38770',
      '38840',
      '38841',
      '38910'
    ],
    code: '214'
  },
  {
    postalCodes: ['43300', '43340'],
    code: '216'
  },
  {
    postalCodes: ['69100', '69101', '69150', '69154', '69170'],
    code: '217'
  },
  {
    postalCodes: ['64350', '64370'],
    code: '218'
  },
  {
    postalCodes: ['03600', '03601', '03604', '03605', '03620', '03710'],
    code: '224'
  },
  {
    postalCodes: [
      '43490',
      '43500',
      '43501',
      '43520',
      '43540',
      '43560',
      '43610',
      '43640',
      '43660'
    ],
    code: '226'
  },
  {
    postalCodes: [
      '39920',
      '39930',
      '39940',
      '39960',
      '39965',
      '39980',
      '39990'
    ],
    code: '230'
  },
  {
    postalCodes: ['64260'],
    code: '231'
  },
  {
    postalCodes: [
      '61800',
      '61801',
      '61804',
      '61805',
      '61810',
      '61820',
      '61850',
      '61860',
      '61880',
      '61910',
      '61920',
      '61940',
      '61950',
      '61960',
      '61980'
    ],
    code: '232'
  },
  {
    postalCodes: [
      '62150',
      '62200',
      '62201',
      '62204',
      '62205',
      '62220',
      '62230',
      '62240',
      '62260',
      '62300',
      '62301',
      '62310',
      '62340',
      '62350',
      '62370',
      '62375',
      '62380',
      '62410',
      '62420',
      '62421',
      '62430',
      '62435',
      '62440',
      '62470'
    ],
    code: '233'
  },
  {
    postalCodes: ['02700', '02701', '02704', '02705'],
    code: '235'
  },
  {
    postalCodes: ['69600', '69601', '69605', '69660'],
    code: '236'
  },
  {
    postalCodes: ['72600', '72601', '72630', '72930', '72950', '72980'],
    code: '239'
  },
  {
    postalCodes: [
      '94100',
      '94101',
      '94102',
      '94104',
      '94105',
      '94200',
      '94300',
      '94600',
      '94604',
      '94605',
      '94700',
      '94704',
      '94720',
      '94800',
      '94830',
      '94900'
    ],
    code: '240'
  },
  {
    postalCodes: [
      '94400',
      '94401',
      '94450',
      '94455',
      '94460',
      '94500',
      '95310',
      '95315',
      '95365',
      '95370'
    ],
    code: '241'
  },
  {
    postalCodes: ['90440', '90441', '90444', '90450', '90451', '90454'],
    code: '244'
  },
  {
    postalCodes: [
      '04020',
      '04200',
      '04201',
      '04204',
      '04205',
      '04220',
      '04224',
      '04230',
      '04234',
      '04250',
      '04251',
      '04254',
      '04260',
      '04261'
    ],
    code: '245'
  },
  {
    postalCodes: [
      '41970',
      '42520',
      '42560',
      '42700',
      '42701',
      '42704',
      '42705',
      '42720',
      '42721',
      '42800',
      '42820',
      '42910',
      '42930'
    ],
    code: '249'
  },
  {
    postalCodes: ['39810', '39820', '39880'],
    code: '250'
  },
  {
    postalCodes: ['43900', '43901', '43940', '43960'],
    code: '256'
  },
  {
    postalCodes: [
      '02390',
      '02400',
      '02401',
      '02404',
      '02405',
      '02410',
      '02420',
      '02421',
      '02430',
      '02431',
      '02434',
      '02435',
      '02440',
      '02450',
      '02460',
      '02470',
      '02471',
      '02480',
      '02490',
      '02510',
      '02520',
      '02540',
      '02550',
      '02880'
    ],
    code: '257'
  },
  {
    postalCodes: [
      '58350',
      '59800',
      '59810',
      '59820',
      '82380',
      '82395',
      '82430',
      '82460',
      '82490',
      '82500',
      '82501',
      '82504',
      '82510',
      '82580',
      '82590'
    ],
    code: '260'
  },
  {
    postalCodes: [
      '97470',
      '99100',
      '99101',
      '99110',
      '99120',
      '99130',
      '99131',
      '99134',
      '99135',
      '99140',
      '99150',
      '99160',
      '99170',
      '99180',
      '99190',
      '99195',
      '99230',
      '99240',
      '99250',
      '99270',
      '99280',
      '99290',
      '99340'
    ],
    code: '261'
  },
  {
    postalCodes: [
      '74610',
      '74630',
      '74640',
      '74670',
      '74680',
      '74700',
      '74701',
      '74704',
      '74705',
      '74720',
      '74740',
      '74770',
      '74840',
      '74940',
      '74980'
    ],
    code: '263'
  },
  {
    postalCodes: ['43390', '43800', '43840'],
    code: '265'
  },
  {
    postalCodes: [
      '32800',
      '32801',
      '32804',
      '32805',
      '32810',
      '32830',
      '32860',
      '32910',
      '32920'
    ],
    code: '271'
  },
  {
    postalCodes: [
      '67020',
      '67100',
      '67101',
      '67102',
      '67104',
      '67105',
      '67200',
      '67204',
      '67300',
      '67304',
      '67400',
      '67404',
      '67410',
      '67500',
      '67600',
      '67604',
      '67605',
      '67700',
      '67701',
      '67705',
      '67800',
      '67805',
      '67900',
      '68210',
      '68220',
      '68230',
      '68240',
      '68300',
      '68304',
      '68320',
      '68370',
      '68380',
      '68390',
      '68550',
      '68999'
    ],
    code: '272'
  },
  {
    postalCodes: [
      '95800',
      '95830',
      '95840',
      '95850',
      '95860',
      '95900',
      '95901',
      '95920',
      '95930',
      '95970',
      '95974',
      '95980',
      '95990'
    ],
    code: '273'
  },
  {
    postalCodes: ['44300', '44320', '44330', '44350', '44370'],
    code: '275'
  },
  {
    postalCodes: [
      '80510',
      '80511',
      '80710',
      '80714',
      '80770',
      '80780',
      '80790',
      '80791',
      '80850',
      '80910',
      '81100',
      '81101',
      '81120',
      '81160',
      '81220',
      '81230',
      '81235'
    ],
    code: '276'
  },
  {
    postalCodes: ['66200', '66201', '66210', '66230', '66280', '66290'],
    code: '280'
  },
  {
    postalCodes: ['31500', '31501'],
    code: '284'
  },
  {
    postalCodes: [
      '46930',
      '48100',
      '48101',
      '48102',
      '48104',
      '48105',
      '48130',
      '48200',
      '48210',
      '48220',
      '48230',
      '48300',
      '48310',
      '48350',
      '48400',
      '48401',
      '48405',
      '48410',
      '48600',
      '48601',
      '48604',
      '48605',
      '48700',
      '48710',
      '48720',
      '48750',
      '48770',
      '48771',
      '48800',
      '48810',
      '48900',
      '48910',
      '48930',
      '49300',
      '49340'
    ],
    code: '285'
  },
  {
    postalCodes: [
      '45100',
      '45101',
      '45102',
      '45104',
      '45105',
      '45120',
      '45130',
      '45135',
      '45150',
      '45154',
      '45160',
      '45164',
      '45165',
      '45200',
      '45201',
      '45204',
      '45330',
      '45360',
      '45365',
      '45370',
      '45371',
      '45410',
      '45411',
      '45460',
      '45610',
      '45611',
      '45615',
      '45700',
      '45701',
      '45704',
      '45705',
      '45720',
      '45724',
      '45725',
      '45740',
      '45744',
      '45750',
      '45810',
      '45910',
      '45911',
      '45914',
      '45940',
      '46110',
      '46140',
      '46230',
      '46400',
      '46430',
      '46450',
      '46570',
      '46710',
      '46730',
      '46750',
      '46800',
      '46801',
      '46805',
      '46810',
      '46860',
      '46900',
      '46901',
      '46904',
      '46910',
      '46914',
      '46950',
      '46960',
      '47110',
      '47150',
      '47200',
      '47201',
      '47205',
      '47260',
      '47650',
      '47710',
      '47760',
      '47810',
      '47830',
      '47850',
      '47900',
      '47910'
    ],
    code: '286'
  },
  {
    postalCodes: [
      '64100',
      '64101',
      '64140',
      '64300',
      '64304',
      '64320',
      '64440',
      '64450',
      '64460',
      '64480',
      '64490'
    ],
    code: '287'
  },
  {
    postalCodes: ['68410', '68500', '68501', '68700', '68750'],
    code: '288'
  },
  {
    postalCodes: [
      '88730',
      '88740',
      '88760',
      '88820',
      '88900',
      '88901',
      '88905',
      '88930',
      '88940'
    ],
    code: '290'
  },
  {
    postalCodes: [
      '17780',
      '17800',
      '17801',
      '17840',
      '17850',
      '17870',
      '17930',
      '17950',
      '17970'
    ],
    code: '291'
  },
  {
    postalCodes: ['22810', '22820', '22830'],
    code: '295'
  },
  {
    postalCodes: [
      '70010',
      '70029',
      '70049',
      '70056',
      '70080',
      '70090',
      '70100',
      '70101',
      '70104',
      '70105',
      '70110',
      '70111',
      '70115',
      '70150',
      '70151',
      '70154',
      '70200',
      '70201',
      '70204',
      '70210',
      '70211',
      '70214',
      '70240',
      '70260',
      '70280',
      '70300',
      '70340',
      '70344',
      '70400',
      '70401',
      '70420',
      '70421',
      '70425',
      '70460',
      '70461',
      '70500',
      '70501',
      '70600',
      '70601',
      '70620',
      '70621',
      '70624',
      '70625',
      '70700',
      '70701',
      '70704',
      '70705',
      '70780',
      '70781',
      '70785',
      '70800',
      '70801',
      '70804',
      '70805',
      '70820',
      '70821',
      '70824',
      '70825',
      '70840',
      '70844',
      '70870',
      '70871',
      '71006',
      '71130',
      '71150',
      '71160',
      '71310',
      '71330',
      '71360',
      '71380',
      '71480',
      '71490',
      '71520',
      '71570',
      '71610',
      '71650',
      '71660',
      '71670',
      '71680',
      '71690',
      '71720',
      '71730',
      '71740',
      '71745',
      '71750',
      '71760',
      '71775',
      '71950',
      '72100',
      '72101',
      '72140',
      '72490',
      '73300',
      '73301',
      '73310',
      '73320',
      '73350',
      '73360',
      '73410',
      '73460',
      '73470',
      '73500',
      '73501',
      '73710',
      '73730',
      '73770',
      '73810',
      '73830'
    ],
    code: '297'
  },
  {
    postalCodes: [
      '63100',
      '63101',
      '63120',
      '63130',
      '63150',
      '63160',
      '63230',
      '63250',
      '63450'
    ],
    code: '300'
  },
  {
    postalCodes: [
      '61220',
      '61230',
      '61250',
      '61270',
      '61280',
      '61290',
      '61300',
      '61301',
      '61304',
      '61305',
      '61310',
      '61360',
      '61370',
      '61380',
      '61600',
      '61601',
      '61605',
      '61630',
      '61640',
      '61680',
      '61710',
      '61720',
      '61730',
      '61760',
      '66300',
      '66301',
      '66320',
      '66330',
      '66340',
      '66350',
      '66360',
      '66370'
    ],
    code: '301'
  },
  {
    postalCodes: ['23360'],
    code: '304'
  },
  {
    postalCodes: [
      '93600',
      '93601',
      '93605',
      '93700',
      '93800',
      '93830',
      '93900',
      '93999'
    ],
    code: '305'
  },
  {
    postalCodes: [
      '83500',
      '83501',
      '83504',
      '83505',
      '83550',
      '83630',
      '83660'
    ],
    code: '309'
  },
  {
    postalCodes: ['43700', '43701', '43720'],
    code: '312'
  },
  {
    postalCodes: ['16540', '16600', '16601', '16610', '16670'],
    code: '316'
  },
  {
    postalCodes: ['86710', '86790'],
    code: '317'
  },
  {
    postalCodes: ['22730'],
    code: '318'
  },
  {
    postalCodes: [
      '97590',
      '97645',
      '98100',
      '98101',
      '98104',
      '98120',
      '98230',
      '98310',
      '98350',
      '98360',
      '98400',
      '98420',
      '98440',
      '98450',
      '98710',
      '98720',
      '98999'
    ],
    code: '320'
  },
  {
    postalCodes: [
      '25650',
      '25700',
      '25701',
      '25704',
      '25710',
      '25730',
      '25760',
      '25810',
      '25820',
      '25830',
      '25840',
      '25860',
      '25870',
      '25900',
      '25910',
      '25930',
      '25940',
      '25950',
      '25960'
    ],
    code: '322'
  },
  {
    postalCodes: [
      '15100',
      '15101',
      '15102',
      '15104',
      '15110',
      '15111',
      '15114',
      '15115',
      '15140',
      '15141',
      '15144',
      '15145',
      '15150',
      '15151',
      '15160',
      '15164',
      '15165',
      '15170',
      '15175',
      '15200',
      '15204',
      '15207',
      '15210',
      '15215',
      '15230',
      '15240',
      '15241',
      '15245',
      '15300',
      '15304',
      '15320',
      '15340',
      '15460',
      '15500',
      '15501',
      '15505',
      '15520',
      '15540',
      '15544',
      '15550',
      '15551',
      '15555',
      '15560',
      '15561',
      '15564',
      '15565',
      '15580',
      '15610',
      '15680',
      '15685',
      '15700',
      '15701',
      '15704',
      '15705',
      '15800',
      '15801',
      '15810',
      '15815',
      '15820',
      '15830',
      '15835',
      '15840',
      '15850',
      '15900',
      '15901',
      '15950',
      '16100',
      '16160'
    ],
    code: '398'
  },
  {
    postalCodes: [
      '66400',
      '66401',
      '66404',
      '66405',
      '66420',
      '66450',
      '66460',
      '66470'
    ],
    code: '399'
  },
  {
    postalCodes: ['23800', '23801', '23804', '23805', '23840', '23880'],
    code: '400'
  },
  {
    postalCodes: [
      '71910',
      '71920',
      '71960',
      '73100',
      '73101',
      '73105',
      '73110',
      '73120',
      '73200',
      '73201',
      '73230',
      '73250',
      '74470'
    ],
    code: '402'
  },
  {
    postalCodes: [
      '62600',
      '62601',
      '62610',
      '62620',
      '62630',
      '62640',
      '62660',
      '62664'
    ],
    code: '403'
  },
  {
    postalCodes: [
      '53100',
      '53101',
      '53102',
      '53104',
      '53105',
      '53130',
      '53200',
      '53204',
      '53205',
      '53300',
      '53301',
      '53305',
      '53400',
      '53420',
      '53500',
      '53501',
      '53505',
      '53550',
      '53554',
      '53600',
      '53604',
      '53605',
      '53650',
      '53810',
      '53814',
      '53830',
      '53850',
      '53851',
      '53855',
      '53900',
      '53920',
      '53950',
      '54100',
      '54101',
      '54105',
      '54110',
      '54120',
      '54190',
      '54230',
      '54235',
      '54250',
      '54260',
      '54270',
      '54300',
      '54310',
      '54330',
      '54390',
      '54410',
      '54430',
      '54460',
      '55300',
      '55320',
      '55330'
    ],
    code: '405'
  },
  {
    postalCodes: [
      '47350',
      '07800',
      '07810',
      '07820',
      '07830',
      '07840',
      '07850'
    ],
    code: '407'
  },
  {
    postalCodes: [
      '62100',
      '62101',
      '62104',
      '62105',
      '62130',
      '62160',
      '62165',
      '62170',
      '62175',
      '62185',
      '62190',
      '62280',
      '62290',
      '62295'
    ],
    code: '408'
  },
  {
    postalCodes: [
      '41180',
      '41310',
      '41325',
      '41330',
      '41331',
      '41340',
      '41341',
      '41350',
      '41360',
      '41370',
      '41390',
      '41400',
      '41401'
    ],
    code: '410'
  },
  {
    postalCodes: ['54710', '54820', '54850'],
    code: '416'
  },
  {
    postalCodes: ['22610'],
    code: '417'
  },
  {
    postalCodes: [
      '33880',
      '33881',
      '37500',
      '37501',
      '37505',
      '37530',
      '37550',
      '37551',
      '37560',
      '37565',
      '37570',
      '37574',
      '37575'
    ],
    code: '418'
  },
  {
    postalCodes: [
      '71460',
      '71470',
      '78480',
      '79100',
      '79101',
      '79104',
      '79130',
      '79140',
      '79150',
      '79160',
      '79180',
      '79190',
      '79230',
      '79255',
      '79265',
      '79330',
      '79350',
      '79410'
    ],
    code: '420'
  },
  {
    postalCodes: ['69420', '69440', '69450'],
    code: '421'
  },
  {
    postalCodes: [
      '81560',
      '81570',
      '81590',
      '81650',
      '81660',
      '81700',
      '81701',
      '81705',
      '81720',
      '81750',
      '81810',
      '81820',
      '81850',
      '81860',
      '81950',
      '81970',
      '83960'
    ],
    code: '422'
  },
  {
    postalCodes: [
      '21350',
      '21360',
      '21410',
      '21420',
      '21421',
      '21424',
      '21425',
      '21430',
      '21450'
    ],
    code: '423'
  },
  {
    postalCodes: ['91900', '91901', '91910', '91915', '91930'],
    code: '425'
  },
  {
    postalCodes: [
      '80400',
      '80401',
      '80405',
      '83100',
      '83101',
      '83130',
      '83140',
      '83150',
      '83160',
      '83320',
      '83330',
      '83340',
      '83400',
      '83430',
      '83450',
      '83460',
      '83480'
    ],
    code: '426'
  },
  {
    postalCodes: [
      '31960',
      '32200',
      '32201',
      '32204',
      '32205',
      '32210',
      '32250',
      '32260',
      '32270',
      '32280',
      '32300',
      '32410',
      '32430',
      '32440',
      '32441',
      '32450',
      '32560'
    ],
    code: '430'
  },
  {
    postalCodes: [
      '12520',
      '12524',
      '12540',
      '12600',
      '12630',
      '12640',
      '12700',
      '12701',
      '12704',
      '12750',
      '12820',
      '12920',
      '12950'
    ],
    code: '433'
  },
  {
    postalCodes: [
      '07700',
      '07720',
      '07740',
      '07750',
      '07780',
      '07870',
      '07880',
      '07884',
      '07890',
      '07900',
      '07901',
      '07904',
      '07905',
      '07910',
      '07920',
      '07930',
      '07940',
      '07945',
      '07955',
      '07960',
      '07970',
      '07980',
      '07990'
    ],
    code: '434'
  },
  {
    postalCodes: ['19910', '19950'],
    code: '435'
  },
  {
    postalCodes: ['91980'],
    code: '436'
  },
  {
    postalCodes: ['22630'],
    code: '438'
  },
  {
    postalCodes: ['68555', '68560', '68570', '68574', '68580'],
    code: '440'
  },
  {
    postalCodes: [
      '46530',
      '54500',
      '54501',
      '54505',
      '54510',
      '54530',
      '54550',
      '54580',
      '54590'
    ],
    code: '441'
  },
  {
    postalCodes: [
      '03810',
      '03850',
      '03870',
      '08100',
      '08101',
      '08104',
      '08105',
      '08150',
      '08151',
      '08154',
      '08200',
      '08201',
      '08204',
      '08205',
      '08350',
      '08360',
      '08450',
      '08480',
      '08500',
      '08501',
      '08680',
      '08700',
      '08701',
      '08800',
      '09120',
      '09220',
      '09430',
      '09520',
      '09630',
      '09810',
      '09930'
    ],
    code: '444'
  },
  {
    postalCodes: [
      '21600',
      '21601',
      '21604',
      '21605',
      '21610',
      '21630',
      '21650',
      '21660',
      '21661',
      '21670',
      '21680',
      '21710',
      '21720',
      '21740',
      '21750',
      '21760',
      '21770',
      '23390'
    ],
    code: '445'
  },
  {
    postalCodes: [
      '66100',
      '66140',
      '66141',
      '66160',
      '66220',
      '66240',
      '66260'
    ],
    code: '475'
  },
  {
    postalCodes: [
      '22100',
      '22101',
      '22105',
      '22110',
      '22111',
      '22120',
      '22155',
      '22160'
    ],
    code: '478'
  },
  {
    postalCodes: ['21480', '21490', '21560'],
    code: '480'
  },
  {
    postalCodes: [
      '21230',
      '21240',
      '21244',
      '21250',
      '21251',
      '21254',
      '21255'
    ],
    code: '481'
  },
  {
    postalCodes: ['86220', '86230', '86240'],
    code: '483'
  },
  {
    postalCodes: [
      '29740',
      '29750',
      '29760',
      '29790',
      '29900',
      '29901',
      '29940'
    ],
    code: '484'
  },
  {
    postalCodes: [
      '49700',
      '49720',
      '49730',
      '49750',
      '49760',
      '49770',
      '54490'
    ],
    code: '489'
  },
  {
    postalCodes: [
      '50049',
      '50100',
      '50101',
      '50102',
      '50104',
      '50105',
      '50120',
      '50130',
      '50134',
      '50150',
      '50160',
      '50170',
      '50180',
      '50190',
      '50195',
      '50200',
      '50300',
      '50350',
      '50500',
      '50520',
      '50600',
      '50604',
      '50670',
      '50700',
      '50770',
      '50800',
      '50970',
      '51130',
      '51420',
      '51450',
      '51520',
      '51540',
      '51600',
      '51601',
      '51620',
      '51670',
      '51720',
      '51740',
      '52020',
      '52100',
      '52110',
      '52150',
      '52300',
      '52301',
      '52320',
      '52330',
      '52340',
      '52360',
      '52420',
      '52510',
      '52780',
      '52830',
      '77380'
    ],
    code: '491'
  },
  {
    postalCodes: [
      '91410',
      '91430',
      '91500',
      '91501',
      '91505',
      '91510',
      '91560'
    ],
    code: '494'
  },
  {
    postalCodes: ['42600', '42601', '42660', '43430'],
    code: '495'
  },
  {
    postalCodes: [
      '95940',
      '95950',
      '99300',
      '99301',
      '99310',
      '99320',
      '99330',
      '99360'
    ],
    code: '498'
  },
  {
    postalCodes: [
      '65450',
      '65460',
      '65470',
      '65480',
      '65520',
      '65610',
      '65611',
      '65614',
      '65630',
      '65650',
      '65710',
      '65730',
      '65760',
      '65800',
      '65870',
      '65920',
      '65930',
      '65970',
      '66520',
      '66530',
      '66531',
      '66534',
      '66540',
      '66550',
      '66560',
      '66580',
      '66590'
    ],
    code: '499'
  },
  {
    postalCodes: ['40930', '40934', '40950', '40951', '40954', '40955'],
    code: '500'
  },
  {
    postalCodes: ['23100', '23101', '23104', '23120', '23140'],
    code: '503'
  },
  {
    postalCodes: ['07600', '07601', '07690'],
    code: '504'
  },
  {
    postalCodes: [
      '04530',
      '04600',
      '04601',
      '04604',
      '04605',
      '04620',
      '04630',
      '04660',
      '04680',
      '04740',
      '04770',
      '04820',
      '04840',
      '04920',
      '04940'
    ],
    code: '505'
  },
  {
    postalCodes: [
      '52700',
      '52701',
      '52705',
      '52720',
      '52730',
      '52740',
      '52760',
      '52850',
      '52890',
      '52920',
      '52960',
      '52970',
      '52980'
    ],
    code: '507'
  },
  {
    postalCodes: [
      '35700',
      '35701',
      '35704',
      '35800',
      '35801',
      '35804',
      '35805',
      '35820',
      '35990',
      '42830',
      '42840',
      '42850'
    ],
    code: '508'
  },
  {
    postalCodes: [
      '21006',
      '21100',
      '21101',
      '21105',
      '21110',
      '21130',
      '21140',
      '21150',
      '21160',
      '21180',
      '21195'
    ],
    code: '529'
  },
  {
    postalCodes: ['29250', '29251', '29270', '29280'],
    code: '531'
  },
  {
    postalCodes: [
      '85500',
      '85501',
      '85504',
      '85505',
      '85540',
      '85560',
      '85580',
      '85620',
      '85630',
      '85640',
      '85660'
    ],
    code: '535'
  },
  {
    postalCodes: [
      '33380',
      '37100',
      '37101',
      '37105',
      '37120',
      '37121',
      '37124',
      '37125',
      '37130',
      '37135',
      '37140',
      '37144',
      '37150',
      '37155',
      '37180',
      '37200',
      '37210',
      '37240',
      '37241',
      '37310'
    ],
    code: '536'
  },
  {
    postalCodes: ['21270'],
    code: '538'
  },
  {
    postalCodes: [
      '75500',
      '75501',
      '75505',
      '75530',
      '75531',
      '75535',
      '75650',
      '75890',
      '75930',
      '75940',
      '75970',
      '75990'
    ],
    code: '541'
  },
  {
    postalCodes: [
      '01800',
      '01801',
      '01804',
      '01805',
      '01810',
      '01820',
      '01830',
      '01840',
      '01860',
      '01900',
      '01901',
      '01904',
      '01905',
      '01940',
      '05100',
      '05200',
      '05201',
      '05204',
      '05205',
      '05250',
      '05450'
    ],
    code: '543'
  },
  {
    postalCodes: [
      '64200',
      '64201',
      '64204',
      '64210',
      '64220',
      '64230',
      '64240',
      '64250',
      '64510',
      '64530',
      '64550',
      '64610',
      '66270',
      '66274',
      '66295'
    ],
    code: '545'
  },
  {
    postalCodes: [
      '16200',
      '16201',
      '16204',
      '16230',
      '16260',
      '16270',
      '16280',
      '16300',
      '16301',
      '16304',
      '16305',
      '16310',
      '16320',
      '16330',
      '16350',
      '16390',
      '16450',
      '16510'
    ],
    code: '560'
  },
  {
    postalCodes: ['32500', '32520'],
    code: '561'
  },
  {
    postalCodes: [
      '35100',
      '35220',
      '35240',
      '35260',
      '35270',
      '35300',
      '35301',
      '35305',
      '35320',
      '35420',
      '35480'
    ],
    code: '562'
  },
  {
    postalCodes: [
      '86210',
      '86300',
      '86301',
      '86304',
      '86350',
      '86510',
      '86530'
    ],
    code: '563'
  },
  {
    postalCodes: [
      '90014',
      '90015',
      '90020',
      '90029',
      '90049',
      '90050',
      '90056',
      '90100',
      '90101',
      '90104',
      '90105',
      '90120',
      '90130',
      '90134',
      '90135',
      '90140',
      '90144',
      '90150',
      '90154',
      '90155',
      '90220',
      '90230',
      '90234',
      '90235',
      '90240',
      '90245',
      '90250',
      '90310',
      '90400',
      '90401',
      '90405',
      '90410',
      '90414',
      '90415',
      '90420',
      '90425',
      '90455',
      '90460',
      '90461',
      '90470',
      '90500',
      '90501',
      '90505',
      '90510',
      '90520',
      '90524',
      '90525',
      '90530',
      '90540',
      '90545',
      '90550',
      '90555',
      '90560',
      '90570',
      '90571',
      '90574',
      '90575',
      '90580',
      '90590',
      '90600',
      '90601',
      '90620',
      '90625',
      '90630',
      '90634',
      '90635',
      '90650',
      '90651',
      '90660',
      '90670',
      '90800',
      '90804',
      '90805',
      '90810',
      '90820',
      '90824',
      '90830',
      '90831',
      '90834',
      '90835',
      '90840',
      '90844',
      '90850',
      '90860',
      '90900',
      '90901',
      '90904',
      '90910',
      '90940',
      '91006',
      '91200',
      '91210',
      '91240',
      '91260',
      '91300',
      '91310'
    ],
    code: '564'
  },
  {
    postalCodes: [
      '17470',
      '17500',
      '17501',
      '17510',
      '17530',
      '17610',
      '17630',
      '17710',
      '17740'
    ],
    code: '576'
  },
  {
    postalCodes: [
      '21510',
      '21520',
      '21530',
      '21531',
      '21534',
      '21535',
      '21540',
      '21555'
    ],
    code: '577'
  },
  {
    postalCodes: ['88300', '88310', '88350', '88380', '88470'],
    code: '578'
  },
  {
    postalCodes: [
      '59100',
      '59101',
      '59130',
      '59210',
      '59310',
      '59320',
      '59410',
      '59510',
      '59511',
      '59520',
      '59530',
      '59610',
      '59710',
      '59720',
      '59730'
    ],
    code: '580'
  },
  {
    postalCodes: [
      '39620',
      '39660',
      '39700',
      '39701',
      '39704',
      '39705',
      '39750',
      '39770',
      '39780'
    ],
    code: '581'
  },
  {
    postalCodes: [
      '98500',
      '98510',
      '98530',
      '98550',
      '98560',
      '98570',
      '98580'
    ],
    code: '583'
  },
  {
    postalCodes: ['69910', '69920', '69950', '69951', '69970', '69980'],
    code: '584'
  },
  {
    postalCodes: ['19410', '19420', '19430', '19460', '19470', '19480'],
    code: '588'
  },
  {
    postalCodes: ['41900', '41901', '41904', '41920', '41925', '41980'],
    code: '592'
  },
  {
    postalCodes: [
      '76100',
      '76101',
      '76102',
      '76104',
      '76105',
      '76120',
      '76130',
      '76150',
      '76280',
      '76620',
      '76780',
      '76850',
      '76940',
      '77110',
      '77120',
      '77140',
      '77220',
      '77240',
      '77320',
      '77330',
      '77350',
      '77430',
      '77434',
      '77520',
      '77570',
      '77580'
    ],
    code: '593'
  },
  {
    postalCodes: [
      '72400',
      '72401',
      '72430',
      '72510',
      '72530',
      '72550',
      '72570',
      '72710',
      '72740',
      '72810',
      '72840'
    ],
    code: '595'
  },
  {
    postalCodes: ['68600', '68601', '68605', '68620', '68630', '68660'],
    code: '598'
  },
  {
    postalCodes: [
      '68530',
      '68690',
      '68800',
      '68810',
      '68820',
      '68830',
      '68840',
      '68870',
      '68910',
      '68920',
      '68930',
      '68940',
      '68970'
    ],
    code: '599'
  },
  {
    postalCodes: [
      '44800',
      '44801',
      '44860',
      '44880',
      '44884',
      '44910',
      '44920',
      '44950',
      '44970'
    ],
    code: '601'
  },
  {
    postalCodes: [
      '33920',
      '33950',
      '33954',
      '33955',
      '33960',
      '33961',
      '33964',
      '33965',
      '33980'
    ],
    code: '604'
  },
  {
    postalCodes: [
      '83700',
      '83701',
      '83720',
      '83750',
      '83760',
      '83780',
      '83825',
      '83830',
      '83835',
      '83840',
      '83855'
    ],
    code: '607'
  },
  {
    postalCodes: ['29630', '29631', '29640', '38680'],
    code: '608'
  },
  {
    postalCodes: [
      '28100',
      '28101',
      '28102',
      '28104',
      '28105',
      '28120',
      '28124',
      '28130',
      '28131',
      '28134',
      '28135',
      '28190',
      '28200',
      '28220',
      '28240',
      '28300',
      '28330',
      '28360',
      '28361',
      '28364',
      '28365',
      '28370',
      '28430',
      '28434',
      '28500',
      '28540',
      '28560',
      '28580',
      '28600',
      '28601',
      '28604',
      '28610',
      '28614',
      '28615',
      '28660',
      '28760',
      '28800',
      '28840',
      '28844',
      '28880',
      '28900',
      '29570',
      '29600',
      '29601',
      '29680',
      '29700',
      '29720',
      '38600',
      '38601',
      '38650',
      '38670'
    ],
    code: '609'
  },
  {
    postalCodes: ['07150', '07170', '07171', '07190'],
    code: '611'
  },
  {
    postalCodes: [
      '97810',
      '97815',
      '97820',
      '97840',
      '97850',
      '97870',
      '97880',
      '97890',
      '97895',
      '97900',
      '97901',
      '97920',
      '97925',
      '97940',
      '97950',
      '97960',
      '97965',
      '97967',
      '97970',
      '97980',
      '98780'
    ],
    code: '614'
  },
  {
    postalCodes: [
      '93100',
      '93101',
      '93140',
      '93160',
      '93170',
      '93180',
      '93187',
      '93190',
      '93195',
      '93220',
      '93225',
      '93240',
      '93250',
      '93270',
      '93277',
      '93280',
      '93290',
      '93350',
      '93390'
    ],
    code: '615'
  },
  {
    postalCodes: ['07550', '07560', '07564', '07565'],
    code: '616'
  },
  {
    postalCodes: [
      '31810',
      '31850',
      '31900',
      '31901',
      '31930',
      '31950',
      '31970'
    ],
    code: '619'
  },
  {
    postalCodes: [
      '89110',
      '89140',
      '89200',
      '89201',
      '89210',
      '89320',
      '89330'
    ],
    code: '620'
  },
  {
    postalCodes: ['52200', '52201', '52230', '52270'],
    code: '623'
  },
  {
    postalCodes: [
      '49200',
      '49210',
      '49220',
      '49240',
      '49270',
      '49275',
      '49290'
    ],
    code: '624'
  },
  {
    postalCodes: ['86100', '86101', '86110', '86160', '86170'],
    code: '625'
  },
  {
    postalCodes: [
      '86800',
      '86801',
      '86804',
      '86810',
      '86850',
      '86870',
      '86900',
      '86980'
    ],
    code: '626'
  },
  {
    postalCodes: ['92910', '92920', '92930'],
    code: '630'
  },
  {
    postalCodes: ['23950', '23960', '27320', '27340'],
    code: '631'
  },
  {
    postalCodes: [
      '14960',
      '14980',
      '36450',
      '36600',
      '36601',
      '36640',
      '36660',
      '36720',
      '36760',
      '36910',
      '36914',
      '36930'
    ],
    code: '635'
  },
  {
    postalCodes: [
      '21800',
      '21801',
      '21820',
      '21840',
      '21860',
      '21870',
      '21871',
      '21880',
      '21890',
      '21900',
      '21901',
      '21930'
    ],
    code: '636'
  },
  {
    postalCodes: [
      '06100',
      '06101',
      '06104',
      '06105',
      '06108',
      '06150',
      '06151',
      '06154',
      '06200',
      '06400',
      '06401',
      '06405',
      '06450',
      '06500',
      '06530',
      '06650',
      '06750',
      '06830',
      '06850',
      '06880',
      '06950',
      '07110',
      '07114',
      '07130',
      '07220',
      '07280',
      '07310',
      '07320',
      '07350',
      '07360',
      '07370',
      '07390',
      '07410',
      '07450'
    ],
    code: '638'
  },
  {
    postalCodes: [
      '86360',
      '86400',
      '86401',
      '86440',
      '86460',
      '86470',
      '92100',
      '92101',
      '92104',
      '92105',
      '92120',
      '92130',
      '92134',
      '92140',
      '92144',
      '92150',
      '92160',
      '92180',
      '92210',
      '92220',
      '92230',
      '92240',
      '92260'
    ],
    code: '678'
  },
  {
    postalCodes: [
      '21120',
      '21200',
      '21201',
      '21204',
      '21205',
      '21210',
      '21220',
      '21260',
      '21280',
      '21284',
      '21285'
    ],
    code: '680'
  },
  {
    postalCodes: [
      '58830',
      '58850',
      '58900',
      '58901',
      '58910',
      '58920',
      '58940'
    ],
    code: '681'
  },
  {
    postalCodes: [
      '97665',
      '97700',
      '97701',
      '97715',
      '97720',
      '97760',
      '97765',
      '97770',
      '97780',
      '97785'
    ],
    code: '683'
  },
  {
    postalCodes: [
      '26100',
      '26101',
      '26102',
      '26104',
      '26105',
      '26200',
      '26410',
      '26510',
      '26560',
      '26660',
      '26661',
      '26664',
      '26720',
      '26740',
      '26820',
      '26840',
      '26845',
      '26910',
      '26930',
      '26950',
      '27220',
      '27230',
      '27231',
      '27250',
      '27260',
      '27310'
    ],
    code: '684'
  },
  {
    postalCodes: ['77700', '77770', '77910', '77930', '77960'],
    code: '686'
  },
  {
    postalCodes: [
      '73850',
      '73860',
      '73900',
      '73901',
      '73904',
      '73970',
      '73990',
      '75680'
    ],
    code: '687'
  },
  {
    postalCodes: [
      '56550',
      '56610',
      '56640',
      '56710',
      '56730',
      '56800',
      '56801'
    ],
    code: '689'
  },
  {
    postalCodes: ['85900', '85930', '85940', '85980'],
    code: '691'
  },
  {
    postalCodes: [
      '11100',
      '11101',
      '11105',
      '11111',
      '11120',
      '11125',
      '11130',
      '11134',
      '11135',
      '11310',
      '11311',
      '11710',
      '11714',
      '11715',
      '11910'
    ],
    code: '694'
  },
  {
    postalCodes: ['88400', '88401', '88440', '88460'],
    code: '697'
  },
  {
    postalCodes: [
      '96100',
      '96101',
      '96102',
      '96104',
      '96105',
      '96190',
      '96200',
      '96201',
      '96205',
      '96300',
      '96301',
      '96304',
      '96320',
      '96324',
      '96325',
      '96400',
      '96440',
      '96460',
      '96500',
      '96600',
      '96700',
      '96800',
      '96900',
      '96905',
      '96910',
      '96930',
      '96960',
      '97110',
      '97130',
      '97140',
      '97145',
      '97160',
      '97170',
      '97220',
      '97240',
      '97280',
      '97290',
      '97310',
      '97320',
      '97340',
      '97370',
      '97420',
      '97430',
      '97510',
      '97520',
      '97530',
      '97540',
      '97580',
      '97610',
      '97615',
      '97620',
      '97625',
      '97630',
      '97635',
      '97650',
      '97655',
      '97670',
      '97675',
      '97680',
      '97685',
      '97690',
      '97999',
      '99999'
    ],
    code: '698'
  },
  {
    postalCodes: [
      '56100',
      '56101',
      '56120',
      '56140',
      '56210',
      '56310',
      '56330',
      '56410',
      '56440',
      '56510'
    ],
    code: '700'
  },
  {
    postalCodes: [
      '34410',
      '34420',
      '34430',
      '34450',
      '34600',
      '34601',
      '34640',
      '34870',
      '35740',
      '35750'
    ],
    code: '702'
  },
  {
    postalCodes: ['21290', '21291', '21310'],
    code: '704'
  },
  {
    postalCodes: ['82290', '82300', '82301', '82310', '82335'],
    code: '707'
  },
  {
    postalCodes: [
      '10300',
      '10301',
      '10304',
      '10305',
      '10320',
      '10330',
      '10350',
      '10360',
      '10380',
      '10410',
      '10420',
      '10421',
      '10424',
      '10440',
      '10470',
      '10480',
      '10520',
      '10570',
      '10600',
      '10601',
      '10604',
      '10605',
      '10611',
      '10620',
      '10640',
      '10641',
      '10650',
      '10660',
      '10680',
      '10710'
    ],
    code: '710'
  },
  {
    postalCodes: [
      '41260',
      '41270',
      '43100',
      '43101',
      '43104',
      '43105',
      '43130',
      '43170',
      '43220',
      '43240',
      '43250',
      '43270',
      '43420',
      '43440',
      '43480'
    ],
    code: '729'
  },
  {
    postalCodes: [
      '98600',
      '98620',
      '98630',
      '98660',
      '98760',
      '98770',
      '98790',
      '98900',
      '98901',
      '98905',
      '98920',
      '98930',
      '98950',
      '98960',
      '98970',
      '98980',
      '98995'
    ],
    code: '732'
  },
  {
    postalCodes: [
      '24100',
      '24101',
      '24102',
      '24104',
      '24105',
      '24130',
      '24240',
      '24245',
      '24260',
      '24280',
      '24285',
      '24800',
      '24801',
      '24804',
      '24805',
      '24910',
      '25110',
      '25130',
      '25160',
      '25170',
      '25180',
      '25190',
      '25210',
      '25230',
      '25240',
      '25250',
      '25260',
      '25320',
      '25330',
      '25340',
      '25360',
      '25370',
      '25380',
      '25390',
      '25410',
      '25420',
      '25430',
      '25460',
      '25470',
      '25500',
      '25501',
      '25504',
      '25520',
      '25540',
      '25550',
      '25560',
      '25570',
      '25610',
      '25630',
      '25640',
      '25660'
    ],
    code: '734'
  },
  {
    postalCodes: ['22320', '22430'],
    code: '736'
  },
  {
    postalCodes: ['21570', '21571', '21590'],
    code: '738'
  },
  {
    postalCodes: ['54720', '54750', '54770', '54800', '54801', '54950'],
    code: '739'
  },
  {
    postalCodes: [
      '57090',
      '57100',
      '57101',
      '57102',
      '57105',
      '57120',
      '57130',
      '57170',
      '57200',
      '57201',
      '57210',
      '57214',
      '57215',
      '57220',
      '57230',
      '57310',
      '57510',
      '57514',
      '57600',
      '57710',
      '57810',
      '58130',
      '58140',
      '58150',
      '58200',
      '58201',
      '58220',
      '58260',
      '58300',
      '58320',
      '58360',
      '58390',
      '58410',
      '58420',
      '58430',
      '58450',
      '58500',
      '58501',
      '58520',
      '58550',
      '58570',
      '58580',
      '58650',
      '58680',
      '58690',
      '58770',
      '58810'
    ],
    code: '740'
  },
  {
    postalCodes: [
      '98800',
      '98810',
      '98820',
      '98830',
      '98840',
      '98850',
      '99640'
    ],
    code: '742'
  },
  {
    postalCodes: [
      '60060',
      '60061',
      '60100',
      '60101',
      '60104',
      '60105',
      '60110',
      '60111',
      '60120',
      '60124',
      '60125',
      '60150',
      '60154',
      '60157',
      '60200',
      '60204',
      '60220',
      '60280',
      '60320',
      '60324',
      '60325',
      '60420',
      '60510',
      '60511',
      '60514',
      '60515',
      '60550',
      '60560',
      '60640',
      '61100',
      '61110',
      '61120',
      '61140',
      '61160',
      '61180',
      '61400',
      '61401',
      '61410',
      '61430',
      '61440',
      '61450',
      '61460',
      '61470',
      '61650'
    ],
    code: '743'
  },
  {
    postalCodes: [
      '85310',
      '85320',
      '85340',
      '85410',
      '85430',
      '85450',
      '85470'
    ],
    code: '746'
  },
  {
    postalCodes: ['29810', '29811', '29830', '29860', '29880'],
    code: '747'
  },
  {
    postalCodes: [
      '92320',
      '92330',
      '92350',
      '92400',
      '92401',
      '92430',
      '92440',
      '92450',
      '92470'
    ],
    code: '748'
  },
  {
    postalCodes: [
      '70900',
      '70901',
      '70910',
      '70911',
      '70940',
      '71800',
      '71801',
      '71804',
      '71805',
      '71820',
      '71840',
      '71850',
      '71870',
      '71890'
    ],
    code: '749'
  },
  {
    postalCodes: [
      '95200',
      '95201',
      '95204',
      '95210',
      '95220',
      '95225',
      '95230',
      '95255',
      '95260',
      '95270'
    ],
    code: '751'
  },
  {
    postalCodes: [
      '01120',
      '01150',
      '01151',
      '01155',
      '01180',
      '01190',
      '04130',
      '04131',
      '04134',
      '04150',
      '04170',
      '04240'
    ],
    code: '753'
  },
  {
    postalCodes: ['02570', '02580', '02584', '02590'],
    code: '755'
  },
  {
    postalCodes: [
      '97380',
      '97390',
      '99510',
      '99520',
      '99530',
      '99540',
      '99550',
      '99555',
      '99560',
      '99600',
      '99601',
      '99604',
      '99610',
      '99620',
      '99645',
      '99650',
      '99655',
      '99660',
      '99665',
      '99670',
      '99690',
      '99695',
      '99710',
      '99720',
      '99740',
      '99750',
      '99760',
      '99770'
    ],
    code: '758'
  },
  {
    postalCodes: ['63800', '63830', '63880', '63890'],
    code: '759'
  },
  {
    postalCodes: [
      '31400',
      '31401',
      '31405',
      '31410',
      '31450',
      '31460',
      '31470',
      '31490',
      '31520',
      '31530'
    ],
    code: '761'
  },
  {
    postalCodes: [
      '74300',
      '74301',
      '74340',
      '74345',
      '74360',
      '74380',
      '74490'
    ],
    code: '762'
  },
  {
    postalCodes: [
      '74390',
      '88120',
      '88490',
      '88530',
      '88600',
      '88601',
      '88610',
      '88614',
      '88615',
      '88620',
      '88640',
      '88670',
      '88690'
    ],
    code: '765'
  },
  {
    postalCodes: ['22720'],
    code: '766'
  },
  {
    postalCodes: ['58610', '58620', '58700', '58701', '58720'],
    code: '768'
  },
  {
    postalCodes: ['22520', '22530'],
    code: '771'
  },
  {
    postalCodes: [
      '89440',
      '89600',
      '89601',
      '89604',
      '89605',
      '89610',
      '89640',
      '89660',
      '89670',
      '89680',
      '89730',
      '89740',
      '89760',
      '89770',
      '89780',
      '89800',
      '89830',
      '89840',
      '89920'
    ],
    code: '777'
  },
  {
    postalCodes: ['77600', '77601', '77604', '77630', '77690', '77800'],
    code: '778'
  },
  {
    postalCodes: [
      '19230',
      '19310',
      '19320',
      '19370',
      '19700',
      '19701',
      '19730',
      '19740',
      '19770'
    ],
    code: '781'
  },
  {
    postalCodes: [
      '27710',
      '27730',
      '27740',
      '27750',
      '27800',
      '27801',
      '27804',
      '27820',
      '27840',
      '27860',
      '27910',
      '27920'
    ],
    code: '783'
  },
  {
    postalCodes: [
      '88340',
      '91700',
      '91701',
      '91710',
      '91720',
      '91730',
      '91740',
      '91750',
      '91760',
      '91780',
      '91790',
      '92810',
      '92830'
    ],
    code: '785'
  },
  {
    postalCodes: [
      '32730',
      '32740',
      '32741',
      '38100',
      '38120',
      '38130',
      '38140',
      '38200',
      '38201',
      '38204',
      '38210',
      '38211',
      '38220',
      '38250',
      '38270',
      '38280',
      '38300',
      '38360',
      '38370',
      '38420',
      '38424',
      '38460',
      '38470',
      '38510'
    ],
    code: '790'
  },
  {
    postalCodes: [
      '92500',
      '92501',
      '92520',
      '92530',
      '92600',
      '92610',
      '92620',
      '92640',
      '92650',
      '92700'
    ],
    code: '791'
  },
  {
    postalCodes: ['54915', '54920', '54930', '54940', '54960', '56350'],
    code: '831'
  },
  {
    postalCodes: [
      '93400',
      '93401',
      '93420',
      '93440',
      '93470',
      '93540',
      '93590'
    ],
    code: '832'
  },
  {
    postalCodes: ['23310', '23311'],
    code: '833'
  },
  {
    postalCodes: [
      '31230',
      '31250',
      '31300',
      '31304',
      '31310',
      '31340',
      '31350',
      '31370',
      '31380',
      '31384'
    ],
    code: '834'
  },
  {
    postalCodes: [
      '33013',
      '33014',
      '33020',
      '33056',
      '33060',
      '33100',
      '33101',
      '33104',
      '33105',
      '33107',
      '33108',
      '33180',
      '33184',
      '33200',
      '33201',
      '33204',
      '33210',
      '33211',
      '33214',
      '33215',
      '33230',
      '33231',
      '33234',
      '33240',
      '33250',
      '33251',
      '33270',
      '33271',
      '33274',
      '33275',
      '33300',
      '33305',
      '33310',
      '33311',
      '33315',
      '33330',
      '33331',
      '33340',
      '33341',
      '33400',
      '33401',
      '33404',
      '33405',
      '33410',
      '33411',
      '33420',
      '33485',
      '33500',
      '33501',
      '33504',
      '33520',
      '33521',
      '33530',
      '33531',
      '33534',
      '33540',
      '33541',
      '33544',
      '33545',
      '33560',
      '33561',
      '33580',
      '33581',
      '33584',
      '33585',
      '33587',
      '33610',
      '33614',
      '33680',
      '33700',
      '33701',
      '33710',
      '33711',
      '33714',
      '33715',
      '33720',
      '33721',
      '33724',
      '33725',
      '33730',
      '33731',
      '33734',
      '33800',
      '33801',
      '33804',
      '33805',
      '33820',
      '33821',
      '33825',
      '33840',
      '33841',
      '33850',
      '33851',
      '33870',
      '33874',
      '33875',
      '33900',
      '33901',
      '33904',
      '33905',
      '34240',
      '34260',
      '34270',
      '38205'
    ],
    code: '837'
  },
  {
    postalCodes: ['71640', '72210', '72211', '72220'],
    code: '844'
  },
  {
    postalCodes: [
      '95300',
      '95325',
      '95330',
      '95340',
      '95350',
      '95355',
      '95375'
    ],
    code: '845'
  },
  {
    postalCodes: [
      '61840',
      '64700',
      '64701',
      '64705',
      '64720',
      '64740',
      '64760',
      '64770'
    ],
    code: '846'
  },
  {
    postalCodes: [
      '82350',
      '82360',
      '82600',
      '82601',
      '82655',
      '82660',
      '82670',
      '82675',
      '82685',
      '82770'
    ],
    code: '848'
  },
  {
    postalCodes: ['69300', '69301', '69310', '69330', '69340', '69410'],
    code: '849'
  },
  {
    postalCodes: ['41410', '41440', '41660', '41661', '41664'],
    code: '850'
  },
  {
    postalCodes: [
      '94430',
      '95400',
      '95401',
      '95404',
      '95405',
      '95407',
      '95410',
      '95420',
      '95421',
      '95425',
      '95430',
      '95440',
      '95450',
      '95460',
      '95470',
      '95490',
      '95500',
      '95520',
      '95530',
      '95540',
      '95560',
      '95590'
    ],
    code: '851'
  },
  {
    postalCodes: [
      '20014',
      '20025',
      '20100',
      '20101',
      '20104',
      '20105',
      '20200',
      '20201',
      '20204',
      '20210',
      '20214',
      '20215',
      '20240',
      '20241',
      '20250',
      '20251',
      '20255',
      '20300',
      '20304',
      '20305',
      '20320',
      '20321',
      '20324',
      '20325',
      '20360',
      '20361',
      '20364',
      '20365',
      '20380',
      '20381',
      '20400',
      '20404',
      '20460',
      '20464',
      '20500',
      '20501',
      '20505',
      '20520',
      '20521',
      '20525',
      '20540',
      '20541',
      '20544',
      '20545',
      '20610',
      '20611',
      '20615',
      '20700',
      '20701',
      '20705',
      '20720',
      '20730',
      '20734',
      '20735',
      '20740',
      '20741',
      '20744',
      '20745',
      '20750',
      '20751',
      '20754',
      '20765',
      '20800',
      '20801',
      '20810',
      '20811',
      '20814',
      '20815',
      '20880',
      '20900',
      '20905',
      '20960',
      '21330',
      '21335',
      '21340'
    ],
    code: '853'
  },
  {
    postalCodes: [
      '95640',
      '95645',
      '95700',
      '95701',
      '95710',
      '95720',
      '95750',
      '95760',
      '95770',
      '95780',
      '95790',
      '97330',
      '97335'
    ],
    code: '854'
  },
  {
    postalCodes: [
      '71170',
      '71200',
      '71201',
      '71210',
      '71240',
      '71260',
      '71280'
    ],
    code: '857'
  },
  {
    postalCodes: [
      '04300',
      '04301',
      '04304',
      '04305',
      '04310',
      '04320',
      '04321',
      '04325',
      '04330',
      '04340',
      '04350',
      '04360',
      '04370',
      '04380',
      '04390',
      '04500',
      '04501',
      '04504',
      '05400',
      '05401',
      '05405',
      '05430'
    ],
    code: '858'
  },
  {
    postalCodes: ['91800', '91950'],
    code: '859'
  },
  {
    postalCodes: [
      '28260',
      '28400',
      '28401',
      '28405',
      '28407',
      '28450',
      '29310',
      '29320',
      '29340',
      '29350'
    ],
    code: '886'
  },
  {
    postalCodes: [
      '31140',
      '31160',
      '31700',
      '31720',
      '31730',
      '31750',
      '31760',
      '31761',
      '31830',
      '31860'
    ],
    code: '887'
  },
  {
    postalCodes: [
      '91600',
      '91601',
      '91620',
      '91630',
      '91640',
      '91660',
      '91670'
    ],
    code: '889'
  },
  {
    postalCodes: ['99950', '99970', '99980', '99981', '99990'],
    code: '890'
  },
  {
    postalCodes: ['41210', '41220', '41230', '41231', '41240', '41290'],
    code: '892'
  },
  {
    postalCodes: [
      '66840',
      '66850',
      '66900',
      '66901',
      '66904',
      '66910',
      '66930',
      '66950',
      '66970',
      '66980'
    ],
    code: '893'
  },
  {
    postalCodes: [
      '23450',
      '23500',
      '23501',
      '23504',
      '23505',
      '23600',
      '23601',
      '23660',
      '23930'
    ],
    code: '895'
  },
  {
    postalCodes: [
      '65100',
      '65101',
      '65102',
      '65104',
      '65105',
      '65130',
      '65170',
      '65200',
      '65201',
      '65230',
      '65231',
      '65280',
      '65285',
      '65300',
      '65301',
      '65304',
      '65305',
      '65320',
      '65350',
      '65370',
      '65374',
      '65375',
      '65380',
      '65384',
      '65410',
      '66430',
      '66500',
      '66501',
      '66504',
      '66510',
      '66999'
    ],
    code: '905'
  },
  {
    postalCodes: [
      '37600',
      '37601',
      '37604',
      '37605',
      '37630',
      '37634',
      '37700',
      '37720',
      '37740',
      '37770',
      '37850',
      '37860'
    ],
    code: '908'
  },
  {
    postalCodes: [
      '75700',
      '75701',
      '75710',
      '75740',
      '75770',
      '75790',
      '75840'
    ],
    code: '911'
  },
  {
    postalCodes: [
      '78020',
      '78200',
      '78201',
      '78202',
      '78204',
      '78205',
      '78210',
      '78250',
      '78300',
      '78307',
      '78310',
      '78400',
      '78500',
      '78610',
      '78710',
      '78850',
      '78870',
      '78900',
      '79480',
      '79520'
    ],
    code: '915'
  },
  {
    postalCodes: ['23200', '23201', '23210', '23260'],
    code: '918'
  },
  {
    postalCodes: ['72300', '72310', '72330', '72350', '72360', '72380'],
    code: '921'
  },
  {
    postalCodes: ['37350', '37370', '37380', '37420', '37470'],
    code: '922'
  },
  {
    postalCodes: [
      '69700',
      '69701',
      '69730',
      '69750',
      '69820',
      '69830',
      '69850'
    ],
    code: '924'
  },
  {
    postalCodes: ['74200', '74230', '74240', '74250', '74270'],
    code: '925'
  },
  {
    postalCodes: [
      '03100',
      '03101',
      '03104',
      '03105',
      '03150',
      '03220',
      '03250',
      '03300',
      '03304',
      '03320',
      '03400',
      '03404',
      '03405',
      '03430',
      '03790'
    ],
    code: '927'
  },
  {
    postalCodes: [
      '44480',
      '44500',
      '44501',
      '44505',
      '44530',
      '44580',
      '44590',
      '44610',
      '44630',
      '44640',
      '44670',
      '44680',
      '44730',
      '44740',
      '44760',
      '44770',
      '44790'
    ],
    code: '931'
  },
  {
    postalCodes: [
      '62800',
      '62804',
      '62810',
      '62840',
      '62860',
      '62870',
      '62880'
    ],
    code: '934'
  },
  {
    postalCodes: ['49860', '49900', '49901', '49930', '49960', '49980'],
    code: '935'
  },
  {
    postalCodes: [
      '34710',
      '34730',
      '34740',
      '34770',
      '34800',
      '34801',
      '34805',
      '34910',
      '34930',
      '34950',
      '34970',
      '34980',
      '42870'
    ],
    code: '936'
  },
  {
    postalCodes: ['22550'],
    code: '941'
  },
  {
    postalCodes: [
      '62395',
      '66600',
      '66604',
      '66640',
      '66660',
      '66680',
      '66710',
      '66730',
      '66800',
      '66801',
      '66810',
      '66820',
      '66830'
    ],
    code: '946'
  },
  {
    postalCodes: [
      '95600',
      '95601',
      '95610',
      '95615',
      '95620',
      '95630',
      '95635',
      '95655',
      '95660',
      '95670',
      '95675',
      '95680',
      '95690',
      '97250'
    ],
    code: '976'
  },
  {
    postalCodes: [
      '84100',
      '84101',
      '84102',
      '84105',
      '84460',
      '84540',
      '84650',
      '84770',
      '84880'
    ],
    code: '977'
  },
  {
    postalCodes: [
      '33430',
      '33450',
      '33470',
      '33471',
      '33474',
      '33475',
      '33480',
      '33484',
      '34110',
      '34130',
      '34140',
      '34150',
      '34180',
      '34300',
      '34301',
      '34320',
      '34330',
      '34510',
      '34530',
      '34550',
      '39150',
      '39160',
      '39310',
      '39340'
    ],
    code: '980'
  },
  {
    postalCodes: ['32100', '32140'],
    code: '981'
  },
  {
    postalCodes: [
      '63660',
      '63700',
      '63701',
      '63704',
      '63705',
      '63770',
      '63780',
      '63900',
      '63910',
      '63920',
      '63950'
    ],
    code: '989'
  },
  {
    postalCodes: [
      '44100',
      '44101',
      '44104',
      '44105',
      '44120',
      '44150',
      '44160',
      '44170',
      '44190',
      '44200',
      '44201',
      '44220',
      '44250',
      '44260',
      '44280',
      '44400',
      '44401',
      '44420',
      '44440',
      '44460'
    ],
    code: '992'
  },
  {
    postalCodes: [
      '10820',
      '10900',
      '10901',
      '10904',
      '10905',
      '10940',
      '10960'
    ],
    code: '078'
  },
  {
    postalCodes: [
      '12100',
      '12101',
      '12130',
      '12210',
      '12240',
      '12310',
      '12350'
    ],
    code: '086'
  },
  {
    postalCodes: [
      '13700',
      '13701',
      '13720',
      '13721',
      '13800',
      '13880',
      '13900',
      '14430',
      '14610',
      '14620'
    ],
    code: '082'
  },
  {
    postalCodes: [
      '15086',
      '20006',
      '20032',
      '21086',
      '33006',
      '33032',
      '33086',
      '40006',
      '53006',
      '53086',
      '60006',
      '65086',
      '70006',
      '70032',
      '70086',
      '88086',
      '90006',
      '90032',
      '90086',
      '96086',
      '00002',
      '00003',
      '00006',
      '00011',
      '00012',
      '00013',
      '00014',
      '00015',
      '00016',
      '00017',
      '00018',
      '00019',
      '00020',
      '00021',
      '00022',
      '00023',
      '00024',
      '00025',
      '00026',
      '00027',
      '00028',
      '00029',
      '00030',
      '00031',
      '00032',
      '00033',
      '00034',
      '00036',
      '00037',
      '00038',
      '00039',
      '00040',
      '00041',
      '00042',
      '00043',
      '00044',
      '00045',
      '00046',
      '00047',
      '00048',
      '00049',
      '00050',
      '00051',
      '00052',
      '00053',
      '00054',
      '00055',
      '00056',
      '00057',
      '00058',
      '00059',
      '00060',
      '00061',
      '00062',
      '00063',
      '00064',
      '00065',
      '00066',
      '00068',
      '00070',
      '00071',
      '00074',
      '00075',
      '00076',
      '00077',
      '00079',
      '00080',
      '00081',
      '00084',
      '00086',
      '00087',
      '00088',
      '00089',
      '00090',
      '00091',
      '00093',
      '00097',
      '00098',
      '00099',
      '00100',
      '00101',
      '00102',
      '00104',
      '00105',
      '00107',
      '00120',
      '00121',
      '00125',
      '00130',
      '00131',
      '00135',
      '00140',
      '00141',
      '00150',
      '00151',
      '00154',
      '00155',
      '00160',
      '00161',
      '00164',
      '00170',
      '00171',
      '00180',
      '00181',
      '00184',
      '00185',
      '00187',
      '00190',
      '00191',
      '00200',
      '00201',
      '00204',
      '00210',
      '00211',
      '00215',
      '00220',
      '00221',
      '00224',
      '00225',
      '00230',
      '00231',
      '00232',
      '00234',
      '00235',
      '00240',
      '00241',
      '00244',
      '00245',
      '00250',
      '00251',
      '00254',
      '00255',
      '00260',
      '00264',
      '00270',
      '00271',
      '00274',
      '00280',
      '00281',
      '00284',
      '00290',
      '00300',
      '00301',
      '00304',
      '00305',
      '00310',
      '00320',
      '00321',
      '00324',
      '00330',
      '00331',
      '00334',
      '00340',
      '00341',
      '00350',
      '00351',
      '00354',
      '00355',
      '00360',
      '00370',
      '00371',
      '00374',
      '00380',
      '00381',
      '00384',
      '00385',
      '00390',
      '00391',
      '00394',
      '00395',
      '00400',
      '00401',
      '00404',
      '00405',
      '00410',
      '00411',
      '00414',
      '00420',
      '00421',
      '00424',
      '00425',
      '00430',
      '00431',
      '00434',
      '00440',
      '00441',
      '00444',
      '00500',
      '00501',
      '00504',
      '00510',
      '00511',
      '00514',
      '00515',
      '00520',
      '00521',
      '00524',
      '00525',
      '00530',
      '00531',
      '00534',
      '00535',
      '00540',
      '00544',
      '00550',
      '00551',
      '00554',
      '00560',
      '00561',
      '00564',
      '00565',
      '00570',
      '00574',
      '00580',
      '00581',
      '00584',
      '00590',
      '00594',
      '00600',
      '00601',
      '00605',
      '00610',
      '00620',
      '00621',
      '00625',
      '00630',
      '00634',
      '00635',
      '00640',
      '00641',
      '00644',
      '00645',
      '00650',
      '00654',
      '00660',
      '00661',
      '00664',
      '00665',
      '00670',
      '00671',
      '00675',
      '00680',
      '00684',
      '00690',
      '00694',
      '00700',
      '00701',
      '00704',
      '00705',
      '00710',
      '00711',
      '00714',
      '00715',
      '00720',
      '00721',
      '00724',
      '00725',
      '00730',
      '00740',
      '00741',
      '00744',
      '00750',
      '00751',
      '00754',
      '00760',
      '00761',
      '00764',
      '00765',
      '00770',
      '00771',
      '00774',
      '00775',
      '00780',
      '00781',
      '00785',
      '00790',
      '00791',
      '00794',
      '00795',
      '00800',
      '00804',
      '00810',
      '00811',
      '00814',
      '00815',
      '00820',
      '00825',
      '00830',
      '00840',
      '00844',
      '00845',
      '00850',
      '00860',
      '00861',
      '00870',
      '00874',
      '00880',
      '00884',
      '00890',
      '00900',
      '00905',
      '00910',
      '00915',
      '00920',
      '00921',
      '00925',
      '00930',
      '00931',
      '00934',
      '00935',
      '00940',
      '00941',
      '00944',
      '00945',
      '00950',
      '00955',
      '00960',
      '00965',
      '00970',
      '00971',
      '00975',
      '00980',
      '00981',
      '00984',
      '00985',
      '00990',
      '00994',
      '00995',
      '01006',
      '01044',
      '01050',
      '01051',
      '01055'
    ],
    code: '091'
  },
  {
    postalCodes: [
      '15270',
      '15860',
      '15864',
      '15870',
      '15871',
      '15875',
      '15880',
      '15884',
      '15980',
      '16500',
      '16630',
      '16710',
      '16730',
      '16790',
      '16800',
      '16801',
      '17110',
      '17120'
    ],
    code: '098'
  },
  {
    postalCodes: [
      '17130',
      '17150',
      '17200',
      '17201',
      '17205',
      '17220',
      '17240',
      '17320',
      '17410',
      '17430',
      '17440',
      '17450'
    ],
    code: '016'
  },
  {
    postalCodes: [
      '19510',
      '19540',
      '19600',
      '19601',
      '19610',
      '19620',
      '19630',
      '19850'
    ],
    code: '081'
  },
  {
    postalCodes: ['21370', '21380', '21381'],
    code: '019'
  },
  {
    postalCodes: ['22220', '22310', '22330', '22410', '22411', '22415'],
    code: '060'
  },
  {
    postalCodes: ['22240'],
    code: '076'
  },
  {
    postalCodes: ['22270'],
    code: '043'
  },
  {
    postalCodes: ['22340'],
    code: '065'
  },
  {
    postalCodes: ['22710'],
    code: '062'
  },
  {
    postalCodes: ['22840', '22910', '22920', '22930', '22940', '22950'],
    code: '035'
  },
  {
    postalCodes: [
      '27100',
      '27101',
      '27104',
      '27110',
      '27130',
      '27150',
      '27160',
      '27170',
      '29100',
      '29101',
      '29105',
      '29180'
    ],
    code: '051'
  },
  {
    postalCodes: [
      '27400',
      '27401',
      '27404',
      '27420',
      '27430',
      '27450',
      '27500',
      '27501',
      '27504',
      '27510',
      '27511',
      '27514',
      '27515',
      '27600',
      '27640',
      '27650',
      '27670'
    ],
    code: '050'
  },
  {
    postalCodes: ['29200', '29201', '29204', '29205'],
    code: '079'
  },
  {
    postalCodes: [
      '30100',
      '30101',
      '30102',
      '30104',
      '30105',
      '30107',
      '30108',
      '30300',
      '30304',
      '30420',
      '30421',
      '31006',
      '31110',
      '31130',
      '31170'
    ],
    code: '061'
  },
  {
    postalCodes: [
      '37800',
      '37801',
      '37804',
      '37805',
      '37830',
      '37835',
      '37900',
      '37910',
      '37911',
      '37915',
      '37960'
    ],
    code: '020'
  },
  {
    postalCodes: ['38920', '38950', '38951', '38970'],
    code: '099'
  },
  {
    postalCodes: [
      '41490',
      '41500',
      '41520',
      '41521',
      '41530',
      '41540',
      '41550',
      '41560',
      '41580'
    ],
    code: '077'
  },
  {
    postalCodes: [
      '49400',
      '49401',
      '49404',
      '49405',
      '49410',
      '49411',
      '49420',
      '49460',
      '49461',
      '49480',
      '49490',
      '49510',
      '49520',
      '49540',
      '49570',
      '49610',
      '49630',
      '49640',
      '49660',
      '49780',
      '49840'
    ],
    code: '075'
  },
  {
    postalCodes: ['52520', '52550', '52551', '52610', '52620'],
    code: '097'
  },
  {
    postalCodes: ['58160', '58170', '58175', '58180'],
    code: '046'
  },
  {
    postalCodes: [
      '61150',
      '61170',
      '63210',
      '63300',
      '63301',
      '63305',
      '63320',
      '63330',
      '63340',
      '63350',
      '63355',
      '63360',
      '63370',
      '63400',
      '63410',
      '63430',
      '63600',
      '63610',
      '63640',
      '63680'
    ],
    code: '010'
  },
  {
    postalCodes: ['62500', '62501', '62510', '62520', '62540', '62570'],
    code: '052'
  },
  {
    postalCodes: [
      '62710',
      '62720',
      '62730',
      '62740',
      '62750',
      '62760',
      '62830',
      '62900',
      '62901',
      '62904',
      '62920',
      '62940',
      '62950',
      '62990',
      '63500',
      '63501',
      '63510',
      '63530',
      '63540'
    ],
    code: '005'
  },
  {
    postalCodes: ['69510', '69550', '69570'],
    code: '074'
  },
  {
    postalCodes: [
      '79700',
      '79701',
      '79810',
      '79820',
      '79830',
      '79850',
      '79860',
      '79885',
      '79895',
      '79910',
      '79940'
    ],
    code: '090'
  },
  {
    postalCodes: ['85200', '85201', '85210', '85230'],
    code: '009'
  },
  {
    postalCodes: [
      '85710',
      '85730',
      '85800',
      '85801',
      '85804',
      '85820',
      '85840'
    ],
    code: '069'
  },
  {
    postalCodes: [
      '86480',
      '86550',
      '86600',
      '86601',
      '86604',
      '86650',
      '86680',
      '86690'
    ],
    code: '071'
  },
  {
    postalCodes: ['90480'],
    code: '072'
  },
  {
    postalCodes: [
      '99400',
      '99401',
      '99410',
      '99420',
      '99430',
      '99440',
      '99450',
      '99460',
      '99470',
      '99490'
    ],
    code: '047'
  },
  {
    postalCodes: [
      '00095',
      '02008',
      '02010',
      '02014',
      '02020',
      '02022',
      '02044',
      '02066',
      '02070',
      '02100',
      '02101',
      '02104',
      '02105',
      '02110',
      '02120',
      '02130',
      '02131',
      '02134',
      '02135',
      '02140',
      '02150',
      '02151',
      '02154',
      '02155',
      '02160',
      '02170',
      '02171',
      '02175',
      '02180',
      '02184',
      '02200',
      '02201',
      '02204',
      '02207',
      '02210',
      '02211',
      '02214',
      '02215',
      '02230',
      '02231',
      '02234',
      '02235',
      '02240',
      '02241',
      '02244',
      '02250',
      '02254',
      '02260',
      '02264',
      '02270',
      '02271',
      '02275',
      '02280',
      '02284',
      '02285',
      '02290',
      '02300',
      '02304',
      '02320',
      '02321',
      '02324',
      '02325',
      '02330',
      '02335',
      '02340',
      '02344',
      '02360',
      '02361',
      '02365',
      '02380',
      '02600',
      '02601',
      '02604',
      '02605',
      '02610',
      '02611',
      '02614',
      '02615',
      '02620',
      '02621',
      '02624',
      '02630',
      '02631',
      '02634',
      '02635',
      '02650',
      '02654',
      '02660',
      '02661',
      '02664',
      '02665',
      '02677',
      '02680',
      '02684',
      '02710',
      '02711',
      '02715',
      '02720',
      '02725',
      '02730',
      '02740',
      '02750',
      '02755',
      '02760',
      '02761',
      '02764',
      '02765',
      '02770',
      '02771',
      '02774',
      '02775',
      '02780',
      '02781',
      '02784',
      '02810',
      '02820',
      '02860',
      '02885',
      '02920',
      '02921',
      '02924',
      '02925',
      '02940',
      '02941',
      '02945',
      '02970',
      '02980'
    ],
    code: '049'
  },
  {
    postalCodes: [
      '01009',
      '01030',
      '01053',
      '01200',
      '01201',
      '01204',
      '01205',
      '01230',
      '01260',
      '01261',
      '01280',
      '01300',
      '01301',
      '01304',
      '01305',
      '01340',
      '01344',
      '01350',
      '01351',
      '01360',
      '01361',
      '01364',
      '01365',
      '01370',
      '01374',
      '01380',
      '01390',
      '01391',
      '01400',
      '01404',
      '01405',
      '01420',
      '01425',
      '01450',
      '01451',
      '01454',
      '01455',
      '01480',
      '01490',
      '01495',
      '01510',
      '01511',
      '01514',
      '01515',
      '01520',
      '01524',
      '01525',
      '01530',
      '01531',
      '01532',
      '01534',
      '01600',
      '01601',
      '01604',
      '01605',
      '01610',
      '01611',
      '01615',
      '01620',
      '01621',
      '01625',
      '01630',
      '01635',
      '01640',
      '01641',
      '01644',
      '01650',
      '01651',
      '01654',
      '01660',
      '01661',
      '01665',
      '01670',
      '01671',
      '01680',
      '01690',
      '01694',
      '01700',
      '01704',
      '01705',
      '01710',
      '01711',
      '01714',
      '01720',
      '01721',
      '01724',
      '01730',
      '01740',
      '01741',
      '01750',
      '01760',
      '01770'
    ],
    code: '092'
  },
  {
    postalCodes: [
      '07230',
      '07231',
      '07500',
      '07504',
      '07510',
      '07530',
      '07580',
      '07590',
      '07680'
    ],
    code: '018'
  }
]
