import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { getConfigFromEnv } from 'src/config'
import { number } from 'server/src/utils/lib/study-mappers'
const {
  app: { API_URL }
} = getConfigFromEnv()

export function createApolloClient() {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [
          {
            kind: 'INTERFACE',
            name: 'GeoJSONInterface',
            possibleTypes: [
              { name: 'GeoJSONPoint' },
              { name: 'GeoJSONMultiPoint' },
              { name: 'GeoJSONLineString' },
              { name: 'GeoJSONMultiLineString' },
              { name: 'GeoJSONPolygon' },
              { name: 'GeoJSONMultiPolygon' },
              { name: 'GeoJSONGeometryCollection' },
              { name: 'GeoJSONFeature' },
              { name: 'GeoJSONFeatureCollection' }
            ]
          },
          {
            kind: 'INTERFACE',
            name: 'GeoJSONGeometryInterface',
            possibleTypes: [
              { name: 'GeoJSONPoint' },
              { name: 'GeoJSONMultiPoint' },
              { name: 'GeoJSONLineString' },
              { name: 'GeoJSONMultiLineString' },
              { name: 'GeoJSONPolygon' },
              { name: 'GeoJSONMultiPolygon' }
            ]
          }
        ]
      }
    }
  })

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          )
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`)
        }
      }),
      new HttpLink({
        uri: API_URL,
        credentials: 'include'
      })
    ]),
    cache: new InMemoryCache({ fragmentMatcher })
  })

  return client
}

export const formattedNumber = (v: string): number =>
  Number(v.replace(/ /g, '').replace(',', '.'))
