import TextField from '@mui/material/TextField'
import { useForm, Controller } from 'react-hook-form'
import { confirmCodeMutation } from 'src/graphql/mutations'
import { useMutation } from '@apollo/react-hooks'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './parts/LoginHeader'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { storeUserState } from 'src/redux/auth'
import { Notification } from 'src/components/layout/Notification'
import { ApolloError } from 'apollo-client'
import { SendButton } from './parts/SendButton'

type Props = {
  email: string
  password: string
}

type ErrorCodes =
  | 'NO_CODE_EXISTS'
  | 'TOO_MANY_ATTEMPTS_CODE'
  | 'EXPIRED_CODE'
  | 'INVALID_CODE'

type ConfirmCodeError = {
  errorType: ErrorCodes
  attemptsLeft?: number
}

const ErrorMessages: { [key in ErrorCodes]: string } = {
  NO_CODE_EXISTS: 'Kaksivaiheisen tunnistautumisen koodia ei löydetty!',
  TOO_MANY_ATTEMPTS_CODE:
    'Liian monta yritystä. Luo uusi koodi koittamalla kirjautua uudelleen.',
  EXPIRED_CODE: 'Kaksivaiheisen tunnistautumisen koodi on vanhentunut',
  INVALID_CODE: 'Koodi on väärin!'
}

export function ConfirmCodeForm({ email, password }: Props): JSX.Element {
  const { classes } = useStyles()
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { handleSubmit, control } = useForm({
    defaultValues: {
      code: ''
    }
  })

  const [confirmCode] = useMutation(confirmCodeMutation, {
    onError: (error: ApolloError) => {
      const message = error?.graphQLErrors?.[0]?.message
      try {
        const { errorType, attemptsLeft }: ConfirmCodeError =
          JSON.parse(message)
        errorType === 'INVALID_CODE'
          ? setError(
              `${ErrorMessages[errorType]} ${attemptsLeft} yritystä jäljellä`
            )
          : setError(`${ErrorMessages[errorType]}`)
      } catch (err) {
        setError('Tapahtui tuntematon virhe')
      }
    },
    update: (_cache: any, { data: { confirmCode } }) => {
      const {
        status,
        user: { role }
      } = confirmCode

      if (status === 'ok') {
        dispatch(storeUserState({ role }))
        navigate('/login')
      }
    }
  })

  const onSubmit = (variables: any) => {
    confirmCode({ variables: { ...variables, email, password } })
  }

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="code"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              fullWidth
              onChange={onChange}
              label={'Vahvistuskoodi'}
              variant="standard"
              error={!!error}
            />
          )}
        />
        <SendButton buttonTitle="Kirjaudu" error={error} />
        <Notification message="Kaksivaiheisen tunnistamisen kertakäyttökoodi on lähetetty sähköpostiisi. Koodi saapuu muutaman minuutin sisällä" />
      </form>
    </>
  )
}
