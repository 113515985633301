import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useForm, Controller } from 'react-hook-form'
import { resetUserPassword } from 'src/graphql/mutations'
import { useMutation } from '@apollo/react-hooks'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { LoginHeader, useStyles } from './parts/LoginHeader'
import { Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { isTokenValid } from 'src/graphql/queries'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useDispatch } from 'react-redux'
import { sendNewNotification } from 'src/redux/notification'

const schema = z
  .object({
    password: z
      .string()
      .min(8, 'Salasanan on oltava vähintään 8 merkkiä pitkä.'),
    repeatPassword: z.string()
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: 'Salasanat eivät täsmää',
    path: ['repeatPassword']
  })

function ResetPasswordForm(): JSX.Element {
  const { classes } = useStyles()
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const { token: resetPwToken } = useParams<{ token: string }>()
  const dispatch = useDispatch()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      password: '',
      repeatPassword: ''
    },
    resolver: zodResolver(schema)
  })

  const [queryToken, { data }]: any = useLazyQuery(isTokenValid, {
    variables: {
      token: resetPwToken,
      tokenType: 'resetPassword'
    }
  })

  useEffect(() => {
    queryToken(resetPwToken)
  }, [])

  const [resetPassword] = useMutation(resetUserPassword, {
    onError: () => {
      setError('Tapahtui tuntematon virhe.')
    },
    update: (_cache: any, { data: { resetUserPassword } }) => {
      const { status } = resetUserPassword
      if (status === 'ok') {
        dispatch(
          sendNewNotification({
            message: 'Salasanasi on nyt uusittu. Voit kirjautua sisään.'
          })
        )

        navigate('/login')
      }
    }
  })

  const onSubmit = (variables: any) => {
    resetPassword({ variables: { ...variables, token: resetPwToken } })
  }

  if (!data) {
    return <div>Ladataan...</div>
  }

  if (data?.isTokenValid?.isValid === false) {
    return (
      <>
        <Typography color="primary" style={{ marginTop: '1em' }}>
          Salasanan vaihtolinkki on vanhentunut tai virheellinen. Ole hyvä ja
          ota yhteyttä henkilöön, joka ylläpitää organisaationne tunnuksia.
        </Typography>
        <Button color="secondary" component={Link} to={'/login'}>
          Palaa kirjautumisruutuun
        </Button>
      </>
    )
  }

  return (
    <>
      <form
        className={classes.form}
        onChange={() => {
          setError('')
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              type="password"
              onChange={onChange}
              value={value}
              fullWidth
              label={'Salasana'}
              variant="standard"
              error={!!errors.password}
              helperText={errors?.password?.message}
            />
          )}
        />
        <Controller
          name="repeatPassword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              type="password"
              onChange={onChange}
              value={value}
              fullWidth
              label={'Toista salasana'}
              variant="standard"
              error={!!errors.repeatPassword}
              helperText={errors?.repeatPassword?.message}
            />
          )}
        />
        <Typography variant="subtitle1" className={classes.error}>
          {error}
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: '0.5em' }}
        >
          Vaihda salasana
        </Button>
      </form>
    </>
  )
}

export function ResetPasswordPage(): JSX.Element {
  return (
    <LoginHeader
      title="Aseta uusi salasana"
      FormArea={<ResetPasswordForm />}
    ></LoginHeader>
  )
}
