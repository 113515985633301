import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { updateSearchResults } from 'src/redux/searchresults'
import { TableType } from 'src/redux/TableData'
import { SearchPayload } from 'src/services/graphqlServiceTypes'
import { createQueryFilters } from 'src/utils/filter'
import InsightData from '../panel-data/InsightData'
import { useFilter } from 'src/redux'
import { Query } from 'generated-types'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'
import { useParams } from 'react-router-dom'

type Props = {
  panelName: string
}

const query = gql`
  query Project($id: ID!, $filter: Filter!) {
    project(id: $id) {
      id
      name
      ownData(filter: $filter) {
        identifier
        label
        allowExport
        data {
          identifier
          label
          population
          populationPercentage
        }
      }
    }
  }
`

const OwnDatas: FunctionComponent<Props> = (props) => {
  const { panelName } = props
  const { projectId } = useParams()
  const { filters } = useFilter()
  const dispatch = useDispatch()

  const filterData = createQueryFilters(filters)

  const { data: projectNationWideData } = useQuery<Query>(query, {
    variables: { id: projectId, filter: {} }
  })
  const { data: projectFilteredData } = useQuery(query, {
    variables: { id: projectId, filter: filterData }
  })

  const ownDataNationWideData = projectNationWideData?.project?.ownData // getOwnDataData(projectNationWideData)
  const ownDataFilteredData = projectFilteredData?.project?.ownData

  const resettableKeys = ownDataNationWideData
    ? ownDataNationWideData.map((ownData: any) => ownData.identifier)
    : []

  useEffect(() => {
    const organizedData: SearchPayload = { ownData: {} }

    if (!ownDataNationWideData) {
      return
    }

    ownDataNationWideData.forEach((ownData) => {
      if (organizedData.ownData) {
        organizedData.ownData[ownData.identifier] = {}
        ownData.data.forEach((singleData) => {
          if (organizedData.ownData) {
            organizedData.ownData[ownData.identifier][singleData.identifier] = {
              population: singleData.population,
              populationPercentage: singleData.populationPercentage
            }
          }
        })
      }
    })

    dispatch(
      updateSearchResults({
        searchCategory: 'total',
        searchPayload: organizedData
      })
    )
  }, [ownDataNationWideData, dispatch])

  return (
    <InsightData
      data={ownDataFilteredData}
      nationWideData={ownDataNationWideData}
      panelName={panelName}
      tableType={TableType.OwnData}
      resettableKeys={resettableKeys}
    />
  )
}

export default React.memo(OwnDatas)
