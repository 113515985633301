import styled from '@mui/styled-engine'
import { colors } from '../styles/design'

const Background = styled('div')({
  position: 'absolute',
  width: '100%',
  minHeight: '100%',
  color: 'white',
  background: `${colors.turquoise[100]}`,
  padding: '2rem'
})

export default Background
