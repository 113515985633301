import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useFilter } from '../../redux/index'
import { round } from 'lodash'
import { exportPopulationQuery } from 'src/graphql/queries'
import { useExportFilter, useSearchResults } from 'src/redux'
import { StudyDefaultState } from '../../redux/export/exportModelFilter'
import { ExportTable } from './ExportTable'
import { useExportSettings } from 'src/redux/index'
import { compact } from 'lodash'

import { isExportAllowed } from './export-utils'
import { getStudiesWithDisabledExport } from './export-utils'
import { getOwnDataWithDisabledExport } from './export-utils'

export type Row = {
  [key: string]: string | number
}

export type Column = {
  field: string
  headerName: string
  width: number
}

type ExportValue = {
  amount: number
  percentage: number
  fieldID: string
}

type Area = {
  areaLabel: string
  areaId: string
  municipality: string | null
  region: string | null
  values: ExportValue[]
}

type Label = {
  id: string
  label: string
}

type ExportPopulationOutput = {
  labels: Label[]
  areas: Area[]
}

type CustomProjectData = {
  id: string
  indexes: string[]
}

type ExportPopulationInfoOutput = {
  exportPopulationInfo: ExportPopulationOutput
}

export enum ExportSettings {
  AllAreasTotalRow = 'totalRow',
  MunicipalitiesTotalRow = 'municipalityRow',
  RegionTotalRow = 'regionRow',
  MunicipalityInfo = 'municipalityColumn',
  RegionInfo = 'regionColumn'
}

export const ExportMain: React.FC = () => {
  const exportFilter = useExportFilter()
  const exportSettings = useExportSettings()
  const searchresults = useSearchResults()
  const filters = useFilter()
  const [tableError, setTableError] = useState<string | null>(null)
  const [populationFilters, setPopulationFilters] = useState<string[]>([])
  const [studyFilters, setStudyFilters] = useState<CustomProjectData[]>([])
  const [ownDataFilters, setOwnDataFilters] = useState<CustomProjectData[]>([])
  const [areaFilters, setAreaFilters] = useState<string[]>([])
  const [getNewFilters, { loading, error, data }] =
    useLazyQuery<ExportPopulationInfoOutput>(exportPopulationQuery)

  const { settings: userOptions, postalCodeColumn } = exportSettings
  const addMunicipalityColumn = userOptions.includes(
    ExportSettings.MunicipalityInfo
  )
  const addRegionColumn = userOptions.includes(ExportSettings.RegionInfo)

  function createHeaderRow(labels: Label[] | undefined): Column[] | undefined {
    let columns = [{ field: 'id', headerName: 'Alue', width: 180 }]

    if (postalCodeColumn) {
      columns.push({
        field: 'postalCode',
        headerName: 'Postinumero',
        width: 180
      })
    }

    if (addRegionColumn) {
      columns.push({ field: 'region', headerName: 'Maakunta', width: 180 })
    }

    if (addMunicipalityColumn) {
      columns.push({ field: 'municipality', headerName: 'Kunta', width: 180 })
    }

    if (labels) {
      columns = columns.concat(
        labels.flatMap((value) => {
          return [
            {
              field: value.id,
              headerName: value.label,
              width: 180
            },
            {
              field: `${value.id}_index`,
              headerName: `Index (${value.label})`,
              width: 180
            }
          ]
        })
      )
    }
    return columns
  }

  function calculateIndex(
    fieldID: string,
    areaPercentage: number
  ): number | string {
    if (populationFilters.includes(fieldID)) {
      const populationPercentage =
        searchresults?.total?.population?.[fieldID]?.populationPercentage ?? 0
      return round((areaPercentage / populationPercentage) * 100)
    } else {
      const cutPoint = fieldID.lastIndexOf('_') // StudyIndex is communicated from backend in a manner of: "studyName_number"
      const studyName = fieldID.slice(0, cutPoint)
      const studyIndex = fieldID.slice(cutPoint + 1)

      const studyPercentage =
        searchresults?.total?.studies?.[studyName]?.[studyIndex]
          .populationPercentage
      const ownDataPercentage =
        searchresults?.total?.ownData?.[studyName]?.[studyIndex]
          .populationPercentage
      if (studyPercentage) {
        return round((areaPercentage / studyPercentage) * 100)
      } else if (ownDataPercentage) {
        return round((areaPercentage / ownDataPercentage) * 100)
      } else {
        return ''
      }
    }
  }

  function createRows(areas: Area[] | undefined) {
    let rows

    if (areas) {
      rows = areas.map((value) => {
        const areaId = value?.areaId ?? ''
        const isPostalCode = areaId.length > 3 && !isNaN(Number(areaId))
        const row: Row = {
          id: value.areaLabel
        }
        row['postalCode'] = postalCodeColumn && isPostalCode ? areaId : ''
        row['municipality'] = value?.municipality ?? ''
        row['region'] = value?.region ?? ''
        value.values.forEach((item) => {
          row[item.fieldID] = item.amount
          row[`${item.fieldID}_index`] = calculateIndex(
            item.fieldID,
            item.percentage
          )
        })
        return row
      })
    }
    return rows
  }

  let columns = createHeaderRow(data?.exportPopulationInfo?.labels)
  let rows = createRows(data?.exportPopulationInfo?.areas)

  useEffect(() => {
    function getFilters(study: StudyDefaultState): CustomProjectData[] {
      const filters = Object.keys(study).map((category) => {
        const { selectedIndexes } = study[category]
        if (selectedIndexes.length === 0) {
          return null
        }

        return {
          id: category,
          indexes: selectedIndexes
        }
      })
      return compact(filters)
    }

    const mapFilter = filters.filters.get('map')?.filterValue.toJS()
    mapFilter ? setAreaFilters(mapFilter) : setAreaFilters([])
    setPopulationFilters(exportFilter.populationFields)
    setStudyFilters(getFilters(exportFilter.study))
    setOwnDataFilters(getFilters(exportFilter.ownData))
  }, [exportFilter, filters])

  /**
   * Exporting is allowed, ONLY, and ONLY IF none of the selected studies have disabled exporting.
   *
   * In this case we want the resulting array to be empty.
   */
  const isExportAllowed = () => {
    const foundStudiesWithExportDisabled =
      getStudiesWithDisabledExport(exportFilter)

    const foundOwnDatasWithExportDisabled =
      getOwnDataWithDisabledExport(exportFilter)

    return (
      foundStudiesWithExportDisabled.length === 0 &&
      foundOwnDatasWithExportDisabled.length === 0
    )
  }

  useEffect(() => {
    if (
      (populationFilters.length === 0 &&
        studyFilters.length === 0 &&
        ownDataFilters.length === 0) ||
      areaFilters.length === 0
    ) {
      return
    }
    getNewFilters({
      variables: {
        populationFields: populationFilters,
        studyFields: studyFilters,
        ownDataFields: ownDataFilters,
        areas: areaFilters,
        userOptions: userOptions
      }
    })
  }, [
    getNewFilters,
    populationFilters,
    studyFilters,
    areaFilters,
    ownDataFilters,
    userOptions
  ])

  useEffect(() => {
    if (!studyFilters?.length) {
      return
    }
  }, [studyFilters])

  useEffect(() => {
    if (areaFilters.length === 0) {
      setTableError(
        'Alueita ei ole valittuna. Valitse ensin alue karttanäkymästä.'
      )
    } else if (
      populationFilters.length === 0 &&
      studyFilters.length === 0 &&
      ownDataFilters.length === 0
    ) {
      setTableError('Suodattimia ei ole valittuna. Valitse suodatin oikealta.')
    } else if (error) {
      setTableError('Datan noutamisessa tapahtui virhe.')
    } else {
      setTableError(null)
    }
  }, [areaFilters, populationFilters, studyFilters, ownDataFilters, error])

  return (
    <ExportTable
      exportFilter={exportFilter}
      isExportAllowed={isExportAllowed()}
      rows={rows}
      columns={columns}
      tableError={tableError}
      dataLoading={loading}
      exportSettings={exportSettings}
    />
  )
}
