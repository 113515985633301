import React, { FunctionComponent } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import orange from '@mui/material/colors/amber'
import brown from '@mui/material/colors/brown'
import indigo from '@mui/material/colors/indigo'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import cx from 'classnames'
import first from 'lodash/first'
import last from 'lodash/last'
import styled from '@emotion/styled'

import { addFilter, FilterType, removeFilter } from 'src/redux/filter'
import { closePopover, openPopover } from 'src/redux/ui'
import {
  AnswerOutput,
  AnswerValueOutput,
  QuestionOutput
} from 'src/services/graphqlServiceTypes'
import { createQueryFilters } from '../../../../utils/filter'
import PopoverContent from '../../../PopoverContent'
import { StudySegmentBar } from '../../panel-data/StudySegmentBar'
import useStyles from './graphStyles'
import { useFilter } from 'src/redux'
import { useDispatch } from 'react-redux'
import { CustomDataSegment } from './Question'

type Props = {
  question: QuestionOutput
  segmentNames: CustomDataSegment[]
}

const ChartBar = styled(StudySegmentBar)`
  position: relative;
  font-size: 14px;
  font-family: Helvetica;
  color: #000752;
  font-weight: 600;
  white-space: nowrap;
  height: 22px;
`

const LeftBar = styled(ChartBar)`
  color: ${indigo[900]};
`

const RightBar = styled(ChartBar)`
  color: ${brown[900]};
`

const LeftValue = styled.span`
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
`

const RightValue = styled.span`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;
`

const BarWrapper = styled.div`
  display: flex;
  width: 100%;
`

const TwoOptionsQuestion: FunctionComponent<Props> = ({
  question,
  segmentNames
}) => {
  const { classes } = useStyles()
  const { filters } = useFilter()
  const dispatch = useDispatch()

  const { label, answers, studyId } = question

  const firstAnswerSet = first(answers.values) || { values: [] }
  const firstAnswer = first(firstAnswerSet.values) || { name: undefined }
  const secondAnswer = last(firstAnswerSet.values) || { name: undefined }
  const firstLabel = firstAnswer.name
  const secondLabel = secondAnswer.name

  const filterData = createQueryFilters(filters)
  const { study: filteredStudy } = filterData
  const { id: filteredStudyId, segmentId: filteredSegmentId } =
    filteredStudy || {}

  return (
    <Accordion square>
      <AccordionSummary
        className={classes.panelSummary}
        id="question-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.name}>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                className={cx(classes.cell, classes.nameTh, classes.cellTh)}
              />
              <TableCell
                className={cx(classes.cell, classes.scaleTh, classes.cellTh)}
              >
                {firstLabel}
              </TableCell>
              <TableCell
                className={cx(
                  classes.cell,
                  classes.scaleTh,
                  classes.lastTh,
                  classes.cellTh
                )}
              >
                {secondLabel}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {answers.values.map((answer: AnswerOutput) => {
              const answerValue1 = first<AnswerValueOutput>(answer.values)
              const answerValue2 = last<AnswerValueOutput>(answer.values)

              if (!answerValue1 || !answerValue2) {
                return null
              }

              const selected =
                answer.segmentId === filteredSegmentId &&
                studyId === filteredStudyId

              const { segmentId } = answer
              const segment = segmentNames.find((elem) => elem.id === segmentId)

              const rowName = segment?.name ? segment.name : answer.name

              const total =
                Number(answerValue1.value) + Number(answerValue2.value)

              const handleCellPopoverOpen = (event: any) => {
                event.stopPropagation()
                dispatch(
                  openPopover({
                    popoverTargetRef: event.currentTarget,
                    popoverContent: (
                      // @ts-ignore
                      <PopoverContent>
                        <span>
                          {answerValue1.name}{' '}
                          {((100 * Number(answerValue1.value)) / total).toFixed(
                            0
                          )}
                          %
                        </span>
                        <span>
                          {answerValue2.name}{' '}
                          {((100 * Number(answerValue2.value)) / total).toFixed(
                            0
                          )}
                          %
                        </span>
                      </PopoverContent>
                    )
                  })
                )
              }

              const onRowClick = (segmentId: string, segmentLabel: string) => {
                const payload = {
                  filterName: studyId,
                  filterValue: segmentId,
                  filterLabel: segmentLabel,
                  filterType: FilterType.StudySegment
                }
                if (selected) {
                  dispatch(removeFilter(payload))
                } else {
                  dispatch(addFilter(payload))
                }
              }

              return (
                <TableRow
                  key={answer.segmentId}
                  className={cx(classes.tr, selected ? classes.selectedTr : '')}
                  onClick={() => onRowClick(answer.segmentId, rowName)}
                >
                  <TableCell
                    className={cx(
                      classes.cell,
                      classes.firstCell,
                      selected ? classes.selectedTd : ''
                    )}
                  >
                    {rowName}
                  </TableCell>
                  <TableCell
                    className={cx(classes.cell, classes.segmentBar)}
                    colSpan={2}
                    onClick={handleCellPopoverOpen}
                    onMouseEnter={handleCellPopoverOpen}
                    onMouseLeave={() => dispatch(closePopover())}
                  >
                    <BarWrapper>
                      <LeftBar
                        color={indigo[100]}
                        style={{
                          width: `${
                            (100 * Number(answerValue1.value)) / total
                          }%`
                        }}
                      >
                        <LeftValue>{`${Number(
                          (100 * Number(answerValue1.value)) / total
                        ).toFixed(0)}%`}</LeftValue>
                      </LeftBar>
                      <RightBar
                        color={orange[100]}
                        style={{
                          width: `${
                            (100 * Number(answerValue2.value)) / total
                          }%`
                        }}
                      >
                        <RightValue>{`${Number(
                          (100 * Number(answerValue2.value)) / total
                        ).toFixed(0)}%`}</RightValue>
                      </RightBar>
                    </BarWrapper>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(TwoOptionsQuestion)
