import React from 'react'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid'
import { Typography } from '@mui/material'
import { Column, Row } from './ExportMain'

import { colors } from '../styles/design'
import { ExportFilter } from '../../redux/export/exportModelFilter'
import {
  getOwnDataWithDisabledExport,
  getStudiesWithDisabledExport
} from './export-utils'

type TableErrorProps = {
  text: string
}

type Props = {
  tableError: string | null
  rows: Row[] | undefined
  columns: Column[] | undefined
  dataLoading: boolean
  isExportAllowed: boolean
  exportFilter: ExportFilter
}

const TableError: React.FC<TableErrorProps> = (props) => {
  const { text } = props
  return (
    <Typography sx={{ marginTop: '50%' }} variant="h5">
      {text}
    </Typography>
  )
}

function CSVExportToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{ delimiter: ';' }}
        sx={{
          borderRadius: 0,
          background: 'transparent',
          '&:hover': { background: 'transparent' }
        }}
      />
    </GridToolbarContainer>
  )
}

type CsvDisabledProps = {
  exportFilter: ExportFilter
}
const CSVExportDisabledToolbar: React.FC<CsvDisabledProps> = (
  props
): JSX.Element => {
  const { exportFilter } = props
  const studiesWithDisabledExport = getStudiesWithDisabledExport(exportFilter)
  const ownDataWithDisableExport = getOwnDataWithDisabledExport(exportFilter)

  return (
    <div style={{ padding: '4px 5px' }}>
      <p style={{ margin: 0 }}>Datan vienti estetty:</p>
      {studiesWithDisabledExport.length > 0 && (
        <p>
          <strong>Tutkimukset: </strong>
          {studiesWithDisabledExport.join(', ')}
        </p>
      )}
      {ownDataWithDisableExport.length > 0 && (
        <p>
          <strong>Oma data: </strong>
          {ownDataWithDisableExport.join(', ')}
        </p>
      )}
    </div>
  )
}

export const ExportDatagrid: React.FC<Props> = (props) => {
  const {
    tableError,
    rows,
    columns,
    dataLoading,
    isExportAllowed,
    exportFilter
  } = props

  if (dataLoading) {
    return <TableError text="" />
  }

  if (tableError) {
    return <TableError text={tableError} />
  }

  if (!rows || !columns) {
    return <TableError text={'Taulukon muodostamisessa tapahtui virhe.'} />
  }

  return (
    <DataGrid
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          background: colors.turquoise[300],
          borderRadius: 0,
          color: colors.white
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 700
        },
        '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
          color: colors.white,
          '&:hover': {
            color: colors.white
          }
        },
        '& .MuiDataGrid-row:nth-child(odd)': {
          '& .MuiDataGrid-cell:nth-child(odd)': {
            background: colors.white
          },
          '& .MuiDataGrid-cell:nth-child(even)': {
            background: colors.turquoise[100]
          }
        },
        '& .MuiDataGrid-row:nth-child(even)': {
          '& .MuiDataGrid-cell:nth-child(odd)': {
            background: colors.turquoise[100]
          },
          '& .MuiDataGrid-cell:nth-child(even)': {
            background: colors.turquoise[150]
          }
        },
        '& .MuiDataGrid-row .MuiDataGrid-cell': {
          borderBottom: 0
        }
      }}
      disableColumnMenu
      rows={rows}
      columns={columns}
      pageSize={50}
      components={
        isExportAllowed
          ? {
              Toolbar: CSVExportToolbar
            }
          : {
              Toolbar: () => (
                <CSVExportDisabledToolbar exportFilter={exportFilter} />
              )
            }
      }
    />
  )
}
