import { Set } from 'immutable'

import { postalCodesByRegion } from '../../utils/postalCodesByRegion'
import { postalCodesByMunicipality } from '../../utils/postalCodesByMunicipality'
import { AdministrativeDivisions } from '.'

function getMatchingMunicipalitiesForPostalCodes(
  postalCodes: Set<string> | undefined,
  administrativeDivisions: AdministrativeDivisions = {
    region: Set(),
    municipality: Set()
  }
): AdministrativeDivisions {
  if (!postalCodes || postalCodes.count() === 0) {
    return administrativeDivisions
  }

  const matchingMunicipality = postalCodesByMunicipality.find(
    (municipality) => {
      return municipality.postalCodes.includes(postalCodes.first())
    }
  )

  if (matchingMunicipality) {
    if (Set(matchingMunicipality.postalCodes).isSubset(postalCodes)) {
      return getMatchingMunicipalitiesForPostalCodes(
        postalCodes.subtract(matchingMunicipality.postalCodes),
        {
          region: administrativeDivisions.region,
          municipality: administrativeDivisions.municipality.add(
            matchingMunicipality.code
          )
        }
      )
    } else {
      return { region: Set(), municipality: Set() }
    }
  }
  return { region: Set(), municipality: administrativeDivisions.municipality }
}

function getMatchingRegionsForMunicipalities(
  municipalityCodes: Set<string>,
  administrativeDivisions: AdministrativeDivisions = {
    region: Set(),
    municipality: Set()
  }
): AdministrativeDivisions {
  if (!municipalityCodes || municipalityCodes.count() === 0) {
    return administrativeDivisions
  }

  const matchingRegion = postalCodesByRegion.find((region) => {
    return region.municipalitiesInRegion.includes(municipalityCodes.first())
  })

  if (matchingRegion) {
    if (
      Set(matchingRegion.municipalitiesInRegion).isSubset(municipalityCodes)
    ) {
      return getMatchingRegionsForMunicipalities(
        municipalityCodes.subtract(matchingRegion.municipalitiesInRegion),
        {
          region: administrativeDivisions.region.add(matchingRegion.code),
          municipality: administrativeDivisions.municipality
        }
      )
    } else {
      return {
        region: Set(),
        municipality: administrativeDivisions.municipality
      }
    }
  }

  return {
    region: administrativeDivisions.region,
    municipality: administrativeDivisions.municipality
  }
}

export function getEquivalentAdministrativeDivisions(postalCodes: Set<string>) {
  const equivalentMunicipalities =
    getMatchingMunicipalitiesForPostalCodes(postalCodes)
  return getMatchingRegionsForMunicipalities(
    equivalentMunicipalities.municipality,
    equivalentMunicipalities
  )
}

export function getPostalCodesForDivisionCode(divisionCode: string): string[] {
  let divisionMatch
  divisionMatch = postalCodesByRegion.find(({ code: regionCode }) => {
    return regionCode === divisionCode
  })
  if (!divisionMatch) {
    divisionMatch = postalCodesByMunicipality.find(
      ({ code: municipalityCode }) => municipalityCode === divisionCode
    )
  }

  if (divisionMatch) {
    return [...divisionMatch.postalCodes]
  } else {
    return [divisionCode]
  }
}
