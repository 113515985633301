import { random } from 'lodash'
import { Action } from 'redux'
import actionCreatorFactory, { isType } from 'typescript-fsa'

const actionCreator = actionCreatorFactory()

export type Notification = {
  message: string
}

export type NotificationStore = Notification & {
  messageId: number
}

export const sendNewNotification =
  actionCreator<Notification>('NEW_NOTIFICATION')

const defaultState: NotificationStore = { message: '', messageId: 1 }

const reducer = (
  state: NotificationStore = defaultState,
  action: Action
): NotificationStore => {
  if (isType(action, sendNewNotification)) {
    const {
      payload: { message }
    } = action
    return { message, messageId: random(0, 200000) }
  }

  return state
}

export default reducer
