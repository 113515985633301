import { ExportFilter } from '../../redux/export/exportModelFilter'
export function getStudiesWithDisabledExport(
  exportFilter: ExportFilter
): string[] {
  return Object.keys(exportFilter.study).filter((category) => {
    const { allowExport, selectedIndexes } = exportFilter.study[category]
    return allowExport === false && selectedIndexes.length !== 0
  })
}

export function getOwnDataWithDisabledExport(
  exportFilter: ExportFilter
): string[] {
  return Object.keys(exportFilter.ownData).filter((category) => {
    const { allowExport, selectedIndexes } = exportFilter.ownData[category]
    return allowExport === false && selectedIndexes.length !== 0
  })
}

/**
 * Exporting is allowed, ONLY, and ONLY IF none of the selected studies have disabled exporting.
 *
 * In this case we want the resulting array to be empty.
 */
export function isExportAllowed(exportFilter: ExportFilter): boolean {
  console.info(
    `exportFilter.study: ${JSON.stringify(exportFilter.study, null, 2)}`
  )
  console.info(
    `exportFilter.ownData: ${JSON.stringify(exportFilter.ownData, null, 2)}`
  )

  const foundStudiesWithExportDisabled =
    getStudiesWithDisabledExport(exportFilter)

  console.info(
    `foundStudiesWithExportDisabled : ${JSON.stringify(
      foundStudiesWithExportDisabled,
      null,
      2
    )}`
  )

  const foundOwnDatasWithExportDisabled =
    getOwnDataWithDisabledExport(exportFilter)

  return (
    foundStudiesWithExportDisabled.length === 0 &&
    foundOwnDatasWithExportDisabled.length === 0
  )
}
