import React, { FunctionComponent } from 'react'
import { Map } from 'immutable'
import { compact } from 'lodash'

import { FilterSpec } from 'src/redux/filter'
import {
  PopulationInfoOutput,
  StudyOutput,
  TabularDataGroup,
  TabularData
} from 'src/services/graphqlServiceTypes'
import { TableData, TableType } from 'src/redux/TableData'
import DataTable from './DataTable'
import {
  generalCategories,
  householdCategories,
  employmentCategories,
  populationDataCategories
} from 'src/utils/categories'
import { useExportFilter, useFilter } from 'src/redux'
import { ExportChecked } from 'src/redux/export'
import { OwnDataData } from 'generated-types'

type Props = {
  data?: PopulationInfoOutput | StudyOutput | OwnDataData[]
  nationWideData?: PopulationInfoOutput | StudyOutput | OwnDataData[]
  tableType: TableType
  panelName: string
  resettableKeys: string[]
}

type NestedRow = {
  percentage: number
  value: number
}

type Row = {
  checked: boolean
  id: string
  label: string
  nationWide: NestedRow
  selection: NestedRow
}
type DataTableGroup = {
  index: number
  rowIds: string[]
  tableData: TabularDataGroup<TabularData>
  rows: Row[]
}

type ExportAllCategories = {
  [key: string]: string[]
}

export function getPopulationPanelCategories(panelName: string | undefined) {
  switch (panelName) {
    case 'population':
      return generalCategories
    case 'household':
      return householdCategories
    case 'employment':
      return employmentCategories
    default:
      return populationDataCategories
  }
}

const InsightData: FunctionComponent<Props> = ({
  data: filteredData,
  nationWideData: filteredNationWideData,
  tableType,
  panelName,
  resettableKeys
}) => {
  const { populationFields, categories, study, ownData } = useExportFilter()
  const { filters } = useFilter()

  if (!filteredData || !filteredNationWideData) {
    return null
  }

  const orderedPopulationData = getPopulationPanelCategories(panelName)

  const orderedGroups =
    resettableKeys.length > 0 ? resettableKeys : orderedPopulationData

  function getSelectedValue(
    filters: Map<string, FilterSpec>,
    dataId: string
  ): string | null {
    return filters.get(dataId, { filterValue: null }).filterValue
  }

  function createTableData(
    item: TabularData,
    nationWideTableData: TabularDataGroup<TabularData>,
    checkedValues: string[],
    categoryCheckStatus: ExportChecked,
    isChecked: boolean | undefined = undefined
  ): TableData {
    const nationWideItem = nationWideTableData
      ? nationWideTableData.data.filter(
          (iterItem) => iterItem.identifier === item.identifier
        )[0] || {}
      : { identifier: '', label: '', population: 0, populationPercentage: 0 }

    return {
      id: item.identifier,
      label: item.label,
      checked:
        checkedValues.includes(item.identifier) ||
        categoryCheckStatus === ExportChecked.Yes ||
        isChecked === true,
      selection: {
        value: item.population,
        percentage: item.populationPercentage
      },
      nationWide: {
        value: nationWideItem.population,
        percentage: nationWideItem.populationPercentage
      }
    }
  }

  const fullUpdate: ExportAllCategories = {}

  const DataTableGroups: DataTableGroup[] = compact(
    orderedGroups.map((groupKey, index) => {
      // @ts-ignore (:CLOWN_FACE, TROLOLOOOOOOOOOOOOO)
      const tableData = filteredData.filter(
        (item: any) => item.identifier === groupKey
      )[0]
      // @ts-ignore (:CLOWN_FACE, TROLOLOOOOOOOOOOOOO)
      const nationWideTableData = filteredNationWideData.filter(
        (item: any) => item.identifier === groupKey // :any (:CLOWN_FACE, TROLOLOOOOOOOOOOOOO)
      )[0]
      if (!tableData?.identifier) {
        return null
      }
      const categoryCheckStatus = categories[tableData.identifier]

      const tableId = tableData.identifier

      const rows: TableData[] = tableData.data.map((item: any) => {
        // :any (:CLOWN_FACE, TROLOLOOOOOOOOOOOOO)
        let isChecked: boolean = false
        if (panelName === 'study') {
          if (study[tableId]?.selectedIndexes.includes(item.identifier)) {
            isChecked = true
          }
        } else if (panelName === 'ownData') {
          if (ownData[tableId]?.selectedIndexes.includes(item.identifier)) {
            isChecked = true
          }
        }

        return createTableData(
          item,
          nationWideTableData,
          populationFields,
          categoryCheckStatus,
          isChecked
        )
      })

      const rowIds: string[] = rows.map((row) => {
        return row.id
      })
      fullUpdate[tableId] = rowIds
      return { index, rowIds, tableData, rows }
    })
  )

  return (
    <>
      {DataTableGroups.map(({ index, tableData, rows }) => {
        return (
          <>
            <DataTable
              key={index}
              panelName={panelName}
              title={tableData.label}
              dataId={tableData.identifier}
              data={rows}
              selectedId={getSelectedValue(filters, tableData.identifier)}
              tableType={tableType}
              resettable={resettableKeys}
              filters={filters}
            />
          </>
        )
      })}
    </>
  )
}

export default React.memo(InsightData)
