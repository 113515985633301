import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled'

import logo from './logo.png'

const LogoImg = styled.img`
  width: 120px;
`

const Logo: FunctionComponent = () => {
  return (
    <span>
      <LogoImg src={logo} />
    </span>
  )
}

export default Logo
