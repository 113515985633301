import React from 'react'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'

import { FilterStateMode, changeUIMode } from 'src/redux/filter'
import { useFilter } from 'src/redux'
import { useDispatch } from 'react-redux'

import { appTheme } from '../../layout/appTheme'

type FilterModeIndicator = {
  active?: boolean
}

const FilterModeToggleText = styled(Button)<FilterModeIndicator>`
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  display: inline-block;
  // margin: 10px 5px 0px 0px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? `2px ${appTheme.palette.primary.main} solid` : ''};

  margin-bottom: ${(props) => props.active ? '0' : '2px'};
  
  border-radius: 0;
`

const VerticalLine = styled.div`
  border-left: 1px solid ${appTheme.palette.primary.main};
  height: 20px;
  margin: 2px 8px 4px 8px;
`

const FlexWrap = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  clear: both;
`

const SpaceBetween = styled(FlexWrap)`
  justify-content: space-between;
`

export const FilterModeToggle: React.FC = () => {
  const { mode } = useFilter()
  const dispatch = useDispatch()

  if (mode !== FilterStateMode.Export) {
    return (
      <SpaceBetween>
        <FilterModeToggleText
          variant="text"
          onClick={() =>
            dispatch(changeUIMode({ mode: FilterStateMode.Population }))
          }
          active={mode === FilterStateMode.Population}
        >
          Lukumäärä
        </FilterModeToggleText>
        <VerticalLine />
        <FilterModeToggleText
          variant="text"
          onClick={() =>
            dispatch(changeUIMode({ mode: FilterStateMode.Index }))
          }
          active={mode === FilterStateMode.Index}
        >
          Suhteellinen
        </FilterModeToggleText>
      </SpaceBetween>
    )
  } else {
    return null
  }
}
