import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { OperationVariables } from 'react-apollo'

export const useQueryWithSmoothDataChange = (
  query: any,
  options?: OperationVariables
): any => {
  const { data, loading, error } = useQuery(query, options)
  const [finalData, setFinalData] = useState()

  useEffect(() => {
    if (!loading) {
      setFinalData(data)
    }
  }, [data, loading])

  return { data: finalData, loading, error }
}
