import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import CountUp from 'react-countup'

import { useDispatch } from 'react-redux'

import Paper from '@mui/material/Paper'
import { makeStyles } from 'tss-react/mui'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'

import { colors } from '../../styles/design'

import {
  FilterStateFilters,
  FilterStateMode,
  addFilter,
  removeFilter
} from 'src/redux/filter'
import { closePopover, openPopover } from 'src/redux/ui'
import { useFilter } from 'src/redux/index'
import { useExportFilter } from 'src/redux'
import {
  updateFieldExport,
  ExportChecked,
  updateCategoryLevelExport
} from 'src/redux/export'
import { Panel } from 'src/redux/export/exportModelFilter'

import {
  TableData,
  TableType,
  getFilterTypeForTable
} from 'src/redux/TableData'
import { formatNumberWrapper } from '../../../utils/numeral'
import PopoverContent from '../../PopoverContent'
import { BarColor, selectBarColor, StudySegmentBar } from './StudySegmentBar'
import SegmentIndex from './SegmentIndex'
import { css } from '@mui/material/styles'

const useStyles = makeStyles()({
  root: {
    width: '100%',
    marginTop: '8px',
    marginBottom: '24px',
    overflowX: 'auto',
    boxShadow: 'none'
  },
  selected: {
    backgroundColor: `${colors.black} !important`, // original: #396
    color: 'red !important'
  },
  selectedColor: {
    color: 'white !important'
  },
  cell: {
    borderBottomColor: 'rgb(240, 240, 240)',
    cursor: 'pointer',
    fontFamily: 'Helvetica, Arial, sans-serif',
    padding: '4px 0 4px 0',
    whiteSpace: 'nowrap'
  },
  firstCell: {
    paddingLeft: '24px',
    wordBreak: 'break-word',
    whiteSpace: 'normal'
  },
  checkBox: {
    height: '10px',
    paddingLeft: '30px',
    boxSizing: 'border-box'
  },
  lastCell: {
    paddingLeft: '16px',
    paddingRight: '24px'
  },
  headCell: {
    color: '#222',
    cursor: 'auto',
    fontFamily: '"Source Sans Pro", din-2014, Helvetica, Arial, sans-serif',
    fontWeight: 600
  },
  labelCell: {
    fontSize: '16px',
    fontWeight: 700
  },
  nationWideCell: {
    color: '#595959'
  },
  barCell: {
    height: '27px'
  },
  footerCell: {
    cursor: 'auto'
  },
  row: {
    padding: 0,
    height: 'auto',
    marginBottom: '1px'
  }
})

type Props = {
  className?: string
  title?: string
  dataId: string
  selectedId?: string | null
  data: TableData[]
  footNotes?: string[]
  tableType: TableType
  resettable: string[]
  filters: FilterStateFilters
  panelName: string
}

type StyledTableCellProps = {
  width?: string | null
}

const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  && {
    ${(props) =>
      props.width &&
      css`
        width: ${props.width};
      `}
  }
`

const DataTable: FunctionComponent<Props> = (props) => {
  const { panelName, dataId, data } = props
  const { classes, cx } = useStyles()
  const { mode } = useFilter()
  const exportFilter = useExportFilter()
  const dispatch = useDispatch()

  let categories: Panel
  if (panelName === 'study') {
    categories = exportFilter.studyCategories
  } else if (panelName === 'ownData') {
    categories = exportFilter.ownDataCategories
  } else {
    categories = exportFilter.categories
  }

  const handleSelection = (id: string, label: string) => {
    const { dataId, resettable, tableType } = props

    if (mode === FilterStateMode.Export) {
      dispatch(
        updateFieldExport({
          topCategory: panelName,
          category: dataId,
          field: id
        })
      )
      return
    }

    const filterType = getFilterTypeForTable(tableType)

    if (resettable.indexOf(dataId) !== -1) {
      resettable.map((key) => {
        return dispatch(
          removeFilter({
            filterName: key,
            filterValue: id,
            filterLabel: label,
            filterType
          })
        )
      })
    }

    const payload = {
      filterName: dataId,
      filterValue: id,
      filterLabel: label,
      filterType
    }

    if (selectedId === id) {
      dispatch(removeFilter(payload))
    } else {
      dispatch(addFilter(payload))
    }
  }

  function toggleCategoryCheckbox(dataId: string, checked: ExportChecked) {
    let checkedState =
      checked !== ExportChecked.Not ? ExportChecked.Not : ExportChecked.Yes
    dispatch(
      updateCategoryLevelExport({
        topCategory: panelName,
        category: dataId,
        checked: checkedState
      })
    )
  }

  const { footNotes, tableType, title, selectedId, filters } = props

  const showSelectionColumnForOwnData =
    tableType === TableType.OwnData && filters.has('map')
  const showSelectionColumnForOther =
    tableType !== TableType.OwnData && filters.size > 0
  const showSelectionColumn =
    showSelectionColumnForOwnData || showSelectionColumnForOther

  const showIndexColumnForPopulationInfoTable =
    tableType === TableType.PopulationInfo && showSelectionColumn && !selectedId
  const showIndexColumnForStudyTable =
    tableType === TableType.Study && showSelectionColumn && !selectedId
  const showIndexColumnForOwnDataTable =
    tableType === TableType.OwnData && filters.has('map') && filters.size === 1
  const showIndexColumn =
    showIndexColumnForPopulationInfoTable ||
    showIndexColumnForStudyTable ||
    showIndexColumnForOwnDataTable

  return (
    <Paper className={classes.root}>
      <Table>
        <TableHead>
          <TableRow className={classes.row}>
            <StyledTableCell
              className={cx(
                classes.headCell,
                classes.firstCell,
                classes.cell,
                classes.labelCell
              )}
              component="th"
            >
              {mode === FilterStateMode.Export ? (
                <Checkbox
                  size="small"
                  onChange={() => {
                    toggleCategoryCheckbox(dataId, categories[dataId])
                  }}
                  checked={categories[dataId] === ExportChecked.Yes}
                  indeterminate={categories[dataId] === ExportChecked.Partial}
                />
              ) : null}
              <div style={{ marginLeft: '20px', whiteSpace: 'pre-line' }}>
                {title}
              </div>
            </StyledTableCell>

            {showIndexColumn && (
              <StyledTableCell
                width="40px"
                className={cx(classes.headCell, classes.cell)}
                align="right"
                component="th"
              >
                Indeksi
              </StyledTableCell>
            )}

            <StyledTableCell
              width="80px"
              className={cx(classes.headCell, classes.cell)}
              align="right"
              component="th"
            >
              {showSelectionColumn && 'Valinta'}
            </StyledTableCell>

            <StyledTableCell
              width="80px"
              className={cx(classes.headCell, classes.cell, {
                [classes.nationWideCell]: showSelectionColumn,
                [classes.lastCell]: tableType === TableType.OwnData
              })}
              align="right"
              component="th"
            >
              Suomi
            </StyledTableCell>

            {tableType !== TableType.OwnData && (
              <StyledTableCell
                width="27%"
                className={cx(classes.headCell, classes.cell, classes.lastCell)}
                component="th"
              >
                %
              </StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const tdClass = cx(classes.cell, {
              [classes.selectedColor]: selectedId === row.id
            })
            const selectionBarColor = selectBarColor(
              row.selection.percentage,
              row.nationWide.percentage
            )

            const PopoverStuff = () => (
              // @ts-ignore
              <PopoverContent>
                {showSelectionColumn ? (
                  <>
                    <h5>{row.label}</h5>
                    <span>
                      Valinta: {(100 * row.selection.percentage).toFixed(0)}%
                    </span>
                    <span>
                      Suomi: {(100 * row.nationWide.percentage).toFixed(0)}%
                    </span>
                  </>
                ) : (
                  <span>
                    {row.label}: {(100 * row.nationWide.percentage).toFixed(0)}%
                  </span>
                )}
              </PopoverContent>
            )

            const handleCellPopoverOpen = (event: any) => {
              event.stopPropagation()
              dispatch(
                openPopover({
                  popoverTargetRef: event.currentTarget,
                  popoverContent: <PopoverStuff />
                })
              )
            }

            return (
              <TableRow
                className={cx(classes.row, {
                  [classes.selected]: selectedId === row.id
                })}
                key={row.id}
                onClick={() => handleSelection(row.id, row.label)}
                selected={selectedId === row.id}
              >
                <TableCell className={cx(tdClass, classes.firstCell)}>
                  {mode === FilterStateMode.Export ? (
                    <Checkbox
                      size="small"
                      classes={{ root: classes.checkBox }}
                      checked={row.checked}
                    />
                  ) : null}
                  {row.label}
                </TableCell>

                {showIndexColumn && (
                  <TableCell className={tdClass} align="right">
                    <SegmentIndex
                      value={row.selection.percentage}
                      comparison={row.nationWide.percentage}
                    />
                  </TableCell>
                )}

                <TableCell className={tdClass} align="right">
                  {showSelectionColumn ? (
                    // @ts-ignore
                    <CountUp
                      preserveValue={true}
                      end={row.selection.value}
                      formattingFn={formatNumberWrapper}
                    ></CountUp>
                  ) : null}
                </TableCell>

                <TableCell
                  className={cx(tdClass, {
                    [classes.nationWideCell]: showSelectionColumn,
                    [classes.lastCell]: tableType === TableType.OwnData
                  })}
                  align="right"
                >
                  {/* @ts-ignore */}
                  <CountUp
                    preserveValue={true}
                    end={row.nationWide.value}
                    formattingFn={formatNumberWrapper}
                  ></CountUp>
                </TableCell>
                {tableType !== TableType.OwnData && (
                  <TableCell
                    className={cx(
                      classes.cell,
                      classes.lastCell,
                      classes.barCell,
                      {
                        [classes.selectedColor]: selectedId === row.id
                      }
                    )}
                    onClick={handleCellPopoverOpen}
                    onMouseEnter={handleCellPopoverOpen}
                    onMouseLeave={() => dispatch(closePopover())}
                  >
                    {showSelectionColumn && (
                      <StudySegmentBar
                        color={selectionBarColor}
                        style={{ width: `${100 * row.selection.percentage}%` }}
                      />
                    )}
                    <StudySegmentBar
                      color={BarColor.nationWide}
                      style={{ width: `${100 * row.nationWide.percentage}%` }}
                    />
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </TableBody>
        <TableFooter>
          {footNotes &&
            footNotes.map((footNote: any, i: number) => {
              return (
                <TableRow className={classes.row} key={i}>
                  <TableCell
                    className={cx(classes.cell, classes.footerCell)}
                    colSpan={2}
                  >
                    {footNote}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableFooter>
      </Table>
    </Paper>
  )
}

export default DataTable
