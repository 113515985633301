import gql from 'graphql-tag'

export const loginMutation = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      status
      token
      user {
        role
      }
    }
  }
`

export const sendPasswordResetLink = gql`
  mutation ResetPassword($email: String!) {
    resetPasswordLink(email: $email) {
      status
    }
  }
`

export const newUserRegisterMutation = gql`
  mutation newUserRegister(
    $token: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    newUserRegister(
      password: $password
      token: $token
      firstName: $firstName
      lastName: $lastName
    ) {
      status
    }
  }
`

export const resetUserPassword = gql`
  mutation resetUserPassword($token: String!, $password: String!) {
    resetUserPassword(password: $password, token: $token) {
      status
    }
  }
`

export const confirmCodeMutation = gql`
  mutation ConfirmCode($email: String!, $password: String!, $code: String!) {
    confirmCode(email: $email, password: $password, code: $code) {
      status
      token
      user {
        role
      }
    }
  }
`

export const logoutMutation = gql`
  mutation Logout {
    logout
  }
`
