import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/react-hooks'
import Chip from '@mui/material/Chip/Chip'
import Button from '@mui/material/Button'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import styled from '@emotion/styled'
import { colors } from 'src/components/styles/design'

import {
  FilterSpec,
  AdministrativeDivisions,
  removeFilter,
  setCustomAreaSelection
} from 'src/redux/filter'
import { useUser, useFilter, useNewMap } from 'src/redux'
import Logo from 'src/components/static/Logo'
import { UserMenu } from 'src/components/layout/UserMenu'
import { FilterModeToggle } from './FilterModeToggle'
import { DataMapModeToggle } from './DataMapModeToggle'
import { ModalPages, setModal } from 'src/redux/modal'
import { Query } from 'generated-types'
import gql from 'graphql-tag'

const useStyles = makeStyles()((theme: Theme) => ({
  child: {
    width: '100%',
    padding: '10px 20px'
  },
  root: {
    width: '100%',
    background: colors.turquoise[100]
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: '#e0e0e0'
  },
  areaSelectionButton: {
    width: '15em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

const FlexWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  clear: both;
  min-height: 39px;
`

const AlignHorizontally = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`

const JustifyLeft = styled(FlexWrap)`
  justify-content: flex-start;
`

const SpaceBetween = styled(FlexWrap)`
  justify-content: space-between;
`

const ChipList = styled(JustifyLeft)`
  margin-top: 16px;
  min-height: 48px;
`

const areaSelectionQuery = gql`
  query areaSelection($id: ID!) {
    areaSelection(id: $id) {
      name
      regions
      municipalities
      postals
    }
  }
`

function SidebarTop(): JSX.Element {
  const { classes } = useStyles()
  const user = useUser()
  const { areaSelectionId, areaSelectionName } = useNewMap()

  const [getAreas, { data: dataAreas }] =
    useLazyQuery<Query>(areaSelectionQuery)

  const dispatch = useDispatch()
  const { administrativeDivisions, labels, filters } = useFilter()

  const handleDelete = (item: FilterSpec) => {
    let value = null

    if (item.filterName === 'map') {
      value =
        item.filterValue && item.filterValue.count() === 1
          ? item.filterValue.first()
          : null
    } else {
      value = item.filterValue
    }

    dispatch(
      removeFilter({
        filterName: item.filterName,
        filterValue: value,
        filterType: item.filterType,
        filterLabel: item.filterLabel
      })
    )
  }

  const renderLabel = (
    item: FilterSpec,
    administrativeDivisions: AdministrativeDivisions
  ): string | undefined => {
    if (item.filterName === 'map') {
      const mapFilters = labels.get('map')

      if (mapFilters) {
        return item.filterValue.count() === 1 ||
          administrativeDivisions.municipality.count() === 1 ||
          administrativeDivisions.region.count() === 1
          ? item.filterLabel
          : 'Useita alueita'
      }
    } else {
      return item.filterLabel
    }
  }

  const renderBreadCrumbItem = (item: FilterSpec | undefined) => {
    if (!item) {
      return null
    }

    return (
      <Chip
        className={classes.chip}
        label={renderLabel(item, administrativeDivisions)}
        onDelete={() => handleDelete(item)}
        key={item.filterName}
      />
    )
  }

  const renderBreadCrumbs = () => {
    const keys = filters.keySeq()

    return keys.map((key: string) => {
      return renderBreadCrumbItem(filters.get(key))
    })
  }

  useEffect(() => {
    if (areaSelectionId) {
      getAreas({
        variables: { id: areaSelectionId }
      })
    }
  }, [areaSelectionId])

  useEffect(() => {
    if (dataAreas?.areaSelection && areaSelectionId) {
      dispatch(setCustomAreaSelection(dataAreas?.areaSelection))
    }
  }, [areaSelectionId, dataAreas])

  return (
    <div className={classes.root}>
      <AlignHorizontally>
        <SpaceBetween>
          <Logo />
          <UserMenu />
        </SpaceBetween>
      </AlignHorizontally>
      <div className={classes.child}>
        {user && (
          <Button
            className={classes.areaSelectionButton}
            variant="contained"
            onClick={() =>
              dispatch(
                setModal({
                  modalOpen: true,
                  modalPage: ModalPages.AreaSelection
                })
              )
            }
          >
            {areaSelectionName ? areaSelectionName : 'Valitse aluevalintani'}
          </Button>
        )}
        <ChipList>{renderBreadCrumbs()}</ChipList>
        <SpaceBetween>
          <DataMapModeToggle />
          <FilterModeToggle />
        </SpaceBetween>
      </div>
    </div>
  )
}

export default SidebarTop
