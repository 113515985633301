import { FunctionComponent, useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { Modal } from '@mui/material'
import { saveUserMapPosition } from 'src/redux/auth'
import {
  addFilter as addFilterAction,
  FilterStateMode,
  removeFilter as removeFilterAction,
  resetMapFilters,
  updateMapAndZoneData,
  FilterSpec
} from '../../redux/filter'
import { useFilter, useUI, useModal, useUserPreferences } from 'src/redux/index'
import {
  updateAvailableOwnData,
  updateAvailableStudies
} from 'src/redux/export'
import { StudyDefaultState } from 'src/redux/export/exportModelFilter'
import InsightMap from '../map'
import { ExportMain } from '../export/ExportMain'
import Popover from '../Popover'
import Sidebar from '../sidebar/Sidebar'
import { About, MySettings, AreaSelection } from '../modal'
import { MapTheme } from '../map/colors'
import { LatLng } from 'leaflet'
import { useNavigate, useParams } from 'react-router-dom'
import { Query } from 'generated-types'
import gql from 'graphql-tag'
import { ModalPages } from 'src/redux/modal'

const PageLayout = styled.div`
  display: flex;
  background-color: white;
`

const MapTableArea = styled.div`
  width: 65%;
`

const SidebarDiv = styled.div`
  width: 35%;
  min-width: 480px;
`

const projectQuery = gql`
  query Project($id: ID!) {
    project(id: $id) {
      id
      areaSelectionIds
      study(filter: {}) {
        identifier
        allowExport
        data {
          identifier
        }
      }

      ownData(filter: {}) {
        identifier
        allowExport
        data {
          identifier
          label
        }
      }
    }
  }
`

export const ProjectPage: FunctionComponent = () => {
  const { projectId } = useParams()
  const { mode, administrativeDivisions, filters } = useFilter()
  const { popoverContent, popoverTargetRef } = useUI()
  const { modalOpen, mapTheme, modalPage } = useModal()
  const { mapPosition, zoomLevel } = useUserPreferences()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [getProject, { data, error }] = useLazyQuery<Query>(projectQuery, {
    variables: { id: projectId }
  })

  function saveMapState(position: LatLng, zoomLevel: number) {
    dispatch(
      saveUserMapPosition({ mapPosition: position, zoomLevel: zoomLevel })
    )
  }

  const addFilter = (filter: FilterSpec) => {
    dispatch(addFilterAction(filter))
  }

  const removeFilter = (filter: FilterSpec) => {
    dispatch(removeFilterAction(filter))
  }

  useEffect(() => {
    getProject()
  }, [getProject])

  useEffect(() => {
    if (error) {
      navigate('/login')
    }
  }, [error, history])

  useEffect(() => {
    if (!data?.project) {
      return
    }
    const rawStudies = data.project.study ?? []
    const allOwnData = data.project.ownData ?? []
    const studies: StudyDefaultState = {}
    const ownDatas: StudyDefaultState = {}

    allOwnData.forEach((ownDataItem) => {
      ownDatas[ownDataItem.identifier] = {
        indexes: ownDataItem.data.map((index) => index.identifier),
        selectedIndexes: [],
        allowExport:
          ownDataItem.allowExport != null ? ownDataItem.allowExport : true
      }
    })

    rawStudies.forEach((study) => {
      studies[study.identifier] = {
        indexes: study.data.map((index) => index.identifier),
        selectedIndexes: [],
        allowExport: study.allowExport != null ? study.allowExport : true
      }
    })

    dispatch(updateAvailableStudies({ study: studies }))
    dispatch(updateAvailableOwnData({ ownData: ownDatas }))
  }, [data, dispatch])

  if (!projectId) {
    return <h3>No such project found</h3>
  }

  return (
    <PageLayout>
      <Modal open={modalOpen}>
        <div>
          {modalOpen && modalPage === ModalPages.About ? <About /> : null}
          {modalOpen && modalPage === ModalPages.MySettings ? (
            <MySettings />
          ) : null}
          {modalOpen && modalPage === ModalPages.AreaSelection ? (
            <AreaSelection projectData={data} />
          ) : null}
        </div>
      </Modal>
      <MapTableArea>
        {mode === FilterStateMode.Export ? (
          <ExportMain />
        ) : (
          // @ts-ignore
          <InsightMap
            onSelectArea={addFilter}
            mapPosition={mapPosition}
            zoomLevel={zoomLevel}
            resetMapFilters={resetMapFilters}
            onDeselectArea={removeFilter}
            filters={filters}
            saveMapState={saveMapState}
            mode={mode}
            mapTheme={mapTheme as keyof MapTheme}
            administrativeDivisions={administrativeDivisions}
            updateMapAndZoneData={updateMapAndZoneData}
          />
        )}
      </MapTableArea>

      <SidebarDiv>
        <Sidebar projectId={projectId} />
      </SidebarDiv>
      {/* @ts-ignore */}
      <Popover parentRef={popoverTargetRef}>{popoverContent}</Popover>
    </PageLayout>
  )
}
