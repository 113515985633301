import { useEffect } from 'react'

import gql from 'graphql-tag'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import Background from '../../layout/Background'
import { UserMenu } from '../../layout/UserMenu'
import { useQuery } from 'react-apollo'
import { ProjectCard } from './ProjectCard'
import { Query } from 'generated-types'
import { useNavigate } from 'react-router-dom'

import { colors } from '../../styles/design'

const CustomerDiv = styled.div``

const StyledCustomerTitle = {
  color: colors.turquoise[300],
  padding: '2em 0em 0.5em 0em',
  fontWeight: 'bold'
}

const CardArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
`

const StyledUserMenu = styled.div`
  display: flex;
  justify-content: flex-end;
`

const query = gql`
  query {
    customers(filter: { perPage: 1000 }) {
      total
      data {
        id
        name
        description
        active
        two_factor_authentication
        projects {
          data {
            id
            name
            active
            description
            studyIds
            ownDataIds
            questionStudyIds
            public
          }
        }
      }
    }
  }
`

export function LandingPage(): JSX.Element | null {
  const navigate = useNavigate()
  const { data, loading } = useQuery<Query>(query)

  useEffect(() => {
    const projectUrl = sessionStorage.getItem('selected-project')
    if (projectUrl) {
      sessionStorage.removeItem('selected-project')
      navigate(projectUrl)
    }
  }, [history])

  if (loading || !data) {
    return null
  }

  if (data) {
    return (
      <Background>
        <StyledUserMenu>
          <UserMenu />
        </StyledUserMenu>
        {data.customers.data.map((customer) => {
          return (
            <CustomerDiv key={customer.id}>
              <Typography variant="h2" sx={StyledCustomerTitle}>
                {customer.name}
              </Typography>
              <CardArea>
                {customer.projects.data.map((project) => {
                  return <ProjectCard key={project.id} project={project} />
                })}
              </CardArea>
            </CustomerDiv>
          )
        })}
      </Background>
    )
  }
  return null
}
