import { Feature } from 'geojson'
import { FilterType } from '../redux/filter'
import { AreaType } from './graphqlServiceTypes'

export function filterTypeForFeature(feature: Feature): FilterType {
  if (
    feature.properties &&
    feature.properties.areaType === AreaType.PostalCode
  ) {
    return FilterType.MapAreaPostalCode
  }
  if (
    feature.properties &&
    feature.properties.areaType === AreaType.Municipality
  ) {
    return FilterType.MapAreaMunicipality
  }
  if (feature.properties && feature.properties.areaType === AreaType.Region) {
    return FilterType.MapAreaRegion
  }

  console.error('Unknown feature type', feature)
  return FilterType.MapAreaRegion
}

export function areaTypeForFilterType(filterType: FilterType): AreaType {
  if (filterType === FilterType.MapAreaPostalCode) {
    return AreaType.PostalCode
  }
  if (filterType === FilterType.MapAreaMunicipality) {
    return AreaType.Municipality
  }
  if (filterType === FilterType.MapAreaRegion) {
    return AreaType.Region
  }

  console.error('Unknown filter type', filterType)
  return AreaType.Region
}
