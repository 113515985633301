import TextField from '@mui/material/TextField'
import { useForm, Controller } from 'react-hook-form'
import { sendPasswordResetLink } from 'src/graphql/mutations'
import { useMutation } from '@apollo/react-hooks'
import { useNavigate, Link } from 'react-router-dom'
import { LoginHeader, useStyles } from './parts/LoginHeader'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { sendNewNotification } from 'src/redux/notification'
import { SendButton } from './parts/SendButton'
import Button from '@mui/material/Button'

function RequestNewPassword(): JSX.Element {
  const { classes } = useStyles()
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: ''
    }
  })

  const [requestNewPassword] = useMutation(sendPasswordResetLink, {
    onError: () => {
      setError('Sähköposti on virheellinen!')
    },
    update: (_cache: any, { data: { resetPasswordLink } }) => {
      const { status } = resetPasswordLink
      if (status === 'ok') {
        dispatch(
          sendNewNotification({
            message:
              'Jos sähköpostiosoitteesi löytyy järjestelmästä, saat pian sähköpostiviestin, jonka kautta voit uusia salasanasi."'
          })
        )
        navigate('/login')
      }
    }
  })

  const onSubmit = (variables: any) => {
    requestNewPassword({ variables: { ...variables } })
  }

  return (
    <>
      <form
        className={classes.form}
        onChange={() => {
          setError('')
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              type="email"
              onChange={onChange}
              value={value}
              fullWidth
              label={'Sähköpostiosoite'}
              variant="standard"
              error={!!error}
            />
          )}
        />
        <SendButton buttonTitle="Pyydä uusi salasana" error={error} />
        <Button
          variant="outlined"
          style={{ marginTop: '0.5em' }}
          type="submit"
          component={Link}
          to={`/login`}
        >
          Takaisin
        </Button>
      </form>
    </>
  )
}

export function RequestNewPasswordPage(): JSX.Element {
  return (
    <LoginHeader
      title="Uusi salasana"
      FormArea={<RequestNewPassword />}
    ></LoginHeader>
  )
}
