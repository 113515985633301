import { ReactNode } from 'react'
import { Action } from 'redux'
import actionCreatorFactory, { isType } from 'typescript-fsa'

const actionCreator = actionCreatorFactory()

export type OpenPopoverProps = {
  popoverTargetRef: HTMLElement
  popoverContent: ReactNode
}

export const openPopover = actionCreator<OpenPopoverProps>('OPEN_POPOVER')
export const closePopover = actionCreator<void>('CLOSE_POPOVER')

export type UIState = {
  popoverTargetRef?: HTMLElement
  popoverContent?: ReactNode
}

const defaultState: UIState = {
  popoverTargetRef: undefined,
  popoverContent: undefined
}

const reducer = (state: UIState = defaultState, action: Action): UIState => {
  if (isType(action, openPopover)) {
    const {
      payload: { popoverTargetRef, popoverContent }
    } = action
    const newState = { ...state }
    newState.popoverContent = popoverContent
    newState.popoverTargetRef = popoverTargetRef
    return newState
  }

  if (isType(action, closePopover)) {
    const newState = { ...state }
    newState.popoverContent = undefined
    newState.popoverTargetRef = undefined
    return newState
  }

  return state
}

export default reducer
