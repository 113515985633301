export const populationDataCategories = [
  'gender',
  'age',
  'majority',
  'education',
  'map'
]
export const generalCategories = ['gender', 'age', 'majority', 'education']
export const householdCategories = ['household', 'housing']
export const employmentCategories = ['incomeClass', 'employment', 'jobs']
