import React from 'react'
import { Typography, Switch, Checkbox } from '@mui/material'
import FormGroup from '@mui/material/FormGroup'
import { useDispatch } from 'react-redux'
import FormControlLabel from '@mui/material/FormControlLabel'
import styled from '@mui/styled-engine'
import { Column, Row, ExportSettings } from './ExportMain'
import {
  ExportSettingsStore,
  saveExportSettings,
  togglePostalCodeColumn
} from 'src/redux/exportSettings'
import { ExportDatagrid } from './ExportDatagrid'

import { ExportFilter } from '../../redux/export/exportModelFilter'

const TableArea = styled('div')({
  padding: '3em',
  height: '86vh'
})

const SwitchArea = styled('div')({
  marginTop: '1.5em',
  padding: '2em 0em 1.5em 0em'
})

type Props = {
  tableError: string | null
  rows: Row[] | undefined
  columns: Column[] | undefined
  dataLoading: boolean
  exportSettings: ExportSettingsStore
  isExportAllowed: boolean
  exportFilter: ExportFilter
}

const styles = {
  panelHeader: {},
  areaToggleHeader: {
    fontSize: '20px'
  }
}

export const ExportTable: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const {
    tableError,
    rows,
    columns,
    dataLoading,
    exportSettings,
    isExportAllowed,
    exportFilter
  } = props

  function changeUserOptions(option: ExportSettings) {
    dispatch(saveExportSettings(option))
  }

  const RowOptions = (
    <FormGroup row>
      {[
        [ExportSettings.AllAreasTotalRow, 'Näytä yhteensä'],
        [ExportSettings.MunicipalitiesTotalRow, 'Näytä kuntakooste'],
        [ExportSettings.RegionTotalRow, 'Näytä maakuntakooste']
      ].map((row, index) => {
        const option = row[0] as ExportSettings
        const label = row[1]
        return (
          <FormControlLabel
            key={`row_${index}`}
            control={
              <Switch
                checked={exportSettings.settings.includes(option)}
                onChange={() => changeUserOptions(option)}
              />
            }
            label={label}
            color={index === 0 ? 'primary' : 'secondary'}
          />
        )
      })}
    </FormGroup>
  )

  const ColumnOptions = (
    <>
      {[
        [ExportSettings.MunicipalityInfo, 'Lisää kuntasarake'],
        [ExportSettings.RegionInfo, 'Lisää maakuntasarake']
      ].map((row, index) => {
        const option = row[0] as ExportSettings
        const label = row[1]
        return (
          <FormControlLabel
            key={`column_${index}`}
            control={
              <Checkbox
                checked={exportSettings.settings.includes(option)}
                onChange={() => changeUserOptions(option)}
              />
            }
            label={label}
          />
        )
      })}
    </>
  )

  return (
    <TableArea>
      <Typography variant="h1" sx={styles.panelHeader}>
        Data-näkymä
      </Typography>
      <SwitchArea>
        <Typography sx={styles.areaToggleHeader}>Taulukon asetukset</Typography>
        {RowOptions}
        <FormGroup row>
          {ColumnOptions}
          <FormControlLabel
            control={
              <Checkbox
                checked={exportSettings.postalCodeColumn}
                onChange={() => dispatch(togglePostalCodeColumn())}
              />
            }
            label={'Lisää postinumerosarake'}
          />
        </FormGroup>
      </SwitchArea>

      <ExportDatagrid
        rows={rows}
        columns={columns}
        dataLoading={dataLoading}
        tableError={tableError}
        isExportAllowed={isExportAllowed}
        exportFilter={exportFilter}
      />
    </TableArea>
  )
}
