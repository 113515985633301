import React, { FunctionComponent } from 'react'

import { QuestionOutput } from '../../../../services/graphqlServiceTypes'
import NumberIntervalQuestion from './NumberIntervalQuestion'
import TwoOptionsQuestion from './TwoOptionsQuestion'
import NumberIntervalWithRespondentIdQuestion from './NumberIntervalWithRespondentIdQuestion'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'

type QuestionTypeProps = {
  question: QuestionOutput
}

export type CustomDataSegment = {
  id: string
  name: string
}

const Question: FunctionComponent<QuestionTypeProps> = ({ question }) => {
  const { type, studyId } = question
  const { data } = useQuery<{
    study: { segments: CustomDataSegment[] }
  }>(gql`
    query {
      study (id: "${studyId}") {
        segments {
          id
          name
        }
      }
    }
  `)

  const segments = data?.study.segments

  if (!segments) {
    return null
  }

  switch (type) {
    case 'number_interval':
      return (
        <NumberIntervalQuestion question={question} segmentNames={segments} />
      )
    case 'two_options':
      return <TwoOptionsQuestion question={question} segmentNames={segments} />
    case 'number_interval_with_respondent_id':
      return (
        <NumberIntervalWithRespondentIdQuestion
          question={question}
          segmentNames={segments}
        />
      )
    default:
      return null
  }
}

export default React.memo(Question)
