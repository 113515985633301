import { createTheme } from '@mui/material/styles'
import { fiFI } from '@mui/material/locale'

import { colors } from '../styles/design'

const defaultTitleStyles = {
  color: colors.turquoise[300],
  fontWeight: 700
}

export const appTheme = createTheme(
  {
    /* Better to use the palette to set colours. Then you don't need to manually override stuff.
     * We can add custom colours for secondary, error, etc:
     *
     * https://mui.com/material-ui/customization/palette/
     * */
    palette: {
      primary: {
        main: colors.turquoise[300]
      }
    },
    typography: {
      h1: {
        ...defaultTitleStyles,
        fontSize: '2rem'
      },
      h2: {
        ...defaultTitleStyles,
        fontSize: '1.5rem'
      },
      h3: {
        ...defaultTitleStyles,
        fontSize: '1.25rem'
      },
      h4: {
        ...defaultTitleStyles,
        fontSize: '1rem'
      },
      h5: {
        ...defaultTitleStyles,
        fontSize: '1rem'
      },
      h6: {
        ...defaultTitleStyles,
        fontSize: '1rem'
      },
      fontFamily:
        '"Source Sans Pro", din-2014, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, ' +
        'Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            justifyContent: 'center',
            borderRadius: '9999px'
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&$expanded': {
              margin: 0
            }
          }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            minHeight: 'auto'
          },
          content: {
            margin: 0,
            '&$expanded': {
              margin: 0
            }
          }
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            display: 'block',
            padding: 0
          }
        }
      },
      MuiCardHeader: {
        styleOverrides: {
          title: {
            fontSize: '1rem'
          }
        }
      },
      MuiGrid: {
        styleOverrides: {}
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            '.Mui-checked.Mui-checked + &': {
              backgroundColor: colors.turquoise[200]
            }
          }
        }
      }
    }
  },
  fiFI
)
