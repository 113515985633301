export const colors = {
  accent1: '#1976d2',
  accent2: '#2EA44F',
  brandRed: '#E60F28',
  backgroundGrey: '#4f5a62',
  darkGrey: '#3A4A4E',
  black: '#1F1F1FFF',
  /* turquoise: "#2A6471", */
  blue: {
    300: 'hsl(191deg, 45%, 52%)' // #4DA7BC
  },
  turquoise: {
    // 100: "#E9EFF1", // // Web AIM AAA compliant (contrast ratio 7:1 or more)
    100: 'hsl(195deg, 22%, 93%)',
    150: 'hsl(192deg, 22%, 86%)',
    200: 'hsl(190deg, 41%, 36%)',
    300: 'hsl(190deg, 41%, 26%)' // "#27545D"
  },
  white: '#FFFFFF'
}

export const texts = {
  medium: {
    fontSize: '1.1em',
    fontFamily: '"Source Sans Pro", din-2014, Helvetica, Arial, sans-serif',
    letterSpacing: '0.07em',
    fontWeight: 500
  }
}
