import React, { FunctionComponent } from 'react'
import CountUp from 'react-countup'
import { formatNumber } from 'src/utils/numeral'

type Props = {
  value: number
  comparison: number
}

const SegmentIndex: FunctionComponent<Props> = ({ value, comparison }) => {
  const index = getRatio(value, comparison)
  return (
    <span style={{}}>
      {/* @ts-ignore */}
      <CountUp
        preserveValue={true}
        end={Math.floor(100 * index)}
        formattingFn={formatNumber}
      ></CountUp>
    </span>
  )
}

export function getRatio(value: number, comparison: number): number {
  // Guard division by zero
  if (comparison < 0.000001) {
    return 0
  }

  return value / comparison
}

export default SegmentIndex
