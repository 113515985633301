import { FilterType } from './filter'

export type TableData = {
  id: string
  label: string
  selection: { value: number; percentage: number }
  nationWide: { value: number; percentage: number }
  checked: boolean
}

export enum TableType {
  PopulationInfo,
  Study,
  OwnData
}

export function getFilterTypeForTable(tableType: TableType): FilterType {
  switch (tableType) {
    case TableType.PopulationInfo:
      return FilterType.PopulationInfoCategory
    case TableType.Study:
      return FilterType.StudySegment
    case TableType.OwnData:
      return FilterType.OwnDataSegment
  }
}
