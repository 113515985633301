import React, { FunctionComponent } from 'react'

import { TableType } from 'src/redux/TableData'
import { populationInfoQuery } from 'src/graphql/queries'
import { useQueryWithSmoothDataChange } from 'src/graphql/hooks'
import { createQueryFilters } from 'src/utils/filter'
import InsightData from '../panel-data/InsightData'
import { useFilter } from 'src/redux'

type Props = {
  panelName: string
}

const Population: FunctionComponent<Props> = (props) => {
  const { panelName } = props
  const { filters } = useFilter()
  const filterData = createQueryFilters(filters)

  const { data: populationNationWideData } = useQueryWithSmoothDataChange(
    populationInfoQuery,
    {
      variables: { filter: {} }
    }
  )
  const { data: populationFilteredData } = useQueryWithSmoothDataChange(
    populationInfoQuery,
    {
      variables: { filter: filterData }
    }
  )

  return (
    <InsightData
      data={populationFilteredData && populationFilteredData.populationInfo}
      nationWideData={
        populationNationWideData && populationNationWideData.populationInfo
      }
      tableType={TableType.PopulationInfo}
      panelName={panelName}
      resettableKeys={[]}
    />
  )
}

export default React.memo(Population)
