import { AreaSelection } from 'generated-types'
import { Map, Record, Set } from 'immutable'
import actionCreatorFactory, { Action, isType } from 'typescript-fsa'
import { populationDataCategories } from '../../utils/categories'
import { getAllPostalsFromAreaSelection } from './area-selection'
import {
  getEquivalentAdministrativeDivisions,
  getPostalCodesForDivisionCode
} from './areaFind'

const actionCreator = actionCreatorFactory()

export enum FilterType {
  PopulationInfoCategory,
  StudySegment,
  OwnDataSegment,
  RespondentIdStudy,
  MapAreaPostalCode,
  MapAreaMunicipality,
  MapAreaRegion
}

export type FilterSpec = {
  filterName: string
  filterValue: any
  filterLabel: string
  filterType: FilterType
  questionId?: string
  answerRange?: number[]
  mode?: FilterStateMode
}

export type LabelSpec = {
  filterName: string
  labelKey: string
  labelName: string
}

type ModeUpdate = {
  mode: FilterStateMode
}

export const addFilter = actionCreator<FilterSpec>('ADD_FILTER')
export const removeFilter = actionCreator<FilterSpec>('REMOVE_FILTER')
export const doRemoveFilter = actionCreator<FilterSpec>('DO_REMOVE_FILTER')
export const doRemoveMapFilter = actionCreator<FilterSpec>(
  'DO_REMOVE_MAP_FILTER'
)
export const resetMapFilters = actionCreator('RESET_MAP_FILTERS')
export const doResetMapFilters = actionCreator('DO_RESET_MAP_FILTERS')

export const replaceMapFilter = actionCreator<FilterSpec>('REPLACE_MAP_FILTER')
export const updateFilter = actionCreator<FilterSpec>('UPDATE_FILTER')
export const updateMapFilter = actionCreator<FilterSpec>('UPDATE_MAP_FILTER')

export const setCustomAreaSelection = actionCreator<AreaSelection>(
  'SET_CUSTOM_AREA_SELECTION'
)

export const addLabel = actionCreator<LabelSpec>('ADD_LABEL')
export const doResetMapLabels = actionCreator('DO_RESET_MAP_LABELS')

export const changeUIMode = actionCreator<ModeUpdate>('CHANGE_UI_MODE')

export const updateMapAndZoneData = actionCreator('UPDATE_MAP_AND_ZONE_DATA')

export type FilterStateFilters = Map<string, FilterSpec>
export type FilterStateLabels = Map<string, Map<string, string>>
export enum FilterStateMode {
  Population,
  Index,
  Export
}
export type AdministrativeDivisions = {
  region: Set<string>
  municipality: Set<string>
}

export type FilterState = Record<{
  filters: FilterStateFilters
  labels: FilterStateLabels
  mode: FilterStateMode
  administrativeDivisions: AdministrativeDivisions
}>

const defaultState: FilterState = Record({
  filters: Map<string, FilterSpec>(),
  labels: Map<string, Map<string, string>>(),
  mode: FilterStateMode.Population,
  administrativeDivisions: {
    region: Set<string>(),
    municipality: Set<string>()
  }
})()

function getMapFilterValue(state: FilterState): Set<string> {
  // @ts-ignore
  return state.getIn(['filters', 'map', 'filterValue'])
}

export default function reducer<T extends {}>(
  state: FilterState = defaultState,
  action: Action<T>
): FilterState {
  if (isType(action, changeUIMode)) {
    const {
      payload: { mode }
    } = action
    if (mode || mode === 0) {
      return state.set('mode', mode)
    }
  }

  if (isType(action, doResetMapLabels)) {
    return state.setIn(['labels', 'map'], Map<string, string>())
  }

  if (isType(action, addLabel)) {
    return state.updateIn(
      ['labels', action.payload.filterName, `${action.payload.labelKey}`],
      () => action.payload.labelName
    )
  }

  if (isType(action, doRemoveFilter)) {
    return state.removeIn([
      'filters',
      state
        .get('filters')
        .findKey((m) => m.filterName === action.payload.filterName)
    ])
  }

  if (isType(action, doRemoveMapFilter)) {
    state = state.setIn(
      ['filters', 'map', 'filterValue'],
      Set(
        getMapFilterValue(state).subtract(
          getPostalCodesForDivisionCode(action.payload.filterValue)
        )
      )
    )
    state = state.setIn(
      ['administrativeDivisions'],
      getEquivalentAdministrativeDivisions(getMapFilterValue(state))
    )

    const mapFilters = getMapFilterValue(state)

    if (action.payload.filterValue === null || mapFilters?.count() === 0) {
      state = state.removeIn(['filters', 'map'])
    }

    return state
  }

  if (
    isType(action, updateFilter) &&
    state.get('mode') === FilterStateMode.Population &&
    populationDataCategories.includes(action.payload.filterName)
  ) {
    return state
      .set(
        'filters',
        state
          .get('filters')
          .filter((f) => populationDataCategories.includes(f.filterName))
      )
      .updateIn(['filters', action.payload.filterName], () => action.payload)
  }

  if (isType(action, updateFilter)) {
    const mapFilters = state.get('filters').get('map')
    if (state.get('mode') === FilterStateMode.Population) {
      state = state.set('filters', defaultState.get('filters'))
    }
    if (mapFilters) {
      state = state.setIn(['filters', 'map'], mapFilters)
    }
    if (state.get('filters').has(action.payload.filterName)) {
      return state.updateIn(
        ['filters', action.payload.filterName],
        () => action.payload
      )
    } else {
      return state.setIn(['filters', action.payload.filterName], action.payload)
    }
  }

  if (isType(action, setCustomAreaSelection)) {
    const filters = state.get('filters')
    const { payload } = action

    if (!filters) {
      return state
    }

    const allPostals = getAllPostalsFromAreaSelection(payload)

    state = state.setIn(['filters', 'map'], {
      filterName: 'map',
      filterValue: Set(allPostals),
      filterLabel: name,
      filterType: FilterType.MapAreaPostalCode
    })

    return state.setIn(
      ['administrativeDivisions'],
      getEquivalentAdministrativeDivisions(getMapFilterValue(state))
    )
  }

  if (isType(action, updateMapFilter)) {
    let payload = null
    const filters = state.get('filters')

    if (!filters) {
      return state
    }

    if (!state.get('filters').has('map')) {
      payload = {
        filterName: action.payload.filterName,
        filterValue: Set([]),
        filterLabel: action.payload.filterLabel,
        filterType: action.payload.filterType
      }

      state = state.setIn(['filters', 'map'], payload)
    }

    const mapFilters = state.get('filters').get('map')

    if (!mapFilters || !mapFilters.filterValue) {
      return state
    }

    payload = {
      filterName: action.payload.filterName,
      filterValue: Set(
        mapFilters.filterValue.concat(
          getPostalCodesForDivisionCode(action.payload.filterValue)
        )
      ),
      filterLabel: action.payload.filterLabel,
      filterType: action.payload.filterType
    }

    state = state.setIn(['filters', 'map'], payload)
    return state.setIn(
      ['administrativeDivisions'],
      getEquivalentAdministrativeDivisions(getMapFilterValue(state))
    )
  }

  if (isType(action, replaceMapFilter)) {
    let payload = null
    const filters = state.get('filters')

    if (!filters) {
      return state
    }

    if (!state.get('filters').has('map')) {
      payload = {
        filterName: action.payload.filterName,
        filterValue: Set([]),
        filterLabel: action.payload.filterLabel,
        filterType: action.payload.filterType
      }

      state = state.setIn(['filters', 'map'], payload)
    }

    const mapFilters = state.get('filters').get('map')

    if (!mapFilters || !mapFilters.filterValue) {
      return state
    }

    payload = {
      filterName: action.payload.filterName,
      filterValue: Set(
        mapFilters.filterValue.concat(
          getPostalCodesForDivisionCode(action.payload.filterValue)
        )
      ),
      filterLabel: action.payload.filterLabel,
      filterType: action.payload.filterType
    }

    state = state.setIn(['filters', 'map'], payload)

    return state.setIn(
      ['administrativeDivisions'],
      getEquivalentAdministrativeDivisions(getMapFilterValue(state))
    )
  }

  if (isType(action, doResetMapFilters)) {
    return state
      .setIn(['filters', 'map'], {
        filterName: 'map',
        filterValue: Set([]),
        filterType: null
      })
      .setIn(['administrativeDivisions'], {
        region: Set(),
        municipality: Set()
      })
  }

  return state
}
