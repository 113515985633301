import React, { PureComponent } from 'react'

import ApolloClient from 'apollo-client'
import { FeatureCollection } from 'geojson'
import { List, Map as ImmutableMap } from 'immutable'
import {
  LatLng,
  Layer,
  LeafletEvent,
  Map as LeafletMap,
  Path,
  PathOptions
} from 'leaflet'
import 'leaflet/dist/leaflet.css'
import Control from 'react-leaflet-control'
import { withApollo } from 'react-apollo'
import { GeoJSON, Map, TileLayer } from 'react-leaflet'
import styled from '@emotion/styled'
import { getNameForArea } from 'src/utils/area-functions'

import { ZoneData } from 'generated-types'

import {
  AdministrativeDivisions,
  FilterSpec,
  FilterStateFilters,
  FilterStateMode,
  FilterType,
  resetMapFilters,
  updateMapAndZoneData
} from 'src/redux/filter'
import {
  AreaType,
  Coordinates,
  MapFilter,
  ZoneFeature
} from 'src/services/graphqlServiceTypes'
import { filterTypeForFeature } from 'src/services/graphqlServiceUtils'
import {
  ColorRangeList,
  ColorTheme,
  createColorRanges,
  getColorForValue,
  MapTheme,
  mapThemes
} from './colors'
import { MapLegend } from './MapLegend'
import { config } from './config'
import {
  MapQueryOutput,
  postalCodeQuery,
  PostalCodeQueryOutput,
  zoneQuery,
  ZoneQueryOutput
} from './query'

const ZOOM_LEVEL_POSTAL_CODE = 10
const ZOOM_LEVEL_MUNICIPALITY = 7

const InsightMapDiv = styled.div`
  .leaflet-container {
    height: 100vh;
  }

  path {
    transition: fill 0.15s linear;
  }
`

type ZoneDataMap = ImmutableMap<string, ZoneData>

type MapGranularity = 'postalCode' | 'municipality' | 'region'

type InsightMapProps = {
  client: ApolloClient<any>
  filters: FilterStateFilters
  mode: FilterStateMode
  onDeselectArea: (filterSpec: FilterSpec) => void
  onSelectArea: (filterSpec: FilterSpec) => void
  saveMapState: (position: LatLng, zoomLevel: number) => void
  resetMapFilters: typeof resetMapFilters
  mapPosition: Coordinates
  zoomLevel: number
  updateMapAndZoneData: typeof updateMapAndZoneData
  administrativeDivisions: AdministrativeDivisions
  mapTheme: keyof MapTheme
}

type InsightMapState = {
  colorRanges: ColorRangeList
  mapData: FeatureCollection
  zoneData: ZoneDataMap
  selectedType: FilterType | null
  mode: FilterStateMode
}

export class InsightMap extends PureComponent<
  InsightMapProps,
  InsightMapState
> {
  public readonly state: InsightMapState = {
    colorRanges: List(),
    mapData: { type: 'FeatureCollection', features: [] },
    zoneData: ImmutableMap(),
    selectedType: null,
    mode: FilterStateMode.Population
  }

  public componentDidMount() {
    this.queryMapAndZoneData()
  }

  leafletMap = null
  setLeafletMapRef = (map: any) => (this.leafletMap = map && map.leafletElement)

  public componentDidUpdate(previousProps: InsightMapProps) {
    if (
      this.props.filters === previousProps.filters &&
      this.props.mode === previousProps.mode &&
      this.props.mapTheme === previousProps.mapTheme
    ) {
      return
    }

    // Clear color ranges if map filters were cleared
    if (!this.props.filters.has('map') && previousProps.filters.has('map')) {
      this.setState(() => ({
        colorRanges: List()
      }))
    }

    this.queryMapAndZoneData()
  }

  public render() {
    const { mapData } = this.state
    const geoJsonKey = Math.random()

    return (
      <InsightMapDiv>
        <Map
          ref={this.setLeafletMapRef}
          center={this.props.mapPosition}
          maxBounds={config.maxBounds}
          maxBoundsViscosity={0.8}
          onMoveEnd={this.handleMoveEvent}
          zoom={this.props.zoomLevel}
          maxZoom={14}
          minZoom={5}
          wheelPxPerZoomLevel={150}
          zoomDelta={1}
          zoomSnap={1}
        >
          <TileLayer
            url={config.tileSource}
            attribution={config.tileAttribution}
          />
          <GeoJSON
            data={mapData}
            key={geoJsonKey}
            onEachFeature={this.onEachFeature}
            style={this.getFeatureStyle}
          />
          {/* @ts-ignore */}
          <Control position="bottomright">
            <MapLegend mapTheme={this.props.mapTheme} />
          </Control>
        </Map>
      </InsightMapDiv>
    )
  }

  private highlightFeature(event: LeafletEvent, theme: keyof MapTheme) {
    const highlightLayer: Path = event.target
    const newTheme: ColorTheme = mapThemes[theme]
    const highlightColor = newTheme.highlightColor
    highlightLayer.setStyle({
      fillColor: `rgba(${highlightColor.red},${highlightColor.green},${highlightColor.blue}, ${highlightColor.opacity})`,
      weight: 2,
      opacity: 1
    })
  }

  private resetFeature(event: LeafletEvent, feature: ZoneFeature) {
    const highlightLayer: Path = event.target

    highlightLayer.setStyle(this.getFeatureStyle(feature))
  }

  private onEachFeature = (feature: ZoneFeature, layer: Layer) => {
    layer.on({
      click: () => this.handleFeatureClick(feature),
      mouseover: (event) => this.highlightFeature(event, this.props.mapTheme),
      mouseout: (event) => this.resetFeature(event, feature)
    })
  }

  private handleFeatureClick = (feature: ZoneFeature) => {
    performance.mark('start-handleFeatureClick')

    const { onSelectArea, onDeselectArea } = this.props

    const filterType = filterTypeForFeature(feature)

    if (filterType !== this.state.selectedType) {
      this.setState(() => ({
        selectedType: filterType
      }))

      resetMapFilters()
    }

    if (!feature.properties) {
      return
    }

    const { identifier } = feature.properties

    if (this.isAreaSelected(identifier)) {
      onDeselectArea({
        filterName: 'map',
        filterValue: identifier,
        filterLabel: '',
        filterType
      })
    } else {
      onSelectArea({
        filterName: 'map',
        filterValue: identifier,
        filterLabel: getNameForArea(identifier),
        filterType
      })
    }

    performance.mark('end-handleFeatureClick')
    performance.measure(
      'handleFeatureClick',
      'start-handleFeatureClick',
      'end-handleFeatureClick'
    )
  }

  private getSelectedAreas(): Array<string> | null {
    const { filters, administrativeDivisions } = this.props
    const mapFilters = filters.get('map')
    return mapFilters
      ? [
          ...mapFilters.filterValue,
          ...administrativeDivisions.municipality.toArray(),
          ...administrativeDivisions.region.toArray()
        ]
      : null
  }

  private getSelectedAreaCount(): number {
    const selectedAreas = this.getSelectedAreas()
    return selectedAreas ? selectedAreas.length : 0
  }

  private isAreaSelected(identifier: string | null): boolean {
    const selectedAreas = this.getSelectedAreas()
    return selectedAreas
      ? identifier
        ? selectedAreas.includes(identifier)
        : false
      : false
  }

  private handleMoveEvent = () => {
    this.queryMapAndZoneData()
  }

  private getQueryFilter(areaType: AreaType): MapFilter {
    const filterData: { [key: string]: FilterSpec } =
      this.props.filters.toJSON()
    const filter: MapFilter = { area: { areaType } }

    if (filterData.gender) {
      filter.gender = filterData.gender.filterValue
    }
    if (filterData.education) {
      filter.education = filterData.education.filterValue
    }
    if (filterData.age) {
      filter.age = filterData.age.filterValue
    }
    if (filterData.incomeClass) {
      filter.incomeClass = filterData.incomeClass.filterValue
    }
    if (filterData.housing) {
      filter.housing = filterData.housing.filterValue
    }
    if (filterData.employment) {
      filter.employment = filterData.employment.filterValue
    }
    if (filterData.jobs) {
      filter.jobs = filterData.jobs.filterValue
    }
    if (filterData.household) {
      filter.household = filterData.household.filterValue
    }

    Object.entries(filterData).forEach(([key, value]) => {
      if (value.filterType === FilterType.StudySegment) {
        filter.study = {
          id: key,
          segmentId: value.filterValue
        }
      }
      if (value.filterType === FilterType.OwnDataSegment) {
        filter.ownData = {
          id: key,
          segmentId: value.filterValue
        }
      }
    })

    return filter
  }

  private async queryMapAndZoneData() {
    performance.mark('start-queryMapAndZoneData')
    const { client, updateMapAndZoneData, mapTheme } = this.props
    updateMapAndZoneData() // dummy action to reset logout timer

    function zoneDataToMap(zoneData: ZoneData[]): ZoneDataMap {
      return ImmutableMap(
        zoneData.map((item): [string, ZoneData] => [item.id, item])
      )
    }

    // @ts-ignore
    const map: LeafletMap = this.leafletMap
    const zoom = map.getZoom()
    const location = map.getCenter()
    this.props.saveMapState(location, zoom)

    let result: MapQueryOutput
    let granularity = this.getMapGranularity(zoom)

    if (granularity === 'postalCode') {
      const bounds = map.getBounds()
      const filter = this.getQueryFilter(AreaType.PostalCode)
      performance.mark('start-queryPostalCodes')
      const { data } = await client.query<PostalCodeQueryOutput>({
        query: postalCodeQuery,
        fetchPolicy: 'network-only',
        variables: {
          boundingBox: {
            minLat: bounds.getSouth(),
            minLng: bounds.getWest(),
            maxLat: bounds.getNorth(),
            maxLng: bounds.getEast()
          },
          filter
        }
      })
      result = data.postalCodeDataByBbox
      performance.mark('end-queryPostalCodes')
      performance.measure(
        'queryPostalCodes',
        'start-queryPostalCodes',
        'end-queryPostalCodes'
      )
    } else {
      const areaType =
        granularity === 'municipality' ? AreaType.Municipality : AreaType.Region
      const filter = this.getQueryFilter(areaType)

      const { data } = await client.query<ZoneQueryOutput>({
        query: zoneQuery,
        fetchPolicy: 'network-only',
        variables: {
          areaType,
          filter
        }
      })
      result = data
    }

    const zoneDataForColorRanges =
      this.getSelectedAreaCount() === 0
        ? result.zoneData
        : result.zoneData.filter((data) => this.isAreaSelected(data.id))

    this.setState(() => ({
      mapData: result.GeoJSONFeatureCollection,
      zoneData: zoneDataToMap(result.zoneData),
      colorRanges: createColorRanges(
        zoneDataForColorRanges,
        this.props.mode,
        mapTheme
      ),
      mode: this.props.mode
    }))
    performance.mark('end-queryMapAndZoneData')
    performance.measure(
      'queryMapAndZoneData',
      'start-queryMapAndZoneData',
      'end-queryMapAndZoneData'
    )
  }

  private getMapGranularity = (zoom: number): MapGranularity => {
    const filteredPostalCodes = this.props.filters.getIn(['map', 'filterValue'])
    const administrativeDivisions = this.props.administrativeDivisions

    if (
      administrativeDivisions.region.size > 0 ||
      !filteredPostalCodes ||
      // @ts-ignore
      filteredPostalCodes.length === 0
    ) {
      if (zoom >= ZOOM_LEVEL_POSTAL_CODE) return 'postalCode'
      if (zoom >= ZOOM_LEVEL_MUNICIPALITY) return 'municipality'
      return 'region'
    } else {
      if (administrativeDivisions.municipality.size === 0) return 'postalCode'
      if (administrativeDivisions.municipality.size > 0) {
        if (zoom >= ZOOM_LEVEL_POSTAL_CODE) return 'postalCode'
        return 'municipality'
      }
    }

    return 'postalCode'
  }

  private getFeatureStyle = (feature?: ZoneFeature): PathOptions => {
    if (!feature) {
      return {}
    }

    return {
      fillColor: this.getFillColor(feature),
      weight: 1,
      opacity: 0.5,
      color: '#333',
      fillOpacity: 1
    }
  }

  private getFillColor(feature: ZoneFeature): string {
    if (
      this.getSelectedAreaCount() === 0 ||
      this.isAreaSelected(feature.properties.identifier)
    ) {
      const zoneData = this.state.zoneData.get(feature.properties.identifier)
      const mode = this.state.mode
      if (zoneData) {
        if (mode === FilterStateMode.Index) {
          return getColorForValue(
            zoneData.populationIndex,
            this.state.colorRanges
          )
        } else {
          return getColorForValue(zoneData.population, this.state.colorRanges)
        }
      }
    }
    return 'transparent'
  }
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export default withApollo<Omit<InsightMapProps, 'client'>>(InsightMap)
