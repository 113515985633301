import { AreaSelection } from 'generated-types'
import { compact, flatten } from 'lodash'
import {
  getMunicipalitiesForRegion,
  getPostalCodesForMunicipality
} from 'src/utils/area-functions'

export function getAllPostalsFromAreaSelection(area: AreaSelection): string[] {
  const { regions, postals, municipalities } = area

  const municipalitiesFromRegions = flatten(
    compact(
      regions.map((region) => {
        return getMunicipalitiesForRegion(region)
      })
    )
  )

  const postalsFromRegionsAndMuns = flatten(
    compact(
      municipalities.concat(municipalitiesFromRegions).map((region) => {
        return getPostalCodesForMunicipality(region)
      })
    )
  )

  return postalsFromRegionsAndMuns.concat(postals)
}
