import gql from 'graphql-tag'

export const exportPopulationQuery = gql`
  query exportPopulationInfo(
    $populationFields: [String]
    $areas: [String!]
    $userOptions: [UserOptions]
    $studyFields: [CustomProjectData]
    $ownDataFields: [CustomProjectData]
  ) {
    exportPopulationInfo(
      areas: $areas
      populationFields: $populationFields
      userOptions: $userOptions
      studyFields: $studyFields
      ownDataFields: $ownDataFields
    ) {
      labels {
        id
        label
      }
      areas {
        areaLabel
        areaId
        municipality
        region
        values {
          amount
          fieldID
          percentage
        }
      }
    }
  }
`
