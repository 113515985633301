import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useForm, Controller } from 'react-hook-form'
import { loginMutation } from 'src/graphql/mutations'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { storeUserState } from 'src/redux/auth'
import { Link, useNavigate } from 'react-router-dom'
import { LoginHeader, useStyles } from './parts/LoginHeader'
import { useEffect, useState } from 'react'
import { ConfirmCodeForm } from './ConfirmCodeForm'
import { sendNewNotification } from 'src/redux/notification'
import { SendButton } from './parts/SendButton'
import { useUser } from 'src/redux'

export function LoginForm(): JSX.Element {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [loginLoading, setLoginLoading] = useState(false)
  const [showConfirmCode, setShowConfirmCode] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const { role, serviceNetworkError } = useUser()
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const actionsDisabled = loginLoading || serviceNetworkError

  const [login] = useMutation(loginMutation, {
    onError: () => {
      setError('Käyttäjänimi tai salasana oli virheellinen.')
      setLoginLoading(false)
    },
    update: (_cache: any, { data: { login: loginResponse } }) => {
      const { status } = loginResponse

      const role = loginResponse?.user?.role
      setLoginLoading(false)
      if (status === 'code_sent') {
        setShowConfirmCode(true)
      } else {
        dispatch(storeUserState({ role }))
        dispatch(
          sendNewNotification({ message: 'Sisäänkirjautuminen onnistui.' })
        )
        navigate('/')
      }
    }
  })

  const onSubmit = ({
    email,
    password
  }: {
    email: string
    password: string
  }) => {
    setUserEmail(email)
    setUserPassword(password)
    setLoginLoading(true)
    login({ variables: { email, password } })
  }

  useEffect(() => {
    if (role) {
      navigate('/')
    }
  }, [role])

  if (showConfirmCode) {
    return <ConfirmCodeForm email={userEmail} password={userPassword} />
  }

  return (
    <>
      <form
        className={classes.form}
        onChange={() => {
          setError('')
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              type="email"
              onChange={onChange}
              value={value}
              fullWidth
              label={'Sähköpostiosoite'}
              variant="standard"
              error={!!error}
              disabled={actionsDisabled}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              type="password"
              onChange={onChange}
              value={value}
              fullWidth
              label={'Salasana'}
              variant="standard"
              error={!!error}
              disabled={actionsDisabled}
            />
          )}
        />
        <SendButton
          buttonTitle="Kirjaudu"
          error={error}
          loadingTitle="Kirjaudutaan"
          loading={actionsDisabled}
        />
      </form>

      <Button
        variant="text"
        disabled={actionsDisabled}
        style={{ marginTop: '0.5em' }}
        type="submit"
        component={Link}
        to={`/login/request-new-password`}
      >
        Unohdin salasanani
      </Button>
      {serviceNetworkError ? <h3>Palvelu on virhetilassa.</h3> : null}
    </>
  )
}

export function LoginFormPage(): JSX.Element {
  return (
    <LoginHeader title="Kirjaudu sisään" FormArea={<LoginForm />}></LoginHeader>
  )
}
