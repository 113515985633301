import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useForm, Controller } from 'react-hook-form'
import { newUserRegisterMutation } from 'src/graphql/mutations'
import { useMutation } from '@apollo/react-hooks'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { LoginHeader, useStyles } from './parts/LoginHeader'
import { Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { isTokenValid } from 'src/graphql/queries'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useDispatch } from 'react-redux'
import { sendNewNotification } from 'src/redux/notification'
import { SendButton } from './parts/SendButton'

const schema = z
  .object({
    firstName: z.string().nonempty({ message: 'Ei voi olla tyhjä' }),
    lastName: z.string().nonempty({ message: 'Ei voi olla tyhjä' }),
    password: z
      .string()
      .min(8, 'Salasanan on oltava vähintään 8 merkkiä pitkä.'),
    repeatPassword: z.string()
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: 'Salasanat eivät täsmää',
    path: ['repeatPassword']
  })

function NewUserForm(): JSX.Element {
  const { classes } = useStyles()
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const { token: newUserToken } = useParams<{ token: string }>()
  const dispatch = useDispatch()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      repeatPassword: ''
    },
    resolver: zodResolver(schema)
  })

  const [queryToken, { data }]: any = useLazyQuery(isTokenValid, {
    variables: {
      token: newUserToken,
      tokenType: 'newUser'
    }
  })

  useEffect(() => {
    queryToken(newUserToken)
  }, [])

  const [userRegister] = useMutation(newUserRegisterMutation, {
    onError: () => {
      setError('Tapahtui tuntematon virhe.')
    },
    update: (_cache: any, { data: { newUserRegister } }) => {
      const { status } = newUserRegister
      if (status === 'ok') {
        dispatch(
          sendNewNotification({
            message: 'Käyttäjätunnuksesi on nyt luotu. Voit kirjautua sisään.'
          })
        )
        navigate('/login')
      }
    }
  })

  const onSubmit = (variables: any) => {
    userRegister({ variables: { ...variables, token: newUserToken } })
  }

  if (!data) {
    return <div>Ladataan...</div>
  }

  if (data?.isTokenValid?.isValid === false) {
    return (
      <>
        <Typography color="primary" style={{ marginTop: '1em' }}>
          Rekisteröitymislinkki on vanhentunut tai virheellinen. Ole hyvä ja ota
          yhteyttä henkilöön, joka ylläpitää organisaationne tunnuksia.
        </Typography>
        <Button color="secondary" component={Link} to={'/login'}>
          Palaa kirjautumisruutuun
        </Button>
      </>
    )
  }

  return (
    <>
      <form
        className={classes.form}
        onChange={() => {
          setError('')
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="firstName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              fullWidth
              label={'Etunimi'}
              variant="standard"
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              fullWidth
              label={'Sukunimi'}
              variant="standard"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              type="password"
              onChange={onChange}
              value={value}
              fullWidth
              label={'Salasana'}
              variant="standard"
              error={!!errors.password}
              helperText={errors?.password?.message}
            />
          )}
        />
        <Controller
          name="repeatPassword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              type="password"
              onChange={onChange}
              value={value}
              fullWidth
              label={'Toista salasana'}
              variant="standard"
              error={!!errors.repeatPassword}
              helperText={errors?.repeatPassword?.message}
            />
          )}
        />
        <SendButton buttonTitle="Luo uusi käyttäjätili" error={error} />
      </form>
    </>
  )
}

export function NewUserPage(): JSX.Element {
  return (
    <LoginHeader
      title="Käyttäjätilin luonti"
      FormArea={<NewUserForm />}
    ></LoginHeader>
  )
}
