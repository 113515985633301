import { useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
import { useUser } from '../redux'

export function PrivateRoute(): null | JSX.Element {
  const { role } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (role === null) {
      navigate('/login')
    }
  }, [role])

  if (role === undefined) {
    return null
  }

  return <Outlet />
}
