import numeral from 'numeral'
import * as process from 'process'

import { getConfigFromEnv } from '../config'

numeral.register('locale', 'fi', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'M',
    billion: 'G',
    trillion: 'T'
  },
  ordinal() {
    return '.'
  },
  currency: {
    symbol: '€'
  }
})

numeral.locale('fi')

export default numeral

type Options = {
  showBallpark?: boolean
  ballparkLimit?: number
}

/**
 * Use this function if you want to control ballbark values using environment variables.
 */
export const formatNumberWrapper = (value: number): string => {
  const {
    app: { SHOW_BALLPARK, BALLPARK_LIMIT }
  } = getConfigFromEnv()

  return formatNumber(value, {
    showBallpark: SHOW_BALLPARK,
    ballparkLimit: BALLPARK_LIMIT
  })
}

export const formatNumber = (value: number, options?: Options): string => {
  const num = numeral(value)

  const { showBallpark = false, ballparkLimit = 10 } = options || {}

  if (showBallpark && num.value() < ballparkLimit) {
    return `< ${ballparkLimit}`
  }

  return num.format('0,0[.]00')
}
