import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router-dom'
import { StudyOwnDataAmount } from './StudyOwnDataAmount'
import { Project } from 'generated-types'

import { colors } from '../../styles/design'

import {appTheme} from "../../layout/appTheme";

const useStyles = makeStyles()(() => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '15rem',
    width: '30em',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f2f2f2'
    },
    color: colors.darkGrey,
    fontWeight: 600
  },
  indicators: {
    display: 'flex',

    padding: '0em 1em 0.5em 1.5em',
    gap: '2rem',
    justifyContent: 'space-between'
  },
  inactive: {
    fontWeight: 'bold'
  }
}))

export const ProjectCard = ({ project }: { project: Project }) => {
  const { classes } = useStyles()
  const navigate = useNavigate()

  const studyIds = project.studyIds ? project.studyIds : []
  const ownDataIds = project.ownDataIds ? project.ownDataIds : []
  const questionIds = project.questionStudyIds ? project.questionStudyIds : []

  function selectProject(project_id: string): void {
    const projectUrl = `/project/${project_id}`
    sessionStorage.setItem('selected-project', projectUrl)
    navigate(projectUrl)
  }

  return (
    <div key={project.id} onClick={() => selectProject(project.id)}>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: appTheme.palette.primary.main}} aria-label="recipe">
              {project.name.substring(0, 1)}
            </Avatar>
          }
          title={
            <Typography variant="h3" sx={{ color: colors.darkGrey }}>
              {project.name}
            </Typography>
          }
          subheader={
            project.public ? (
              <Tooltip title="Projektin voi nähdä jokainen, jolla on projektin URL-osoite.">
                <span className={classes.inactive}>Julkinen projekti</span>
              </Tooltip>
            ) : null
          }
        />

        <CardContent>
          <Typography color="textSecondary" />
          <Typography variant="body1" component="p" color="textSecondary">
            {project?.description ?? 'Ei kuvausta'}
          </Typography>
        </CardContent>

        <div className={classes.indicators}>
          <StudyOwnDataAmount ids={studyIds} infoType={'study'} />
          <StudyOwnDataAmount ids={ownDataIds} infoType={'ownData'} />
          <StudyOwnDataAmount ids={questionIds} infoType={'questionIds'} />
        </div>
      </Card>
    </div>
  )
}
