import { FunctionComponent, useState } from 'react'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import styled from '@mui/styled-engine'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate, useLocation } from 'react-router-dom'
import { logoutMutation } from '../../graphql/mutations'
import { useUser } from '../../redux/index'
import { useDispatch } from 'react-redux'
import { setModal, ModalPages } from 'src/redux/modal'
import { storeUserState } from '../../redux/auth'
import { getConfigFromEnv } from 'src/config'
import { appTheme } from './appTheme'
import { colors } from '../styles/design'

const {
  app: { ADMIN_PANEL_URL }
} = getConfigFromEnv()

const NoStyleLink = styled('a')({
  textDecoration: 'none',
  color: 'black'
})

const StyledMenuIcon = styled(MenuIcon)({
  color: appTheme.palette.primary.main,
  '&:hover': {
    color: appTheme.palette.primary.dark
  }
})

const classes = {
  withPointer: {
    cursor: 'pointer',
    padding: '10px 20px'
  }
}

export const UserMenu: FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { role } = useUser()
  const client = useApolloClient()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [logoutRequest] = useMutation(logoutMutation)
  const dispatch = useDispatch()

  const isProjectPage =
    pathname.includes('project/') || pathname.includes('public/')

  const openMenu = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    sessionStorage.removeItem('selected-project')
    dispatch(storeUserState({ role: null }))
    logoutRequest()
    closeMenu()
    client.cache.reset().then(() => {
      navigate('/login')
    })
  }

  const backToProjectSelection = () => {
    sessionStorage.removeItem('selected-project')
    navigate('/')
  }

  const openModal = (modalPage: ModalPages) => {
    dispatch(setModal({ modalOpen: true, modalPage: modalPage }))
    closeMenu()
  }

  const isAdmin = role === 'admin' || role === 'superadmin'

  return (
    <div style={classes.withPointer}>
      <StyledMenuIcon onClick={openMenu} />
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {isProjectPage && role ? (
          <MenuItem onClick={() => backToProjectSelection()}>
            Projektinvalinta
          </MenuItem>
        ) : null}
        {isProjectPage && !role ? (
          <NoStyleLink href={`/login`}>
            <MenuItem>Kirjaudu sisään</MenuItem>
          </NoStyleLink>
        ) : null}
        {isAdmin ? (
          <NoStyleLink href={`${ADMIN_PANEL_URL}`}>
            <MenuItem>Hallintapaneeli</MenuItem>
          </NoStyleLink>
        ) : null}
        {isProjectPage ? (
          <MenuItem onClick={() => openModal(ModalPages.MySettings)}>
            Asetukset
          </MenuItem>
        ) : null}
        {isProjectPage ? (
          <MenuItem onClick={() => openModal(ModalPages.About)}>
            Tietoja
          </MenuItem>
        ) : null}
        {role ? <MenuItem onClick={logout}>Kirjaudu ulos</MenuItem> : null}
      </Menu>
    </div>
  )
}
