import gql from 'graphql-tag'

import { questionFragment } from '../fragments'

export const projectQueryWithQuestions = gql`
  query Questions($id: ID!, $studyId: ID!, $filter: Filter) {
    project(id: $id) {
      id
      name
      questions(studyId: $studyId, filter: $filter) {
        ...Question
      }
    }
  }
  ${questionFragment}
`

export const projectQueryWithQuestionStudies = gql`
  query QuestionStudies($id: ID!) {
    project(id: $id) {
      id
      name
      questionStudies {
        identifier
        label
      }
    }
  }
`
