import React, { useEffect, useState } from 'react'
import { Switch, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import styled from '@emotion/styled'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useDispatch } from 'react-redux'
import { mapThemes, MapTheme } from 'src/components/map/colors'
import { changeMapTheme } from 'src/redux/modal'
import { useModal, useUser } from 'src/redux'
import { CloseModalButton, ModalBase, ModalTitle } from './common'

const FormArea = styled('div')`
  margin-top: 1em;
  gap: 1em;
`

const AdvancedSettings = styled('div')`
  margin-top: 4em;
  gap: 1.5em;
`

export const MySettings: React.FC = () => {
  const { mapTheme } = useModal()
  const [developerMode, setDeveloperMode] = useState(false)
  const dispatch = useDispatch()
  const { role } = useUser()

  const handleSettingsToggle = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    dispatch(changeMapTheme({ theme: event.target.value as keyof MapTheme }))
  }

  const toggleInsightDebug = (newDevMode: boolean) => {
    setDeveloperMode(newDevMode)
    localStorage.setItem('insightDebug', newDevMode ? 'true' : '')
  }

  useEffect(() => {
    setDeveloperMode(Boolean(localStorage.getItem('insightDebug')) ?? false)
  }, [])

  return (
    <ModalBase>
      <ModalTitle title="Asetukset" />
      <FormArea>
        <FormControl>
          <Typography variant="h3">Kartan väriteema</Typography>
          <Select
            size="small"
            id="select-color"
            value={mapTheme}
            // @ts-ignore
            onChange={(event) => handleSettingsToggle(event)}
          >
            {Object.keys(mapThemes).map((theme) => {
              return (
                <MenuItem key={theme} value={theme}>
                  {mapThemes[theme as keyof MapTheme].name}
                </MenuItem>
              )
            })}
          </Select>

          {role === 'superadmin' ? (
            <AdvancedSettings>
              <Typography variant="h3">Edistyneet asetukset</Typography>
              <Divider />
              <FormArea>
                <Typography variant="h4">Versio- ja ympäristötiedot</Typography>
                <Switch
                  checked={developerMode}
                  onChange={() => toggleInsightDebug(!developerMode)}
                />
                <Typography variant="subtitle1">
                  Tämä asetus näyttää oikeassa alakulmassa ympäristön versio- ja
                  nimitiedot.
                </Typography>
              </FormArea>
            </AdvancedSettings>
          ) : null}
        </FormControl>
      </FormArea>
      <CloseModalButton />
    </ModalBase>
  )
}
