import { List } from 'immutable'
import { ZoneData } from 'generated-types'
import { FilterStateMode } from '../../redux/filter'
import { round, range } from 'lodash'

const COLOR_RANGE_SEGMENT_COUNT = 10
const COLOR_VALUE_NOT_IN_RANGE = 'transparent'

type Color = {
  red: number
  green: number
  blue: number
  opacity?: number
}

export type ColorTheme = {
  color1: Color
  color2?: Color
  midOpacity?: number
  endOpacity?: number
  highlightColor: Color
  name: string
}

export type MapTheme = {
  singleRed: ColorTheme
  originalGreen: ColorTheme
  greenRed: ColorTheme
  orangeBlue: ColorTheme
}

export const mapThemes: MapTheme = {
  originalGreen: {
    name: 'Alkuperäinen vihreä',
    color1: {
      red: 44,
      green: 140,
      blue: 30
    },
    highlightColor: {
      red: 30,
      green: 190,
      blue: 0,
      opacity: 0.9
    }
  },
  greenRed: {
    name: 'Puna-vihreä',
    color1: {
      red: 192,
      green: 57,
      blue: 43
    },
    color2: {
      red: 39,
      green: 174,
      blue: 96
    },
    highlightColor: {
      red: 20,
      green: 210,
      blue: 64,
      opacity: 0.9
    },
    endOpacity: 80,
    midOpacity: 40
  },
  orangeBlue: {
    name: 'Oranssi-sininen',
    color1: {
      red: 41,
      green: 128,
      blue: 185
    },
    color2: {
      red: 211,
      green: 84,
      blue: 0
    },
    highlightColor: {
      red: 10,
      green: 90,
      blue: 220,
      opacity: 0.9
    },
    endOpacity: 80,
    midOpacity: 40
  },
  singleRed: {
    name: 'Yksinkertainen punainen',
    color1: {
      red: 192,
      green: 57,
      blue: 43
    },
    highlightColor: {
      red: 233,
      green: 42,
      blue: 10,
      opacity: 0.9
    }
  }
}

export type ColorRange = {
  min: number
  max: number
  color: string
}

export type ColorRangeList = List<ColorRange>

// Set scale to 0..1 in 10 steps with exponential scaling e^start..e^end
const scaleStart = 1.0
const scaleEnd = 3.5

// 11 points for 10 segments
const scale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  // e^(start + step)
  .map((n) =>
    Math.exp(
      scaleStart + n * ((scaleEnd - scaleStart) / COLOR_RANGE_SEGMENT_COUNT)
    )
  )
  // start at 0
  .map((n) => n - Math.exp(scaleStart))
  // normalize to 0..1 to get factors for multiplication
  .map((n) => n / (Math.exp(scaleEnd) - Math.exp(scaleStart)))

type Gradient = {
  red: number
  blue: number
  green: number
}

export function createMultiColor(
  fade: number,
  theme: ColorTheme,
  stepCount: number
) {
  const { endOpacity, midOpacity, color1, color2 } = theme
  if (!color2 || !endOpacity || !midOpacity) {
    throw new Error('Multicolor-theme is lacking a property!')
  }
  const stepSize = (midOpacity - endOpacity) / 5
  let rawScale = range(endOpacity, midOpacity, stepSize)
  rawScale = rawScale.concat([midOpacity])
  rawScale = rawScale.concat(range(midOpacity, endOpacity, Math.abs(stepSize)))
  const newScale = rawScale.map((value) => value / 100)
  const opacity = newScale[stepCount]

  const diffRed = color2.red - color1.red
  const diffGreen = color2.green - color1.green
  const diffBlue = color2.blue - color1.blue

  const gradient: Gradient = {
    red: round(color1.red + diffRed * fade),
    green: round(color1.green + diffGreen * fade),
    blue: round(color1.blue + diffBlue * fade)
  }

  return `rgba(${gradient.red},${gradient.green},${gradient.blue}, ${opacity})`
}

export function createSingleColor(fade: number, theme: ColorTheme) {
  const { color1: color } = theme
  return `rgba(${color.red},${color.green},${color.blue}, ${Number(fade)})`
}

export const createColorRanges = (
  zoneData: ZoneData[],
  mode: FilterStateMode,
  themeName: keyof MapTheme
): ColorRangeList => {
  const highestQuantity =
    mode === FilterStateMode.Index
      ? Math.max(...zoneData.map((item) => item.populationIndex))
      : Math.max(...zoneData.map((item) => item.population))

  let ranges = List<ColorRange>()

  for (let i = 0; i < COLOR_RANGE_SEGMENT_COUNT; i++) {
    const weightedFade = 0.1 + (0.8 * i) / (COLOR_RANGE_SEGMENT_COUNT - 1)
    ranges = ranges.push({
      min: scale[i] * highestQuantity,
      max: scale[i + 1] * highestQuantity,
      color:
        'color2' in mapThemes[themeName]
          ? createMultiColor(weightedFade, mapThemes[themeName], i)
          : createSingleColor(weightedFade, mapThemes[themeName])
    })
  }
  return ranges
}

export const getColorForValue = (
  value: number,
  ranges: ColorRangeList
): string => {
  const match = ranges.find((range) => value >= range.min && value <= range.max)
  return match ? match.color : COLOR_VALUE_NOT_IN_RANGE
}
