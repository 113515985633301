import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme: Theme) => ({
  panelSummary: {
    display: 'flex'
  },
  name: {
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    padding: theme.spacing(0, 3)
  },
  tr: {
    '&:hover': {
      cursor: 'pointer'
    },
    zIndex: 10
  },
  selectedTr: {
    backgroundColor: '#396'
  },
  selectedGraphTr: {
    backgroundColor: '#c8ecda'
  },
  selectedTd: {
    color: 'white'
  },
  table: {
    tableLayout: 'fixed',
    marginBottom: theme.spacing(4)
  },
  cell: {
    border: 'none',
    padding: theme.spacing(1.5, 2),
    lineHeight: '1.2'
  },
  chartCell: {
    border: 'none',
    padding: theme.spacing(1.5, 2),
    lineHeight: '1.2',
    width: '680px'
  },
  sliderCell: {
    border: 'none',
    padding: theme.spacing(1.5, 2),
    margin: '20px',
    lineHeight: '1.2'
  },
  cellTh: {
    paddingTop: '0',
    paddingBottom: '0'
  },
  nameTh: {
    width: '45%'
  },
  scaleTh: {
    fontSize: '12px',
    color: 'black',
    verticalAlign: 'top'
  },
  lastTh: {
    textAlign: 'right'
  },
  firstCell: {
    fontFamily: 'Helvetica, Arial, sans-serif',
    textAlign: 'right',
    padding: theme.spacing(0, 0, 0, 3),
    border: 'none'
  },
  segmentBar: {
    border: 'none'
  },
  sliderLabel: {
    maxWidth: '25%',
    hyphens: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))
