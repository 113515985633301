import styled from '@emotion/styled'

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  span {
    font-size: 12px;
    font-weight: 500;
  }

  h5 {
    font-size: 12px;
    margin: 0;
    margin-bottom: 4px;
  }
`

export default PopoverContent
