import { Action } from 'redux'
import actionCreatorFactory, { isType } from 'typescript-fsa'
import { SearchPayload } from '../services/graphqlServiceTypes'

const actionCreator = actionCreatorFactory()

export type SearchResults = {
  [key: string]: SearchPayload | undefined
}

export const emptySearchState = actionCreator<SearchResults>('EMPTY_SEARCH')
export const updateSearchResults = actionCreator<{
  searchCategory: string
  searchPayload: SearchPayload
}>('UPDATE_RESULTS')

const defaultState: SearchResults = {}

const reducer = (
  state: SearchResults = defaultState,
  action: Action
): SearchResults => {
  if (isType(action, updateSearchResults)) {
    const {
      payload: { searchCategory, searchPayload }
    } = action
    const newState: SearchResults = { ...state }
    const olderData = { ...newState[searchCategory] }
    newState[searchCategory] = { ...olderData, ...searchPayload }
    return newState
  }

  return state
}

export default reducer
