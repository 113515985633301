import { FeatureCollection } from 'geojson'
import gql from 'graphql-tag'
import { ZoneDataOutput } from '../../services/graphqlServiceTypes'

export const postalCodeQuery = gql`
  query PostalCodes($boundingBox: BoundingBox!, $filter: MapFilter!) {
    postalCodeDataByBbox(boundingBox: $boundingBox, filter: $filter) {
      zoneData {
        id
        type
        name
        population
        unfilteredPopulation
        populationIndex
      }
      GeoJSONFeatureCollection {
        type
        features {
          type
          geometry {
            type
            ... on GeoJSONGeometryInterface {
              coordinates
            }
            ... on GeoJSONGeometryCollection {
              geometries {
                type
                coordinates
              }
            }
          }
          properties {
            areaType
            identifier
            municipalityIdentifier
            name {
              fi
              sv
            }
          }
        }
      }
    }
  }
`

export const zoneQuery = gql`
  query Zones($areaType: Int!, $filter: MapFilter!) {
    zoneData(filter: $filter) {
      id
      type
      name
      population
      unfilteredPopulation
      populationIndex
    }
    GeoJSONFeatureCollection(areaType: $areaType) {
      type
      features {
        type
        geometry {
          type
          coordinates
        }
        properties {
          areaType
          identifier
          name {
            fi
            sv
          }
        }
      }
    }
  }
`

export type MapQueryOutput = {
  GeoJSONFeatureCollection: FeatureCollection
  zoneData: ZoneDataOutput
}

export type PostalCodeQueryOutput = {
  postalCodeDataByBbox: MapQueryOutput
}

export type ZoneQueryOutput = MapQueryOutput
