import { Action } from 'redux'
import actionCreatorFactory, { isType } from 'typescript-fsa'
import { ExportSettings } from '../components/export/ExportMain'
import { without } from 'lodash'

export type ExportSettingsStore = {
  settings: ExportSettings[]
  postalCodeColumn: boolean
}

const actionCreator = actionCreatorFactory()

export const saveExportSettings = actionCreator<ExportSettings>(
  'UPDATE_EXPORT_SETTINGS'
)
export const togglePostalCodeColumn = actionCreator('TOGGLE_POSTAL_CODE_COLUMN')

const defaultState: ExportSettingsStore = {
  settings: [],
  postalCodeColumn: false
}

const reducer = (
  state: ExportSettingsStore = defaultState,
  action: Action
): ExportSettingsStore => {
  if (isType(action, saveExportSettings)) {
    const setting = action.payload
    let newState = { ...state }
    const { settings: oldSettings } = newState

    if (newState.settings.includes(setting)) {
      newState.settings = without(oldSettings, setting)
    } else {
      newState.settings = oldSettings.concat([setting])
    }

    return newState
  }

  if (isType(action, togglePostalCodeColumn)) {
    let newState = { ...state }
    newState.postalCodeColumn = !state.postalCodeColumn
    return newState
  }

  return state
}

export default reducer
