import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import indigo from '@mui/material/colors/indigo'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import cx from 'classnames'
import first from 'lodash/first'
import get from 'lodash/get'

import { addFilter, FilterType, removeFilter } from 'src/redux/filter'
import { closePopover, openPopover } from 'src/redux/ui'
import {
  AnswerOutput,
  AnswerValueOutput,
  QuestionOutput
} from '../../../../services/graphqlServiceTypes'
import { createQueryFilters } from '../../../../utils/filter'
import PopoverContent from '../../../PopoverContent'
import { StudySegmentBar } from '../../panel-data/StudySegmentBar'
import useStyles from './graphStyles'
import { useDispatch } from 'react-redux'
import { useFilter } from 'src/redux'
import { CustomDataSegment } from './Question'

type Props = {
  question: QuestionOutput
  segmentNames: CustomDataSegment[]
}

const ChartBar = styled(StudySegmentBar)`
  font-size: 14px;
  font-family: Helvetica;
  padding: 4px;
  color: #000752;
  font-weight: 600;
  white-space: nowrap;
`

const NumberIntervalQuestion: FunctionComponent<Props> = ({
  question,
  segmentNames
}) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const { filters } = useFilter()

  const { label, scale, answers, studyId, id: questionId } = question

  const filterData = createQueryFilters(filters)
  const { study: filteredStudy } = filterData
  const { id: filteredStudyId, segmentId: filteredSegmentId } =
    filteredStudy || {}

  return (
    <Accordion square>
      <AccordionSummary
        className={classes.panelSummary}
        id="question-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.name}>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={cx(classes.cell, classes.nameTh)} />
              <TableCell className={cx(classes.cell, classes.scaleTh)}>
                {scale.startLabel}
              </TableCell>
              <TableCell
                className={cx(classes.cell, classes.scaleTh, classes.lastTh)}
              >
                {scale.endLabel}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {answers.values.map((answer: AnswerOutput) => {
              const answerValue = Number(
                get(first<AnswerValueOutput>(answer.values), 'value')
              )

              const selected =
                answer.segmentId === filteredSegmentId &&
                studyId === filteredStudyId

              const { segmentId } = answer
              const segment = segmentNames.find((elem) => elem.id === segmentId)
              const rowName = segment?.name ? segment.name : answer.name

              const handleCellPopoverOpen = (event: any) => {
                event.stopPropagation()
                dispatch(
                  openPopover({
                    popoverTargetRef: event.currentTarget,
                    popoverContent: (
                      // @ts-ignore
                      <PopoverContent>
                        <span>
                          {rowName} {answerValue.toFixed(1)}
                        </span>
                      </PopoverContent>
                    )
                  })
                )
              }

              const onRowClick = (segmentId: string, segmentLabel: string) => {
                const payload = {
                  filterName: studyId,
                  filterValue: segmentId,
                  filterLabel: segmentLabel,
                  filterType: FilterType.StudySegment,
                  questionId: questionId
                }
                if (selected) {
                  dispatch(removeFilter(payload))
                } else {
                  dispatch(addFilter(payload))
                }
              }

              return (
                <TableRow
                  key={answer.segmentId}
                  className={cx(classes.tr, selected ? classes.selectedTr : '')}
                  onClick={() => onRowClick(answer.segmentId, rowName)}
                >
                  <TableCell
                    className={cx(
                      classes.cell,
                      classes.firstCell,
                      selected ? classes.selectedTd : ''
                    )}
                  >
                    {rowName}
                  </TableCell>
                  <TableCell
                    className={cx(classes.cell, classes.segmentBar)}
                    colSpan={2}
                    onClick={handleCellPopoverOpen}
                    onMouseEnter={handleCellPopoverOpen}
                    onMouseLeave={() => dispatch(closePopover())}
                  >
                    <ChartBar
                      color={indigo[100]}
                      style={{
                        width: `${100 * (answerValue / Number(scale.end))}%`
                      }}
                    >
                      {answerValue.toFixed(1)}
                    </ChartBar>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(NumberIntervalQuestion)
