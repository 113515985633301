import areaHierarchy from '../static/area-codes-hierarchy.json'
import postal_area_names from '../static/names-postal-areas.json'
import region_names from '../static/names-regions.json'
import municipality_names from '../static/names-municipality.json'

import { compact, flatten, get, merge } from 'lodash'

// TODO: Move these area-functions to an NPM package and publish it there for an easier sync between the projects

type ParentArea = {
  region: string
  municipality: string
}

function findParentForPostalCode(postalCode: string): ParentArea | undefined {
  for (const region of Object.keys(areaHierarchy)) {
    // @ts-ignore
    for (const municipality of Object.keys(areaHierarchy[region])) {
      // @ts-ignore
      for (const postal of areaHierarchy[region][municipality]) {
        if (postal === postalCode) {
          return {
            region: region,
            municipality: municipality
          }
        }
      }
    }
  }
  console.error(`Area info not found postalcode ${postalCode}!`)
}

export function findRegionForPostalCode(
  postalCode: string
): string | undefined {
  const parent = findParentForPostalCode(postalCode)
  if (parent) {
    return parent.region
  }
  console.error(`Parent-region cant be found for postalcode ${postalCode} !`)
  return
}

export function findMunicipalityForPostalCode(
  postalCode: string
): string | undefined {
  const parent = findParentForPostalCode(postalCode)
  if (parent) {
    return parent.municipality
  }
  console.error(
    `Parent-municipality cant be found for postalcode ${postalCode} !`
  )
  return
}

export function getMunicipalitiesForRegion(
  regionCode: string
): string[] | undefined {
  const region = get(areaHierarchy, regionCode)
  if (!region) {
    return
  }
  return Object.keys(region)
}

export function getPostalCodesForMunicipality(
  municipalityCode: string
): string[] | undefined {
  const municipalities = {}
  Object.keys(areaHierarchy).forEach((area) => {
    merge(municipalities, get(areaHierarchy, area))
  })
  return get(municipalities, municipalityCode)
}

export function getPostalCodesFromRegion(region: string): string[] | undefined {
  const municipalities = getMunicipalitiesForRegion(region)

  if (!municipalities) {
    console.log(`Region ${region} was not found.`)
    return
  }

  return flatten(
    compact(
      municipalities.map((municipality) => {
        return getPostalCodesForMunicipality(municipality)
      })
    )
  )
}

export function getNameForArea(areaId: string): string {
  const isPostal = areaId.length === 5
  const isMunicipality = areaId.length === 3
  const isRegion = areaId.length === 2

  if (isPostal) {
    return getPostalAreaName(areaId)
  }

  if (isMunicipality) {
    return getMunicipalityName(areaId)
  }

  if (isRegion) {
    return getRegionName(areaId)
  }

  if (!isPostal || !isMunicipality || !isRegion) {
    console.error(`AreaId ${areaId} was not recognized to be of any type.`)
  }

  return areaId
}

export function getPostalAreaName(areaId: string): string {
  if (areaId in postal_area_names) {
    // @ts-ignore
    return postal_area_names[areaId]
  }
  console.error(`PostalArea ${areaId} doesn't have a label!`)
  return areaId
}

export function getRegionName(areaId: string): string {
  if (areaId in region_names) {
    // @ts-ignore
    return region_names[areaId]
  }
  console.error(`Region ${areaId} doesn't have a label!`)
  return areaId
}

export function getMunicipalityName(areaId: string): string {
  if (areaId in municipality_names) {
    // @ts-ignore
    return municipality_names[areaId]
  }
  console.error(`Municipality ${areaId} doesn't have a label!`)
  return areaId
}
