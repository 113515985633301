import React from 'react'
import styled from '@mui/styled-engine'
import { range } from 'lodash'
import { Typography } from '@mui/material'

import {
  createMultiColor,
  createSingleColor,
  MapTheme,
  mapThemes
} from './colors'

import {colors} from "../styles/design";

type Props = {
  mapTheme: keyof MapTheme
}

const styles = {
  lessMore: {
    color: colors.darkGrey,
    fontWeight: 'bold',
    fontSize: '0.75rem',
    lineHeight: '1'
  },
  combined: {
    fontWeight: 550,
    fontSize: '0.75rem',
    lineHeight: '1'
  }
}

const Legend = styled('div')({
  borderRadius: '8px',
  padding: '1em',
  height: 'auto',
  width: '45em',
  backgroundColor: 'white'
})

const Titles = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  height: '60%',
  textTransform: 'uppercase'
})

const ColorArea = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginTop: '0.5em',
  height: '1.5em'
})

const ColorBlock = styled('div')(({ color }: { color: string }) => ({
  width: '10%',
  height: '100%',
  backgroundColor: color
}))

export const MapLegend: React.FC<Props> = (props: Props) => {
  const { mapTheme } = props
  const theme = mapThemes[mapTheme]

  const colors = range(0, 1, 0.1).map((fade) => {
    if ('color2' in theme) {
      return createMultiColor(fade, theme, 10)
    } else {
      return createSingleColor(fade, theme)
    }
  })

  return (
    <Legend>
      <Titles>
        <Typography sx={styles.lessMore}>Vähemmän</Typography>
        <Typography sx={styles.combined}>Lukumäärä</Typography>
        <Typography sx={styles.lessMore}>Enemmän</Typography>
      </Titles>
      <ColorArea>
        {colors.map((color) => {
          return <ColorBlock key={`color_${color}`} color={color} />
        })}
      </ColorArea>
    </Legend>
  )
}
