import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import actionCreatorFactory from 'typescript-fsa'
import { mapThemes, MapTheme } from 'src/components/map/colors'

export enum ModalPages {
  MySettings = 'mysettings',
  About = 'about',
  AreaSelection = 'areaSelection'
}

const actionCreator = actionCreatorFactory()

export const setModal = actionCreator<{
  modalOpen: boolean
  modalPage?: ModalPages
}>('TOGGLE_SETTINGS_MODAL')

export const changeMapTheme = actionCreator<{ theme: keyof MapTheme }>(
  'CHANGE_MAP_THEME'
)

const defaultTheme = 'orangeBlue'

export type ModalState = {
  modalOpen: boolean
  mapTheme: string
  modalPage: ModalPages
}

function getThemeFromMemory(): keyof MapTheme {
  const themeInMemory = localStorage.getItem('mapTheme') ?? defaultTheme

  if (Object.keys(mapThemes).includes(themeInMemory)) {
    return themeInMemory as keyof MapTheme
  } else {
    return defaultTheme as keyof MapTheme
  }
}

const defaultState: ModalState = {
  modalOpen: false,
  modalPage: ModalPages.MySettings,
  mapTheme: getThemeFromMemory()
}

const reducer = (
  state: ModalState = defaultState,
  action: Action
): ModalState => {
  if (isType(action, setModal)) {
    const {
      payload: { modalOpen, modalPage }
    } = action
    const newState: ModalState = { ...state }
    newState.modalOpen = modalOpen
    if (modalPage) {
      newState.modalPage = modalPage
    }
    return newState
  }

  if (isType(action, changeMapTheme)) {
    const {
      payload: { theme }
    } = action
    const newState: ModalState = { ...state }
    newState.mapTheme = theme
    localStorage.setItem('mapTheme', theme)
    return newState
  }

  return state
}

export default reducer
