import styled from '@emotion/styled'
import { css } from '@mui/material/styles'
import { getRatio } from './SegmentIndex'

const COLORIZATION_PERCENTAGE = 0.2

export enum BarColor {
  nationWide = '#d1d1d1',
  low = '#f23e5a',
  normal = '#595959',
  high = '#05c46b'
}

export const StudySegmentBar = styled.div(
  ({ style: { width }, color }: BarProps) =>
    css`
      width: ${width};
      min-height: 7px;
      margin-bottom: 2px;
      background: ${color};
    `
)

type BarProps = {
  style: { width: string }
  color: BarColor | string
}

export function selectBarColor(value: number, comparison: number): BarColor {
  const ratio = getRatio(value, comparison)

  if (ratio > 1 + COLORIZATION_PERCENTAGE) {
    return BarColor.high
  }

  if (ratio < 1 - COLORIZATION_PERCENTAGE) {
    return BarColor.low
  }

  return BarColor.normal
}
