import { version } from './version'

export const getConfigFromEnv = () => ({
  app: {
    DEV_FEATURES: requireBooleanEnv('REACT_APP_DEV_FEATURES'),
    API_URL: requireStringEnv('REACT_APP_API'),
    ADMIN_PANEL_URL: requireStringEnv('REACT_APP_ADMIN_URL'),
    SESSION_TIMEOUT: requireNumberEnv('REACT_APP_SESSION_TIMEOUT'),
    ENV_NAME: requireStringEnv('REACT_APP_ENV_NAME'),
    SHOW_BALLPARK: optionalBooleanEnv('REACT_APP_SHOW_BALLPARK'),
    BALLPARK_LIMIT: optionalNumberEnv('REACT_APP_BALLPARK_LIMIT'),
    VERSION: version
  },
  map: {
    TILE_SOURCE: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    TILE_ATTRIBUTION:
      "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
    BOUNDS_SW_LAT: 58,
    BOUNDS_SW_LNG: 18,
    BOUNDS_NE_LAT: 71,
    BOUNDS_NE_LNG: 33,
    INITIALPOSITION_LAT: 60.1699,
    INITIALPOSITION_LNG: 24.9384,
    INITIAL_ZOOM: 12
  }
})

const requireStringEnv = (env: string): string => {
  const envVariable = process.env[env]

  if (!envVariable) {
    throw new Error(`Environment variable ${env} is missing!`)
  }

  if (typeof envVariable !== 'string') {
    throw new Error(
      `Environment variable ${env} was of type ${typeof envVariable}, when string is required!`
    )
  }

  return envVariable
}

const optionalNumberEnv = (env?: string | undefined): number | undefined => {
  if (!env) {
    return undefined
  }

  const envVariable = process.env[env]

  if (!envVariable) {
    return undefined
  }

  return requireNumberEnv(env)
}

const requireNumberEnv = (env: string): number => {
  const envVariable = process.env[env]

  if (!envVariable) {
    throw new Error(`Environment variable ${env} is missing!`)
  }

  const converted = Number(envVariable)

  if (!converted) {
    throw new Error(`Environment variable ${env} is not a number!`)
  }

  return converted
}

const optionalBooleanEnv = (env?: string | undefined): boolean | undefined => {
  if (!env) {
    return undefined
  }

  const envVariable = process.env[env]

  if (!envVariable) {
    return undefined
  }

  return requireBooleanEnv(env)
}

const requireBooleanEnv = (env: string): boolean => {
  const envVariable = process.env[env]

  if (!envVariable) {
    throw new Error(`Environment variable ${env} is missing!`)
  }

  if (envVariable === 'true') {
    return true
  }

  if (envVariable === 'false') {
    return false
  }

  throw new Error(
    `Environment variable ${env} was of type ${typeof envVariable}, when boolean is required!`
  )
}
