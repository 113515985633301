import { Role } from 'generated-types'
import { Action } from 'redux'
import actionCreatorFactory, { isType } from 'typescript-fsa'
import { Coordinates } from '../services/graphqlServiceTypes'

const actionCreator = actionCreatorFactory()

export type UserStore = {
  email?: string | null
  firstName?: string | null
  lastName?: string | null
  role?: Role | null
  serviceNetworkError?: boolean
}

export const storeUserState = actionCreator<UserStore>('UPDATE_USER_STATE')
export const saveUserMapPosition = actionCreator<{
  mapPosition: Coordinates
  zoomLevel: number
}>('SAVE_USER_MAP_POSITION')

const defaultState: UserStore = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  role: undefined,
  serviceNetworkError: false
}

const reducer = (
  state: UserStore = defaultState,
  action: Action
): UserStore => {
  if (isType(action, storeUserState)) {
    return { ...action.payload }
  }

  return state
}

export default reducer
