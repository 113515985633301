import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import DescriptionIcon from '@mui/icons-material/Description'
import DynamicFormIcon from '@mui/icons-material/DynamicForm'

import { colors } from '../../styles/design'

const badgeStyle = {
  '& .MuiBadge-badge': {
    color: colors.white,
    backgroundColor: colors.blue[300]
  }
}

export const StudyOwnDataAmount = ({
  ids,
  infoType
}: {
  ids: string[]
  infoType: 'ownData' | 'study' | 'questionIds'
}): JSX.Element => {
  return (
    <Tooltip
      style={{ whiteSpace: 'pre-line' }}
      title={ids.map((val) => {
        return <div key={val}>{val}</div>
      })}
    >
      <Badge
        showZero
        sx={badgeStyle}
        badgeContent={ids.length}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
      >
        {infoType === 'study' ? (
          <>
            <LibraryBooksIcon />
            <Typography
              style={{ marginLeft: '0.25rem' }}
              variant="subtitle1"
              color="black"
            >
              Tutkimukset
            </Typography>
          </>
        ) : null}
        {infoType === 'ownData' ? (
          <>
            <DescriptionIcon />
            <Typography
              style={{ marginLeft: '0.25rem' }}
              variant="subtitle1"
              color="black"
            >
              Oma data
            </Typography>
          </>
        ) : null}

        {infoType === 'questionIds' ? (
          <>
            <DynamicFormIcon />
            <Typography
              style={{ marginLeft: '0.25rem' }}
              variant="subtitle1"
              color="black"
            >
              Kysymykset
            </Typography>
          </>
        ) : null}
      </Badge>
    </Tooltip>
  )
}
