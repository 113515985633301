import React from 'react'
import { Typography } from '@mui/material'
import { CloseModalButton, ModalBase, ModalTitle } from './common'

function LicenseBox(): JSX.Element {
  return (
    <div>
      <p>Aineiston tuottaja ja tekijänoikeuden haltija: Tilastokeskus.</p>
      <p>
        Aineisto on ladattu Tilastokeskuksen rajapintapalvelusta 15.3.2018{' '}
        <a href={'https://creativecommons.org/licenses/by/4.0/deed.fi'}>
          lisenssillä CC BY 4.0
        </a>
      </p>
    </div>
  )
}

export const About: React.FC = () => {
  return (
    <ModalBase>
      <ModalTitle title="Tietoja" />
      <Typography variant="h3">
        Postinumero-, maakunta- ja kunta-aluerajat
      </Typography>
      <LicenseBox />
      <Typography variant="h3">Paavo-data</Typography>
      <LicenseBox />
      <CloseModalButton />
    </ModalBase>
  )
}
