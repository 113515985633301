import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
//import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '.'
import getSagas from './getSagas'

const sagaMiddleware = createSagaMiddleware()

export default createStore(
  rootReducer, //composeWithDevTools(
  applyMiddleware(sagaMiddleware)
) //)

sagaMiddleware.run(getSagas(), {})
