import gql from 'graphql-tag'
import { PopulationInfoOutput } from '../../services/graphqlServiceTypes'

import { exportPopulationQuery } from './export'
import {
  projectQueryWithQuestionStudies,
  projectQueryWithQuestions
} from './projectquery'

export type PopulationInfoQueryOutput = {
  populationInfo: PopulationInfoOutput
}

export const populationInfoQuery = gql`
  query PopulationInfo($filter: Filter!) {
    populationInfo(filter: $filter) {
      identifier
      label
      data {
        identifier
        label
        population
        populationPercentage
      }
    }
  }
`

export const userStudiesAndOwnData = gql`
  query QuestionStudies($id: ID!) {
    project(id: $id) {
      id
      questionStudies {
        identifier
      }
      ownData(filter: {}) {
        identifier
      }
      studyIds
      ownDataIds
      questionStudyIds
    }
  }
`

export const isTokenValid = gql`
  query ($token: String!, $tokenType: String!) {
    isTokenValid(token: $token, tokenType: $tokenType) {
      isValid
    }
  }
`

export {
  exportPopulationQuery,
  projectQueryWithQuestionStudies,
  projectQueryWithQuestions
}
