import React, { FunctionComponent } from 'react'
import { projectQueryWithQuestions } from '../../../../graphql/queries'
import { useQueryWithSmoothDataChange } from '../../../../graphql/hooks'
import {
  QuestionOutput,
  Filter
} from '../../../../services/graphqlServiceTypes'
import Question from './Question'
import { useParams } from 'react-router-dom'

type Props = {
  studyId: string
  filterData: Filter
}

const Questions: FunctionComponent<Props> = (props) => {
  let { studyId, filterData } = props
  const { projectId } = useParams()

  const { data } = useQueryWithSmoothDataChange(projectQueryWithQuestions, {
    variables: {
      id: projectId,
      studyId,
      filter: filterData
    }
  })

  if (!data) {
    return null
  }

  const { project } = data
  const { questions } = project

  return (
    <div>
      {questions.map((question: QuestionOutput) => (
        <Question key={question.id} question={question} />
      ))}
    </div>
  )
}

export default React.memo(Questions)
