import { Action } from 'redux'
import actionCreatorFactory, { isType } from 'typescript-fsa'
import { Coordinates } from '../services/graphqlServiceTypes'

const actionCreator = actionCreatorFactory()

const DEFAULT_ZOOM_LEVEL = 12

const DEFAULT_HELSINKI_COORDINATES: Coordinates = {
  lat: 60.1699,
  lng: 24.9384
}

export type UserPreferences = {
  mapPosition: Coordinates
  zoomLevel: number
}

export const saveUserMapPosition = actionCreator<{
  mapPosition: Coordinates
  zoomLevel: number
}>('SAVE_USER_MAP_POSITION')

const defaultState: UserPreferences = {
  mapPosition: {
    lat: DEFAULT_HELSINKI_COORDINATES.lat,
    lng: DEFAULT_HELSINKI_COORDINATES.lng
  },
  zoomLevel: DEFAULT_ZOOM_LEVEL
}

const reducer = (
  state: UserPreferences = defaultState,
  action: Action
): UserPreferences => {
  if (isType(action, saveUserMapPosition)) {
    const {
      payload: { mapPosition, zoomLevel }
    } = action
    const newState = { ...state }
    newState.mapPosition = mapPosition
    newState.zoomLevel = zoomLevel
    return newState
  }

  return state
}

export default reducer
