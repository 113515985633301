import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const PageNotFound: React.FC = () => {
  const navigate = useNavigate()

  const goBackToStart = () => {
    navigate('/')
  }

  useEffect(() => {
    sessionStorage.removeItem('selected-project')
  }, [])

  return (
    <div>
      Sivua ei löytynyt. Palaa alkuun{' '}
      <div style={{ color: 'blue', cursor: 'pointer' }} onClick={goBackToStart}>
        {' '}
        klikkaamalla tästä
      </div>
    </div>
  )
}
