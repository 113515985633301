import { makeStyles } from 'tss-react/mui'

export const sidebarPanelStyles = makeStyles()({
  panelSummary: {
    display: 'flex',
    flexDirection: 'row'
  },
  panelHeader: {},
  nonExpandedHeader: {
    padding: '0.8rem 1rem 0.6rem 0.8rem'
  },
  icon: {
    marginRight: 0
  }
})
