import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import actionCreatorFactory from 'typescript-fsa'

const actionCreator = actionCreatorFactory()

export const setAreaSelection = actionCreator<{ id: string; name: string }>(
  'SET_AREA_SELECTION'
)

export type NewMapState = {
  areaSelectionId: string
  areaSelectionName: string
}

const defaultState: NewMapState = {
  areaSelectionId: '',
  areaSelectionName: ''
}

const reducer = (
  state: NewMapState = defaultState,
  action: Action
): NewMapState => {
  if (isType(action, setAreaSelection)) {
    const {
      payload: { id, name }
    } = action
    return { areaSelectionId: id, areaSelectionName: name }
  }

  return state
}

export default reducer
