import { Map } from 'immutable'

import { FilterSpec, FilterType } from '../redux/filter'
import { Filter } from '../services/graphqlServiceTypes'
import { areaTypeForFilterType } from '../services/graphqlServiceUtils'

export const createQueryFilters = (
  filters: Map<string, FilterSpec>
): Filter => {
  return filters
    .map((filter) => {
      if (filter.filterName === 'map') {
        if (!filter.filterValue.count()) {
          return null
        }

        return {
          areaType: areaTypeForFilterType(FilterType.MapAreaPostalCode),
          areaIds: filter.filterValue
        }
      }

      if (
        filter.filterType === FilterType.StudySegment ||
        filter.filterType === FilterType.OwnDataSegment
      ) {
        return { id: filter.filterName, segmentId: filter.filterValue }
      }

      if (filter.filterType === FilterType.RespondentIdStudy) {
        return {
          id: filter.filterName,
          questionId: filter.questionId,
          answerRange: filter.answerRange
        }
      }

      return filter.filterValue
    })
    .mapKeys((key: string) => {
      if (key === 'map') {
        return 'area'
      }

      const filter = filters.get(key)
      if (filter && filter.filterType === FilterType.StudySegment) {
        return 'study'
      }
      if (filter && filter.filterType === FilterType.OwnDataSegment) {
        return 'ownData'
      }
      if (filter && filter.filterType === FilterType.RespondentIdStudy) {
        return 'respondentIdStudy'
      }

      return key
    })
    .filter((value) => !!value)
    .toJS()
}
