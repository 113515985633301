import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import actionCreatorFactory from 'typescript-fsa'
import { pull, pullAll, difference, union, intersection } from 'lodash'
import {
  createDefaultExportState,
  ExportFilter,
  StudyDefaultState,
  getFieldsForCategory,
  getCategoriesForTopCategory,
  getFieldsForTopCategory,
  getPanelCheckboxStatus,
  getSingleStudyCategoryStatus
} from './exportModelFilter'

const actionCreator = actionCreatorFactory()

export enum ExportChecked {
  Not,
  Partial,
  Yes
}

export const updateTopLevelExport = actionCreator<{
  topCategory: string
  checked: ExportChecked
}>('UPDATE_TOP_LEVEL_EXPORT')

export const updateFieldExport = actionCreator<{
  topCategory: string
  category: string
  field: string
}>('UPDATE_FIELD_EXPORT')

export const updateCategoryLevelExport = actionCreator<{
  topCategory: string
  category: string
  checked: ExportChecked
}>('UPDATE_CATEGORY_LEVEL_EXPORT')

export const updateAvailableStudies = actionCreator<{
  study: StudyDefaultState
}>('UPDATE_AVAILABLE_STUDIES')

export const updateAvailableOwnData = actionCreator<{
  ownData: StudyDefaultState
}>('UPDATE_AVAILABLE_OWNDATA')

const defaultState: ExportFilter = createDefaultExportState()

const reducer = (
  state: ExportFilter = defaultState,
  action: Action
): ExportFilter => {
  if (isType(action, updateTopLevelExport)) {
    const {
      payload: { topCategory, checked }
    } = action
    const newState: ExportFilter = { ...state }

    if (topCategory === 'study') {
      Object.keys(newState.study).forEach((categoryName) => {
        newState.study[categoryName].selectedIndexes =
          checked === ExportChecked.Yes
            ? newState.study[categoryName].indexes
            : []
      })
    } else if (topCategory === 'ownData') {
      Object.keys(newState.ownData).forEach((categoryName) => {
        newState.ownData[categoryName].selectedIndexes =
          checked === ExportChecked.Yes
            ? newState.ownData[categoryName].indexes
            : []
      })
    } else {
      checked === ExportChecked.Yes
        ? (newState.populationFields = getFieldsForTopCategory(topCategory))
        : (newState.populationFields = difference(
            newState.populationFields,
            getFieldsForTopCategory(topCategory)
          ))
    }
    getCategoriesForTopCategory(topCategory, newState).forEach((category) => {
      topCategory === 'study'
        ? (newState.studyCategories[category] = checked)
        : topCategory === 'ownData'
        ? (newState.ownDataCategories[category] = checked)
        : (newState.categories[category] = checked)
    })
    newState.panels[topCategory] = checked
    return newState
  }

  if (isType(action, updateCategoryLevelExport)) {
    const {
      payload: { topCategory, category, checked }
    } = action
    const newState: ExportFilter = { ...state }

    if (topCategory === 'study') {
      const { indexes } = newState.study[category]
      newState.studyCategories[category] = checked
      checked === ExportChecked.Yes
        ? (newState.study[category].selectedIndexes = indexes)
        : (newState.study[category].selectedIndexes = [])
    } else if (topCategory === 'ownData') {
      const { indexes } = newState.ownData[category]
      newState.ownDataCategories[category] = checked
      checked === ExportChecked.Yes
        ? (newState.ownData[category].selectedIndexes = indexes)
        : (newState.ownData[category].selectedIndexes = [])
    } else {
      newState.categories[category] = checked

      const fields = getFieldsForCategory(topCategory, category)
      const { populationFields } = newState
      checked === ExportChecked.Yes
        ? (newState.populationFields = union(populationFields, fields))
        : (newState.populationFields = pullAll(populationFields, fields))
    }
    newState.panels[topCategory] = getPanelCheckboxStatus(newState, topCategory)
    return newState
  }

  if (isType(action, updateAvailableStudies)) {
    const {
      payload: { study }
    } = action
    let newState: ExportFilter = { ...state }
    Object.keys(study).forEach((studyId) => {
      newState.studyCategories[studyId] = ExportChecked.Not
      newState.study[studyId] = study[studyId]
    })
    return newState
  }

  if (isType(action, updateAvailableOwnData)) {
    const {
      payload: { ownData }
    } = action
    let newState: ExportFilter = { ...state }

    Object.keys(ownData).forEach((ownDataId) => {
      newState.ownDataCategories[ownDataId] = ExportChecked.Not
      newState.ownData[ownDataId] = ownData[ownDataId]
    })

    return newState
  }

  if (isType(action, updateFieldExport)) {
    const {
      payload: { field, category, topCategory }
    } = action
    const newState: ExportFilter = { ...state }

    if (topCategory === 'study') {
      let { selectedIndexes: newIndexes } = newState.study[category]

      newIndexes.includes(field)
        ? pull(newIndexes, field)
        : newIndexes.push(field)
      newState.study[category].selectedIndexes = newIndexes
      newState.studyCategories[category] = getSingleStudyCategoryStatus(
        newState.study[category]
      )
    } else if (topCategory === 'ownData') {
      let { selectedIndexes: newIndexes } = newState.ownData[category]

      newIndexes.includes(field)
        ? pull(newIndexes, field)
        : newIndexes.push(field)
      newState.ownData[category].selectedIndexes = newIndexes
      newState.ownDataCategories[category] = getSingleStudyCategoryStatus(
        newState.ownData[category]
      )
    } else {
      const { populationFields } = newState

      populationFields.includes(field)
        ? pull(populationFields, field)
        : populationFields.push(field)
      const allCategoryFields = getFieldsForTopCategory(topCategory)
      const commonCount = intersection(
        allCategoryFields,
        populationFields
      ).length

      if (commonCount === 0) {
        newState.categories[category] = ExportChecked.Not
      } else if (commonCount === allCategoryFields.length) {
        newState.categories[category] = ExportChecked.Yes
      } else {
        newState.categories[category] = ExportChecked.Partial
      }
    }
    newState.panels[topCategory] = getPanelCheckboxStatus(newState, topCategory)
    return newState
  }

  return state
}

export default reducer
