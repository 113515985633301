import { LatLng, LatLngBounds } from 'leaflet'
import { getConfigFromEnv } from 'src/config'
const {
  map: {
    TILE_SOURCE,
    TILE_ATTRIBUTION,
    BOUNDS_SW_LAT,
    BOUNDS_SW_LNG,
    BOUNDS_NE_LAT,
    BOUNDS_NE_LNG,
    INITIALPOSITION_LAT,
    INITIALPOSITION_LNG,
    INITIAL_ZOOM
  }
} = getConfigFromEnv()

export const config = {
  tileSource: TILE_SOURCE,
  tileAttribution: TILE_ATTRIBUTION,
  maxBounds: new LatLngBounds(
    new LatLng(BOUNDS_SW_LAT, BOUNDS_SW_LNG),
    new LatLng(BOUNDS_NE_LAT, BOUNDS_NE_LNG)
  ),
  initialPosition: new LatLng(INITIALPOSITION_LAT, INITIALPOSITION_LNG),
  initialZoom: INITIAL_ZOOM
}
