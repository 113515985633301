import { makeStyles } from 'tss-react/mui'
import { colors, texts } from 'src/components/styles/design'
import { FilterStateMode } from 'src/redux/filter'
import TableViewIcon from '@mui/icons-material/TableView'
import MapIcon from '@mui/icons-material/Map'
import { useFilter } from 'src/redux'
import { useDispatch } from 'react-redux'
import { changeUIMode } from 'src/redux/filter'
import { Button } from '@mui/material'

const useStyles = makeStyles()(() => ({
  button: {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    border: '1px solid white',
    borderRadius: '9999px',
    cursor: 'pointer',
    paddingLeft: '0.8em',
    minHeight: '2em',
    minWidth: '9em',
    ...texts.medium
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: '0.4em'
  }
}))

export function DataMapModeToggle(): JSX.Element {
  const { classes } = useStyles()
  const { mode } = useFilter()
  const dispatch = useDispatch()

  const toggleUIState = () => {
    mode !== FilterStateMode.Export
      ? dispatch(changeUIMode({ mode: FilterStateMode.Export }))
      : dispatch(changeUIMode({ mode: FilterStateMode.Population }))
  }

  const isExportMode = mode === FilterStateMode.Export

  return (
    <Button onClick={toggleUIState} variant="contained">
      <div className={classes.icon}>
        {isExportMode ? <MapIcon /> : <TableViewIcon />}
      </div>
      <div className={classes.icon}>
        {isExportMode ? 'Karttatila' : 'Datatila'}
      </div>
    </Button>
  )
}
