import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { createApolloClient } from './utils/apolloClient'
import App from './components/App'

import store from './redux/store'

const apolloClient = createApolloClient()

function render(Component: React.FunctionComponent<any>) {
  const container = document.getElementById('root')
  const root = createRoot(container!)
  root.render(
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          <Component />
        </ApolloHooksProvider>
      </ApolloProvider>
    </Provider>
  )
}

render(App)
