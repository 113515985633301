import gql from 'graphql-tag'

export const projectFragment = gql`
  fragment Project on Project {
    id
    name
    description
    active
  }
`

export const questionFragment = gql`
  fragment Question on Question {
    id
    label
    type
    studyId
    scale {
      start
      end
      startLabel
      endLabel
    }
    answers {
      total
      values {
        segmentId
        name
        values {
          name
          value
          x
          y
        }
      }
    }
  }
`
